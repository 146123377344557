import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "@appkit4/react-components";
import {useTr} from "../utils/trUtil";

declare type PropTypes = {
    url?: string;
    className?: string;
};
const BackButton: React.FC<PropTypes> = (props: PropTypes) => {
    const navigate = useNavigate();
    const trg = useTr("global");
    const handleClick = () => {
        props.url ? navigate(props.url) : navigate(-1);
    };
    return (
        <Button
            kind="secondary"
            className={props.className ? props.className : null}
            compact={true}
            icon="icon-chevron-double-left-outline"
            onClick={handleClick}
        >
            {trg("back").toLowerCase()}
        </Button>
    );
};

export default BackButton;
