import React from "react";
import {useGlobalStore} from "../stores/GlobalStore";

function useMigration(entityHash: string | undefined) {
    const [isMigrated, setIsMigrated] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    React.useMemo(() => {
        if (isMigrated === false && entityHash)
            fetchApi(`/entity/${entityHash}/migrate/?hook=migration`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : setIsMigrated(true);
                    return data;
                })
                .catch((error) => {
                    addErrorNotification(error);
                    setIsMigrated(true);
                });
    }, [entityHash]);

    return isMigrated;
}

export default useMigration;
