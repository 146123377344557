import React from "react";
import "./Centered.scss";

interface PropTypes {
    children: React.ReactNode;
}
const Centered: React.FC<PropTypes> = (props: PropTypes) => (
    <div className="center-container">
        <div className="center-content">{props.children}</div>
    </div>
);
export default Centered;
