import React from "react";
import {FormRow} from "../../../types/FormRow";
import {Input} from "@appkit4/react-components";
import {InputPropTypes} from "../CalculationFormElement";

const InputDefault: React.FC<InputPropTypes> = (props) => {
    const data = Array.isArray(props.element.value) ? props.element.value : [props.element.value];

    const renderElement = (p: FormRow) => {
        const input =
            p.data_type !== "object"
                ? data.map((val, index) => {
                      //   console.log(currentPath);
                      //   console.log(val);

                      return (
                          <div key={index} className="my-1">
                              <DataInput {...props} value={val} index={index} />
                          </div>
                      );
                  })
                : "";
        return p.html_type === "fluid" ? (
            <div className="input-fluid text-start">
                {p.description || p.info ? (
                    <div className="d-flex">
                        <div style={{width: "60%"}} className="p-2">
                            {p.description}
                        </div>
                        <div className="ps-5 text-end pt-3" style={{width: "40%"}}>
                            <small>{p.info}</small>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {input}
            </div>
        ) : (
            <div className="border p-0 m-2 me-0 d-flex">
                <div style={{width: "60%"}} className="p-2">
                    {p.description}
                </div>
                <div className="px-5 text-end pt-3" style={{width: "20%"}}>
                    <small>{p.info}</small>
                </div>
                <div className="ps-2 pe-1" style={{width: "20%"}}>
                    {input}
                </div>
            </div>
        );
    };

    return (
        <>
            {renderElement(props.element)}
            <div className="p-0 m-0">{props.children}</div>
        </>
    );
};

export default InputDefault;

declare type DataInputPropTypes = InputPropTypes & {value: any; index: number};

const DataInput: React.FC<DataInputPropTypes> = (props) => {
    const {index, value, element} = props;
    const [inputValue, setInputValue] = React.useState(props.value ? props.value : "");

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            //if (inputValue !== props.value && inputValue.length > 0 && element.row_type === "form_data")
            if (inputValue !== value && inputValue.length > 0) props.onChangeHandler(element, inputValue);
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue, element, value, props]);

    return (
        <Input
            key={index}
            style={{display: "inline"}}
            inputProps={{style: {textAlign: element.html_type === "fluid" ? "left" : "right"}}}
            hideTitleOnInput={true}
            value={inputValue}
            //readonly={element.row_type !== "form_data"}
            //onChange={(v: any) => (element.row_type !== "form_data" ? {} : setInputValue(v))}
            onChange={(v: any) => setInputValue(v)}
        />
    );
};
