import React from "react";
import {FormRow} from "../../../types/FormRow";
import {TextArea} from "@appkit4/react-components/field";

import i18n from "../../../utils/i18n";
import HtmlPanel from "./HtmlPanel";

declare type PropTypes = {
    element: FormRow;
    parentPath: string;
    currentPath: string;
    children: React.ReactElement;
};

const InputTextarea: React.FC<PropTypes> = (props) => {
    const {element, parentPath, currentPath, children} = props;
    return element.html_type === "panel" ? (
        <>
            <HtmlPanel {...props}>
                <>
                    <div className="border mx-2 px-2">
                        {element.info ? element.info : element.description}
                        <TextArea
                            maxLength={10000}
                            style={{height: "150px"}}
                            inputProps={{style: {height: "150px"}}}
                        ></TextArea>
                    </div>
                    {children}
                </>
            </HtmlPanel>
        </>
    ) : (
        <div className="border mx-2 px-2">
            <TextArea maxLength={10000} style={{height: "150px"}} inputProps={{style: {height: "150px"}}}></TextArea>
        </div>
    );
};

export default InputTextarea;
