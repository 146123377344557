import {Panel} from "@appkit4/react-components";
import {DefaultComponentProps} from "../Questionnaire";
import {useState, useRef, useEffect} from "react";
import {useTr} from "../../../utils/trUtil";

const TextAreaComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const initialValue = question.answer?.data?.value || "";
    const [value, setValue] = useState(initialValue);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const trg = useTr("global");

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight, 30 * 20)}px`;
        }
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const v = event.target.value;
        setValue(v);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            onChangeHandler(question, v);
        }, 2000);
    };

    return (
        <textarea
            ref={textAreaRef}
            style={{
                resize: "none",
                overflowY: "auto",
                flex: "1",
                width: "100%",
                maxHeight: "500px",
                minHeight: "76px",
                height: "auto",
            }}
            placeholder={trg("enter_the_required_information")}
            value={value}
            onChange={handleChange}
            rows={1}
        />
    );
};

export default TextAreaComponent;
