import React from "react";
import Centered from "../../misc/Centered";
import {Loading} from "@appkit4/react-components/loading";
import {Table, Column} from "@appkit4/react-components/table";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {useTr} from "../../utils/trUtil";
import {useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Button, Panel} from "@appkit4/react-components";
import "./ImportDataCheck.scss";
import ImportTrialBalanceAdjustmentForm from "./ImportTrialBalanceAdjustmentForm";
import {JournalEntry} from "../../types/JournalEntry";
import {Number} from "../../misc/AdjustmentColumns";
import i18n from "../../utils/i18n";
import {numberToLocaleString} from "../../utils/numberToLocaleString";
import PagePanel from "../../misc/PagePanel";

declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    menu: React.ReactNode;
};

const ImportTrialBalanceAdjustments: React.FC<PropTypes> = (props: PropTypes) => {
    const tr2 = useTr("calculation_import");
    const tr = useTr("import");

    const navigate = useNavigate();
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);

    const [entriesAreLoaded, setEntriesAreLoaded] = React.useState<boolean>(false);
    const [entries, setEntries] = React.useState<JournalEntry[]>([]);
    const [tableEntries, setTableEntries] = React.useState<any[]>([]);
    const [movement, setMovement] = React.useState<number>(0.0);

    React.useEffect(() => {
        if (entriesAreLoaded === false)
            fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance/adjustment/`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : setEntries(data);
                    setEntriesAreLoaded(true);
                });
    }, [entriesAreLoaded]);

    const newAdjustmentHandler = () => {
        setEntriesAreLoaded(false);
    };

    React.useEffect(() => {
        setTableEntries(
            entries.map((item) => {
                return {
                    ...item,
                    account_number: item.account ? item.account.number : "",
                    account_name: item.account ? item.account.name : "",
                };
            })
        );

        setMovement(entries.map(({amount}) => parseFloat(amount)).reduce((acc, amount) => acc + amount, 0));
    }, [entries]);

    const removeAdjustment = (item: JournalEntry) => {
        setEntriesAreLoaded(true);
        fetchApi(
            `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_entry/${item.id}/`,
            "delete"
        )
            .then((res) => res.json())
            .then((data) => {
                if (typeof data.error !== "undefined") addErrorNotification(data);
                setEntriesAreLoaded(false);
            });
    };

    const confirmTBAdjustments = () => {
        fetchApi(
            `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance/adjustments_completed/`,
            "post"
        )
            .then((response) => response.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    updateCurrentCalculation(data);
                    navigate(`/calculation/${props.entity.hash}/${props.calculation.hash}/import/data-check`);
                }
            });
    };

    return (
        <PagePanel title="Trial balance adjustments">
            {props.menu}
            {entriesAreLoaded ? (
                <>
                    <ImportTrialBalanceAdjustmentForm
                        calculation={props.calculation}
                        entity={props.entity}
                        afterSaveHandler={newAdjustmentHandler}
                    />

                    <Table
                        originalData={tableEntries}
                        className="table-data-check mt-3"
                        hasTitle={true}
                        striped={true}
                        condensed={true}
                    >
                        <Column
                            sortKey="account_number"
                            renderCell={(row, field) => {
                                return (
                                    <span>
                                        {row.account_number}&nbsp;&nbsp;&nbsp;&nbsp;<i>{row.account_name}</i>
                                    </span>
                                );
                            }}
                        >
                            {tr2("account")}
                        </Column>
                        <Column sortKey="description">{tr2("description")}</Column>
                        <Column
                            sortKey="amount"
                            renderCell={(row, field) => {
                                return <Number field={field} row={row} amount={row.amount} />;
                            }}
                        >
                            {tr2("amount")}
                        </Column>
                        <Column
                            sortKey="debit_amount"
                            renderCell={(row, field) => {
                                return <Number field={field} row={row} amount={row.debit_amount} />;
                            }}
                        >
                            {tr2("debit")}
                        </Column>
                        <Column
                            sortKey="credit_amount"
                            renderCell={(row, field) => {
                                return <Number field={field} row={row} amount={row.credit_amount} />;
                            }}
                        >
                            {tr2("credit")}
                        </Column>
                        <Column
                            style={{width: "50px"}}
                            field="id"
                            renderCell={(row, field) => {
                                return (
                                    <span
                                        className="Appkit4-icon icon-delete-fill text-danger"
                                        style={{cursor: "pointer"}}
                                        onClick={() => removeAdjustment(row)}
                                    ></span>
                                );
                            }}
                        ></Column>
                    </Table>

                    <div className="d-flex w-100 text-end">
                        <div className="m-2 me-auto"></div>
                        <div className="m-3">
                            <span>{tr2("total_movement")}</span>&nbsp;&nbsp;
                            <strong>{numberToLocaleString(movement, i18n.language)}</strong>
                        </div>
                    </div>

                    <div className="d-flex w-100 text-end">
                        <div className="m-2 me-auto"></div>
                        <Button
                            kind="primary"
                            //icon="icon-right-chevron-fill"
                            onClick={() => confirmTBAdjustments()}
                            compact
                            className="mt-2"
                            style={{paddingRight: "5px"}}
                        >
                            {tr2("continue")} &nbsp;&nbsp;
                            <span className="Appkit4-icon icon-right-chevron-fill p-0"></span>
                        </Button>
                    </div>
                </>
            ) : (
                <div className="mt-5">
                    <Centered>
                        <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                    </Centered>
                </div>
            )}
        </PagePanel>
    );
};

export default ImportTrialBalanceAdjustments;
