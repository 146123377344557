import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import Layout from "../../Layout/Layout";
import {HeaderType} from "../../../types/HeaderType";
import {Panel, Button} from "@appkit4/react-components";
import {useNavigate} from "react-router";
import BackButton from "../../../misc/BackButton";
import {useTr} from "../../../utils/trUtil";

declare type PropTypes = {};

const AdminFixtures: React.FC<PropTypes> = (props: PropTypes) => {
    const navigate = useNavigate();
    const trg = useTr("global");

    return (
        <Layout headerType={HeaderType.Blank}>
            <CanShowAdminMiddleware>
                <h2>
                    {trg("fixtures")}
                    <BackButton url="/settings" className="float-end" />
                </h2>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Rulesets">
                            {trg("add_and_update_initial")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/ruleset`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Ruleset Forms">
                            {trg("add_and_update_initial_ruleset_forms")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/rulesetform`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Forms">
                            {trg("add_and_update_initial_form_rows")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/formrow`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Questionnaires">
                            {trg("add_and_update_questions_in_questionnaires")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/question`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Category group">
                            {trg("add_and_update_category_groups")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/categorygroup`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Category">
                            {trg("add_and_update_category")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/category`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Ruleset Category pairs">
                            {trg("add_and_update_ruleset_category_pairs")}
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/rulesetcategorypairs`)}
                            >
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tax Treatment types">
                            {trg("add_and_update_treatment_types")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/taxtreatmenttype`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tag for Ruleset">
                            {trg("add_and_update_tags_for_rulesets")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/tagforruleset`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tag">
                            {trg("add_and_update_tag")}
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/tag`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Adjustment Default">
                            {trg("add_and_update_adjustment_default")}
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/adjustmentdefault`)}
                            >
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Adjustment Default Ruleset">
                            {trg("add_and_update_adjustment_default_ruleset")}
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/adjustmentdefaultruleset`)}
                            >
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                </div>
            </CanShowAdminMiddleware>
        </Layout>
    );
};

export default AdminFixtures;
