import React from "react";
import {FormRow} from "../../../types/FormRow";
import {RadioGroup, Radio, Input, Accordion, AccordionItem} from "@appkit4/react-components";
import {RecursiveFormRowsComponent} from ".././CalculationForm";

declare type PropTypes = {
    element: FormRow;
    parentPath: string;
};

const HtmlTableBox: React.FC<PropTypes> = ({element, parentPath}) => {
    return (
        <div className="border p-1 m-2">
            <div className="d-flex">
                <div style={{width: "55%"}}>{element.description}</div>
                <div style={{width: "5%"}}></div>
                {element.children &&
                    element.children.map((el, index) => (
                        <div key={index} className="p-2 text-center" style={{width: "20%"}}>
                            {el.description}
                        </div>
                    ))}
                {element.children && element.children.length === 0 && <div style={{width: "20%"}}></div>}
            </div>
            <div className="d-flex">
                <div style={{width: "55%"}}></div>
                <div style={{width: "5%"}}>{element.info}</div>
                {element.children &&
                    element.children.map((el, index) => (
                        <div key={index} className="px-2" style={{width: "20%"}}>
                            <Input
                                key={index}
                                style={{display: "inline"}}
                                inputProps={{style: {textAlign: "right"}}}
                                hideTitleOnInput={true}
                                value={el.value ? el.value : ""}
                            />
                        </div>
                    ))}

                {element.children && element.children.length === 0 && (
                    <div className="px-2" style={{width: "20%"}}>
                        <Input
                            style={{display: "inline"}}
                            inputProps={{style: {textAlign: "right"}}}
                            hideTitleOnInput={true}
                            value={element.value ? element.value : ""}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HtmlTableBox;
