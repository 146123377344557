import React, {useState, useEffect, useRef, useCallback} from "react";
import Layout from "../Layout/Layout";
import {useLocation} from "react-router-dom";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import PagePanel from "../../misc/PagePanel";
import {Button, Panel, Switch, Input, Filter, Tooltip, Checkbox, Modal} from "@appkit4/react-components";
import RadioButtonComponent from "./Questionnaire/RadioButtonComponent";
import InputComponent from "./Questionnaire/InputComponent";
import TableComponent from "./Questionnaire/TableComponent";
import {Question} from "../../types/Question";
import {QuestionGroup} from "../../types/QuestionGroup";
import CustomComponent from "./Questionnaire/CustomComponent";
import {AdjustmentAttachment} from "../../types/AdjustmentAttachment";
import {Attachment} from "../../types/Attachment";
import {useAutoScrollStore} from "./Questionnaire/autoscrollStore";
import TextAreaComponent from "./Questionnaire/TextAreaComponent";
import DateComponent from "./Questionnaire/DateComponent";
import TabsComponent from "./Questionnaire/TabsComponents";
import VerticalProgressBar from "./Questionnaire/VerticalProgressBar";
import CheckboxComponent from "./Questionnaire/CheckboxComponent";
import AssignedToComponent from "./Questionnaire/AssignedToComponent";
import {CommentItem} from "./Questionnaire/Comments";
import {Accordion, AccordionItem} from "@appkit4/react-components/accordion";
import classes from "./Questionnaire.module.scss";
import {FileTemplate} from "../../types/FileTemplate";
import i18n from "../../utils/i18n";
import LoadingContainer, {CircularWidth} from "../../misc/LoadingContainer";
import "./Questionnaire.scss";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import {useAuth} from "react-oidc-context";
import ModalExportComponent from "./Questionnaire/ModalExportComponent";

export type DefaultComponentProps = {
    question: Question;
    onChangeHandler: (question: Question, data: any) => void;
};

declare type AttachmentItem = {
    id: number;
    filename: string;
    created: Date;
    download_url?: string;
    adjustments?: AdjustmentAttachment[];
    attachment: Attachment;
    questions: Question[];
};

interface User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
}

const Questionnaire: React.FC = () => {
    const trg = useTr("global");
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [panelData, setPanelData] = useState<Question[]>([]);
    const [dataAreLoaded, setDataAreLoaded] = useState<boolean>(false);
    const [groups, setGroups] = useState<QuestionGroup[]>([]);
    const [groupsAreLoaded, setGroupsAreLoaded] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [attachments, setAttachments] = useState<AttachmentItem[]>([]);
    const [autoScrollTriggered, setAutoScrollTriggered] = useState<boolean>(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState<boolean>(false);
    const panelHeightRefs = useRef<{[key: string]: number}>({});
    const {autoScroll, toggleAutoScroll} = useAutoScrollStore();
    const {search} = useLocation();
    const [Q, setQ] = useState<any>({});
    const [tabsChanged, setTabsChanged] = useState<boolean>(false);
    const [comments, setComments] = useState<CommentItem[]>([]);
    const [activeGroups, setActiveGroups] = useState<string[]>([]);
    const [filterBarVisible, setFilterBarVisible] = useState<boolean>(false);
    const [filterInput, setFilterInput] = useState<{
        stringInput: string;
        assignedTo: number[];
        importance: boolean;
    }>({
        stringInput: "",
        assignedTo: [],
        importance: false,
    });
    const [filteredData, setFilteredData] = useState<Question[]>(panelData);
    const [filterInputError, setFilterInputError] = useState<boolean>(false);
    const [loadingQuestionId, setloadingQuestionId] = useState<number | null>(null);

    let filterTimeout: NodeJS.Timeout | null = null;

    const isVisible = React.useCallback(
        (question: Question) => {
            if (question.formula === "") {
                return true;
            }
            let evalQuestionFormula = new Function("Q", `try { return ${question.formula} } catch { return false }`);
            let v: boolean = evalQuestionFormula(Q);
            return v;
        },
        [Q]
    );

    const isQuestionFilledOut = React.useCallback((question) => {
        if (question.type === "info" || question.type === "hyperlink") {
            return question.attachments.length > 0;
        } else if (question.type === "table") {
            return (
                question.attachments.length > 0 ||
                (question.answer !== null &&
                    question.answer !== undefined &&
                    question.answer.data !== null &&
                    question.answer?.data?.value !== "" &&
                    question.answer?.data?.value?.length !== 0)
            );
        } else if (
            question.type === "checkbox" &&
            question.choices[0] !== "yes" &&
            (question.choices.length === 1 || question.attachments.length > 0)
        ) {
            return true;
        } else {
            return (
                question.answer !== null &&
                question.answer !== undefined &&
                question.answer.data !== null &&
                question.answer?.data?.value !== "" &&
                question.answer?.data?.value?.length !== 0
            );
        }
    }, []);

    useEffect(() => {
        if (!dataAreLoaded && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/question/`)
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setPanelData(data);
                        setDataAreLoaded(true);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [dataAreLoaded, entity, calculation, fetchApi, addErrorNotification]);

    useEffect(() => {
        if (!groupsAreLoaded && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/question/groups/`)
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setGroups(data);
                        setGroupsAreLoaded(true);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [groupsAreLoaded, entity, calculation, fetchApi, addErrorNotification]);

    useEffect(() => {
        if (!dataAreLoaded && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        const mappedAttachments = data.map((a) => ({
                            id: a.id,
                            filename: a.file.original_filename,
                            created: a.file.created,
                            download_url: a.file.download_url,
                            adjustments: a.adjustments,
                            attachment: a,
                            questions: a.questions ?? [],
                        }));
                        setAttachments(mappedAttachments);
                    } else {
                        console.error("Unexpected response format");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching attachments:", error);
                });
        }
    }, [entity, calculation, dataAreLoaded, fetchApi]);

    useEffect(() => {
        if (!dataAreLoaded && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/answer_comment/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        const mappedComments: CommentItem[] = data.map((c) => ({
                            id: c.id,
                            shortName: c.owner.first_name[0] + c.owner.last_name[0],
                            fullName: `${c.owner.first_name} ${c.owner.last_name}`,
                            commentsTime: c.created_at,
                            commentsContent: c.message,
                            answer_id: c.answer,
                        }));
                        setComments(mappedComments);
                    } else {
                        console.error("Unexpected response format");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching comments:", error);
                });
        }
    }, [entity, calculation, dataAreLoaded, fetchApi]);

    useEffect(() => {
        if (submitButtonClicked || tabsChanged) {
            setDataAreLoaded(false);
            setTabsChanged(false);
        }
    }, [submitButtonClicked, tabsChanged]);

    useEffect(() => {
        if (filterBarVisible) {
            let filteredPanelData = panelData.filter((item: Question) => {
                let isAssignedToMatch =
                    filterInput.assignedTo.length > 0
                        ? filterInput.assignedTo.includes(item.answer?.assigned_to?.id)
                        : true;
                let isImportanceMatch = filterInput.importance === false ? true : item.answer?.importance === true;
                let isNameMatch =
                    filterInput.stringInput !== ""
                        ? item.name.toLowerCase().includes(filterInput.stringInput.toLowerCase())
                        : true;
                return isNameMatch && isAssignedToMatch && isImportanceMatch;
            });
            setFilteredData(filteredPanelData.map((item) => ({...item, is_visible: isVisible(item)})));
        }
    }, [filterInput, filterBarVisible, panelData, isVisible, setFilteredData]);

    const handleSubmit = () => {
        setSubmitButtonClicked(true);
        // Handle submit logic
    };

    const submitData = React.useCallback(
        (question: Question, newValue?: any, assignedTo?: User, important?: boolean) => {
            setloadingQuestionId(question.id);
            setPanelData((s) => {
                const new_s = s.map((q) => {
                    if (q.id === question.id) {
                        const newAnswer = q.answer ? {...q.answer} : {};
                        newAnswer.data = {value: newValue};

                        // if (assignedTo !== undefined) {
                        newAnswer.assignedTo = assignedTo;
                        // }
                        if (important !== undefined) {
                            newAnswer.important = important;
                        }

                        return {...q, answer: newAnswer};
                    }
                    return q;
                });
                return new_s;
            });

            if (O.isSome(entity) && O.isSome(calculation)) {
                const url =
                    question.answer === null || question.answer.id === undefined
                        ? `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/answer/`
                        : `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/answer/${question.answer.id}/`;
                const method = question.answer === null || question.answer.id === undefined ? "POST" : "PATCH";
                let requestData = {
                    question_id: question.id,
                };
                if (newValue !== undefined) {
                    requestData["data"] = {value: newValue};
                }
                if (assignedTo && assignedTo !== undefined) {
                    requestData["assigned_to"] = assignedTo.email;
                } else {
                    requestData["assigned_to"] = null;
                }
                if (important !== undefined) {
                    requestData["importance"] = important;
                }
                fetchApi(url, method, requestData)
                    .then((res) => res.json())
                    .then((res) => {
                        if (typeof res.error !== "undefined") {
                            addErrorNotification(res);
                        } else {
                            setPanelData((s) =>
                                s.map((q) => {
                                    const new_q = q.id === question.id ? {...q, answer: res} : q;
                                    return {...new_q, is_visible: isVisible(new_q)};
                                })
                            );
                        }
                        setloadingQuestionId(null);
                    })
                    .catch((error) => {
                        console.error("Error submitting data:", error);
                        setloadingQuestionId(null);
                    });
            }
        },
        [addErrorNotification, isVisible, fetchApi, calculation, entity]
    );

    const onQuestionChangeHandler = useCallback(
        (question: Question, data: any) => {
            if (dataAreLoaded) {
                submitData(question, data);
            }
        },
        [submitData, dataAreLoaded]
    );

    useEffect(() => {}, [panelData]);

    useEffect(() => {
        if (panelData) {
            const pom = {};
            panelData.forEach((i: Question) => {
                pom[i.id] = i.answer?.data?.value;
            });
            setQ(pom);
        }
    }, [panelData]);

    const handleInputChange = useCallback(
        (question: Question, newValue: any) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            const newTimeoutId = setTimeout(() => {
                if (newValue) {
                    submitData(question, newValue);
                }
            }, 1500);

            setTimeoutId(newTimeoutId);
        },
        [submitData, timeoutId]
    );

    const handleFilterInputChange = (value) => {
        if (filterTimeout) {
            clearTimeout(filterTimeout as NodeJS.Timeout);
        }
        if (value === "") {
            setFilterInputError(false);
            setFilterInput({...filterInput, stringInput: value});
            return;
        }
        filterTimeout = setTimeout(() => {
            let stringMatching = filteredData.filter((item: Question) => {
                return item.name.toLowerCase().includes(value.toLowerCase());
            });
            stringMatching = stringMatching.map((item) => ({...item, is_visible: isVisible(item)}));
            if (stringMatching.length === 0) {
                setFilterInputError(true);
            } else {
                setFilterInputError(false);
                setFilterInput({...filterInput, stringInput: value});
            }
        }, 1000);
    };

    const handleTabsChange = React.useCallback(() => {
        setTabsChanged(true);
    }, [setTabsChanged]);

    const handleAttachmentsChange = React.useCallback(
        (question: Question, attachment: Attachment, deleteAttachment?: boolean) => {
            if (deleteAttachment) {
                setPanelData((s) => {
                    const new_s = [...s];
                    const questionIndex = new_s.findIndex((q) => q.id === question.id);
                    if (questionIndex !== -1) {
                        new_s[questionIndex].attachments = new_s[questionIndex].attachments?.filter(
                            (a) => a.attachment !== attachment.id
                        );
                    }
                    return new_s;
                });
            } else {
                setPanelData((s) => {
                    const new_s = [...s];
                    const questionIndex = new_s.findIndex((q) => q.id === question.id);
                    if (questionIndex !== -1) {
                        new_s[questionIndex].attachments?.push(attachment);
                    }
                    return new_s;
                });
                setDataAreLoaded(false);
            }
        },
        [setPanelData]
    );

    const handleGroupClick = (groups: string[]) => {
        setActiveGroups(groups);
    };

    const handleImportantButtonClick = React.useCallback(
        (item: Question) => {
            const currentImportance = item.answer?.importance ?? false;
            submitData(item, item.answer?.data?.value, undefined, !currentImportance);
        },
        [submitData]
    );

    const panels = React.useMemo(() => {
        if (panelData.length > 0) {
            const _groups = {};
            if (filteredData.length > 0) {
                filteredData.forEach((item: Question) => {
                    let idx =
                        item.group === null
                            ? 0
                            : !item.group
                            ? 0
                            : typeof item.group === "number"
                            ? item.group
                            : item.group.id;
                    let el = {...item, is_visible: isVisible(item)};
                    if (!_groups[idx]) _groups[idx] = [el];
                    else _groups[idx].push(el);
                });
            } else {
                panelData.forEach((item: Question) => {
                    let idx =
                        item.group === null
                            ? 0
                            : !item.group
                            ? 0
                            : typeof item.group === "number"
                            ? item.group
                            : item.group.id;
                    let el = {...item, is_visible: isVisible(item)};
                    if (!_groups[idx]) _groups[idx] = [el];
                    else _groups[idx].push(el);
                });
            }

            return (
                <Accordion
                    multiple={true}
                    onClick={(groups: string[]) => handleGroupClick(groups)}
                    activeKeys={activeGroups}
                >
                    {Object.entries(_groups).map(([key, items]: [string, any]) => {
                        let g = groups.find((g) => g.id === parseInt(key));
                        if (items.length === 0) return <React.Fragment key={key}></React.Fragment>;
                        let catAlphaID = String.fromCharCode(g ? g.id + 64 : 65) + ".";
                        let _questions = items.map((item: Question, index: number) => {
                            let content = <React.Fragment></React.Fragment>;
                            switch (item.type) {
                                case "radiobutton":
                                    content = (
                                        <RadioButtonComponent
                                            question={item}
                                            onChangeHandler={onQuestionChangeHandler}
                                        />
                                    );
                                    break;

                                case "date":
                                    content = (
                                        <DateComponent question={item} onChangeHandler={onQuestionChangeHandler} />
                                    );
                                    break;

                                case "textarea":
                                    content = (
                                        <TextAreaComponent question={item} onChangeHandler={onQuestionChangeHandler} />
                                    );
                                    break;

                                case "input":
                                case "number":
                                    content = <InputComponent question={item} onChangeHandler={handleInputChange} />;
                                    break;

                                case "table":
                                    content = <TableComponent question={item} onChangeHandler={handleInputChange} />;
                                    break;

                                case "custom_component":
                                    content = (
                                        <CustomComponent question={item} onChangeHandler={onQuestionChangeHandler} />
                                    );
                                    break;

                                case "checkbox":
                                    content = (
                                        <CheckboxComponent question={item} onChangeHandler={onQuestionChangeHandler} />
                                    );
                                    break;

                                default:
                                    break;
                            }

                            return item.is_visible ? (
                                <div key={item.id} style={{display: "flex", alignItems: "start", marginBottom: "1rem"}}>
                                    <Panel
                                        id={`question-${item.id}`}
                                        className={`question-panel bg-light mb-3`}
                                        style={{
                                            flex: "99%",
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                        }}
                                        ref={(el) => {
                                            if (el) {
                                                panelHeightRefs.current[item.id] = el.clientHeight;
                                            }
                                        }}
                                    >
                                        {loadingQuestionId === item.id && (
                                            <LoadingContainer circularWidthOverride={CircularWidth.small} />
                                        )}
                                        <div className="d-flex justify-content-between">
                                            <h6>{item.question_alpha_id + " " + item.name}</h6>
                                            <div className="d-flex justify-content-end align-items-center">
                                                {O.isSome(calculation) && (
                                                    <div
                                                        style={{width: "200px", marginRight: "5px", marginTop: "-10px"}}
                                                    >
                                                        <AssignedToComponent
                                                            initialUsers={calculation.value.participants}
                                                            assignedUser={item.answer?.assigned_to}
                                                            setAssignedUser={(user: User | undefined) => {
                                                                submitData(item, undefined, user);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <Tooltip
                                                    content={
                                                        item.answer?.importance ? "Unmark important" : "Mark important"
                                                    }
                                                >
                                                    <button
                                                        className="Appkit4-icon icon-circle-warning-outline"
                                                        style={{
                                                            height: "48px",
                                                            color: item.answer?.importance && "#FFBF1F",
                                                        }}
                                                        onClick={() => {
                                                            handleImportantButtonClick(item);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        {item.description && <p>{item.description}</p>}
                                        <p key={item.id} className="pt-2">
                                            {item.templates && item.templates.length ? (
                                                <React.Fragment>
                                                    {item.templates.map((t: FileTemplate | number, i) =>
                                                        typeof t === "number" ||
                                                        !i18n.language.startsWith(t.language) ? (
                                                            <React.Fragment key={`empty-${i}`}></React.Fragment>
                                                        ) : (
                                                            <React.Fragment key={t.id + "-" + i}>
                                                                <a href={t.public_url} target="_parent">
                                                                    {t.name}
                                                                </a>
                                                                {item.templates && item.templates.length > i + 1 ? (
                                                                    <>&nbsp;|&nbsp;</>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        {content}
                                        <br />
                                        <TabsComponent
                                            attachments={attachments}
                                            comments={comments}
                                            question={item}
                                            TabsChanged={handleTabsChange}
                                            AttachmentsChanged={handleAttachmentsChange}
                                        />
                                    </Panel>
                                    <div style={{flex: "%", display: "flex", alignItems: "stretch"}}>
                                        <VerticalProgressBar
                                            status={
                                                item.answer?.importance
                                                    ? "important"
                                                    : isQuestionFilledOut(item)
                                                    ? "normal"
                                                    : submitButtonClicked
                                                    ? "error"
                                                    : "unanswered"
                                            }
                                            height={panelHeightRefs.current[item.id] || 195}
                                        />
                                    </div>
                                </div>
                            ) : null;
                        });

                        let totalItems = items.filter((i) => i.is_visible === true).length;
                        let totalValidItems = items.filter(
                            (i) => isQuestionFilledOut(i) === true && i.is_visible === true
                        ).length;

                        return (
                            <AccordionItem
                                className={
                                    classes.groupAccordionItem +
                                    ` bg-${totalValidItems === totalItems ? "success" : "secondary"} bg-opacity-10`
                                }
                                key={key}
                                templateHeader={() => (
                                    <div className="d-flex w-100">
                                        <div className="flex-grow-1">{catAlphaID + " " + g?.name}</div>
                                        <div>
                                            <small className="fw-normal">{`${totalValidItems}/${totalItems}`}</small>
                                            {totalValidItems === totalItems ? (
                                                <>
                                                    &nbsp;
                                                    <span className="Appkit4-icon icon-check-mark-fill text-success"></span>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                )}
                                itemKey={key}
                                style={{fontSize: "1.2rem", borderRadius: "10px"}}
                            >
                                {_questions}
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            );
        }
    }, [
        calculation,
        submitData,
        panelData,
        isVisible,
        groups,
        activeGroups,
        attachments,
        submitButtonClicked,
        handleInputChange,
        onQuestionChangeHandler,
        comments,
        handleTabsChange,
        handleAttachmentsChange,
        isQuestionFilledOut,
        filteredData,
        loadingQuestionId,
        handleImportantButtonClick,
    ]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        const selectedQuestionId = params.get("questionId");
        if (selectedQuestionId && panelData) {
            const selectedQuestion = panelData.find((question) => question.id === Number(selectedQuestionId));
            const selectedQuestionGroup = selectedQuestion ? selectedQuestion.group : undefined;
            if (selectedQuestionGroup) {
                setActiveGroups([selectedQuestionGroup.toString()]);
            }
            const selectedQuestionPanel = document.getElementById(`question-${selectedQuestionId}`);
            if (selectedQuestionPanel) {
                const timeoutId = setTimeout(() => {
                    selectedQuestionPanel.scrollIntoView({behavior: "smooth", block: "start"});
                }, 1000);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [panelData, search, setActiveGroups]);

    // const scrollToCurrentQuestion = React.useCallback(() => {
    //     const params = new URLSearchParams(search);
    //     const selectedQuestionId = params.get("questionId");

    //     if (!selectedQuestionId && autoScroll && !autoScrollTriggered && dataAreLoaded) {
    //         const firstQuestionWithoutAnswer = panelData.find(
    //             (question) => !isQuestionFilledOut(question) && isVisible(question)
    //         );
    //         const firstInfoQuestionWithoutAttachment = panelData.find((question) => {
    //             if (question.type === "info" && isVisible(question)) {
    //                 const hasAttachment = attachments.some((a) =>
    //                     a.questions.some((q) => q.question_id === question.id)
    //                 );
    //                 return !hasAttachment;
    //             }
    //             return false;
    //         });

    //         let targetQuestion: Question | undefined = undefined;
    //         if (firstInfoQuestionWithoutAttachment && firstQuestionWithoutAnswer) {
    //             targetQuestion =
    //                 firstInfoQuestionWithoutAttachment.id < firstQuestionWithoutAnswer.id
    //                     ? firstInfoQuestionWithoutAttachment
    //                     : firstQuestionWithoutAnswer;
    //         } else {
    //             targetQuestion = firstInfoQuestionWithoutAttachment || firstQuestionWithoutAnswer;
    //         }

    //         if (targetQuestion) {
    //             const groupOfTargetQuestion = targetQuestion.group;
    //             if (groupOfTargetQuestion) {
    //                 setActiveGroups([groupOfTargetQuestion.toString()]);
    //             }
    //             const timeoutId = setTimeout(() => {
    //                 const targetQuestionPanel = document.getElementById(`question-${targetQuestion?.id}`);
    //                 if (targetQuestionPanel && activeGroups.length > 0) {
    //                     targetQuestionPanel.scrollIntoView({behavior: "smooth", block: "start"});
    //                     setAutoScrollTriggered(true);
    //                 }
    //             }, 1000);
    //             return () => clearTimeout(timeoutId);
    //         }
    //     }
    // }, [
    //     panelData,
    //     autoScrollTriggered,
    //     attachments,
    //     autoScroll,
    //     dataAreLoaded,
    //     activeGroups.length,
    //     search,
    //     isQuestionFilledOut,
    // ]);

    const scrollToCurrentQuestion = React.useCallback(() => {
        const params = new URLSearchParams(search);
        const selectedQuestionId = params.get("questionId");

        if (!selectedQuestionId && autoScroll && !autoScrollTriggered && dataAreLoaded) {
            const targetQuestion = findTargetQuestion(panelData, attachments, isQuestionFilledOut);
            if (targetQuestion) {
                scrollToQuestion(targetQuestion);
            }
        }
    }, [
        panelData,
        autoScrollTriggered,
        attachments,
        autoScroll,
        dataAreLoaded,
        activeGroups.length,
        search,
        isQuestionFilledOut,
    ]);

    const findTargetQuestion = (panelData, attachments, isQuestionFilledOut) => {
        const firstQuestionWithoutAnswer = findFirstQuestionWithoutAnswer(panelData, isQuestionFilledOut);
        const firstInfoQuestionWithoutAttachment = findFirstInfoQuestionWithoutAttachment(panelData, attachments);

        return getHigherPriorityQuestion(firstInfoQuestionWithoutAttachment, firstQuestionWithoutAnswer);
    };

    const findFirstQuestionWithoutAnswer = (panelData, isQuestionFilledOut) => {
        return panelData.find((question) => !isQuestionFilledOut(question) && isVisible(question));
    };

    const findFirstInfoQuestionWithoutAttachment = (panelData, attachments) => {
        return panelData.find((question) => {
            if (question.type === "info" && isVisible(question)) {
                const hasAttachment = attachments.some((a) => a.questions.some((q) => q.question_id === question.id));
                return !hasAttachment;
            }
            return false;
        });
    };

    const getHigherPriorityQuestion = (firstInfoQuestionWithoutAttachment, firstQuestionWithoutAnswer) => {
        if (firstInfoQuestionWithoutAttachment && firstQuestionWithoutAnswer) {
            return firstInfoQuestionWithoutAttachment.id < firstQuestionWithoutAnswer.id
                ? firstInfoQuestionWithoutAttachment
                : firstQuestionWithoutAnswer;
        }
        return firstInfoQuestionWithoutAttachment || firstQuestionWithoutAnswer;
    };

    const scrollToQuestion = (targetQuestion) => {
        const groupOfTargetQuestion = targetQuestion.group;
        if (groupOfTargetQuestion) {
            setActiveGroups([groupOfTargetQuestion.toString()]);
        }
        const timeoutId = setTimeout(() => {
            const targetQuestionPanel = document.getElementById(`question-${targetQuestion.id}`);
            if (targetQuestionPanel && activeGroups.length > 0) {
                targetQuestionPanel.scrollIntoView({behavior: "smooth", block: "start"});
                setAutoScrollTriggered(true);
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    };

    useEffect(() => {
        if (!autoScrollTriggered && dataAreLoaded) scrollToCurrentQuestion();
    }, [scrollToCurrentQuestion, autoScrollTriggered, dataAreLoaded]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={trg("questionnaire")}
                    buttons={
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <Button
                                icon="Appkit4-icon icon-filter-outline"
                                kind="text"
                                style={{marginRight: "10px"}}
                                onClick={() =>
                                    filterBarVisible ? setFilterBarVisible(false) : setFilterBarVisible(true)
                                }
                            >
                                {filterBarVisible ? trg("close_filters") : trg("filters")}
                            </Button>
                            <Switch
                                onChange={toggleAutoScroll}
                                checked={autoScroll}
                                showIndicator
                                style={{marginRight: "10px"}}
                            >
                                AutoScroll
                            </Switch>
                            {O.isSome(entity) && O.isSome(calculation) && (
                                <ModalExportComponent
                                    calculation={calculation.value}
                                    entity={entity.value}
                                    questions={panelData}
                                    isVisible={isVisible}
                                />
                            )}
                        </div>
                    }
                >
                    {filterBarVisible === true && (
                        <div className="d-flex justify-content-between align-items-center" style={{height: "100%"}}>
                            <div style={{width: "70%"}}>
                                <Input
                                    type="text"
                                    placeholder={trg("filter_by_keyword")}
                                    hideTitleOnInput={true}
                                    prefix={<div className="Appkit4-icon icon-filter-outline"></div>}
                                    onChange={handleFilterInputChange}
                                    error={filterInputError}
                                    errorNode={
                                        <div style={{width: "100%", color: "#C52A1A"}}>
                                            {<span className="Appkit4-icon icon-search-not-found-outline"></span>}
                                            {trg("filter_error")}
                                        </div>
                                    }
                                    allowClear
                                />
                            </div>
                            <div style={{width: "15%"}}>
                                <Filter
                                    title={trg("assigned_to")}
                                    multiple
                                    data={filteredData
                                        .map(
                                            (item: Question) =>
                                                item.is_visible &&
                                                item.answer?.assigned_to && {
                                                    label: item.answer.assigned_to.email.toString(),
                                                    value: item.answer.assigned_to.id,
                                                    desc: filteredData.filter(
                                                        (i) => i.answer?.assigned_to?.id === item.answer.assigned_to.id
                                                    ).length,
                                                }
                                        )
                                        .filter(Boolean)
                                        .reduce((unique, item) => {
                                            return unique.some(
                                                (uItem) =>
                                                    uItem.label === item.label &&
                                                    uItem.value === item.value &&
                                                    uItem.desc === item.desc
                                            )
                                                ? unique
                                                : [...unique, item];
                                        }, [])}
                                    onSelect={(value) => {
                                        setFilterInput({...filterInput, assignedTo: value});
                                    }}
                                    value={filterInput.assignedTo}
                                    filterListStyle={{position: "absolute", zIndex: 1}}
                                />
                            </div>
                            <div style={{width: "10%"}}>
                                <Checkbox
                                    checked={filterInput.importance}
                                    value={filterInput.importance.toString()}
                                    onChange={(value) => {
                                        setFilterInput({...filterInput, importance: value});
                                    }}
                                >
                                    <span>
                                        {trg("important") +
                                            "  " +
                                            filteredData
                                                .filter((item: Question) => item.is_visible && item.answer?.importance)
                                                .length.toString()}
                                    </span>
                                </Checkbox>
                            </div>
                            <div style={{width: "5%"}}>
                                <Tooltip content={trg("clear_filters")} position="bottom">
                                    <Button
                                        kind="text"
                                        compact
                                        icon="icon-circle-delete-outline"
                                        onClick={() => {
                                            setFilterInput({stringInput: "", assignedTo: [], importance: false});
                                        }}
                                        style={{width: "100%", height: "100%", padding: "6px"}}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    {panelData.length > 0 && (
                        <>
                            <Panel title="">{panels}</Panel>
                            <Button onClick={handleSubmit}>{trg("submit")}</Button>
                        </>
                    )}
                    {dataAreLoaded && panelData.length === 0 && <>{trg("list_of_questions_is_empty")}</>}
                    {!dataAreLoaded && <LoadingContainer circularWidthOverride={CircularWidth.small} />}
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default Questionnaire;
