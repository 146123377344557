import {create} from "zustand";
import {THEME_KEYWORD} from "../Constants";
import {Theme} from "../types/Theme";

interface ThemeState {
    theme: Theme;
    setTheme: (newTheme: Theme) => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
    theme: Theme.LIGHT,
    setTheme: (newTheme: Theme) => {
        localStorage.setItem(THEME_KEYWORD, newTheme);
        set(() => ({theme: newTheme}));
    },
}));
