import React from "react";
import {useGlobalStore} from "../../stores/GlobalStore";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import Create from "./Create";
import * as O from "fp-ts/Option";

const Settings: React.FC = () => {
    const calculation = useGlobalStore((s) => s.currentCalculation);

    return (
        <CanShowCalculationMiddleware>
            <Create calculation={O.isSome(calculation) ? calculation.value : undefined} />
        </CanShowCalculationMiddleware>
    );
};

export default Settings;
