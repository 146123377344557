import React from "react";
import {Notification} from "@appkit4/react-components/notification";

declare type PropTypes = {
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
};

declare type StateTypes = {
    hasError: boolean;
    errorMessage: string;
};

export default class ErrorBoundary extends React.Component<PropTypes, StateTypes> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {hasError: false, errorMessage: ""};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, errorMessage: error.message};
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        console.warn(typeof error);
        console.error(errorInfo);
        console.warn(typeof errorInfo);

        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
    }

    handleClose = () => {};

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Notification
                    title="Sorry, something is wrong"
                    message={this.state.errorMessage}
                    status="error"
                    onClose={this.handleClose}
                />
            );
        }

        return this.props.children;
    }
}
