import React from "react";
import {Navigation, NavigationItem} from "@appkit4/react-components/navigation";
import {useSidebarStore} from "../../stores/SidebarStore";
import "./LayoutSidebar.scss";
import UserAvatar from "./UserAvatar";
import {useNavigate} from "react-router-dom";
import {useTr} from "../../utils/trUtil";

// TODO: Style, this is sad.
const LayoutSidebar: React.FC = () => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [isActive, setIsActive] = useSidebarStore((s) => [s.isActive, s.setIsActive]);

    const navigate = useNavigate();
    const trg = useTr("global");

    const onClickLogo = (event: React.MouseEvent<HTMLElement>) => {};

    const redirect = (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item: NavigationItem,
        index: number
    ) => {
        setSelectedIndex(index);
        switch (index) {
            case 0:
                navigate("/settings");
                break;
            case 1:
                navigate("/.auth/logout");
                break;
            default:
                break;
        }
    };

    const redirectSub = (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item: NavigationItem | undefined,
        index: number | undefined,
        indexParent: number
    ) => {
        setSelectedIndex(indexParent);
        // routing logic here
    };

    const navList = [
        {
            name: "Settings",
            prefixIcon: "setting",
        },
        // {
        //     name: "SIGN OUT",
        //     prefixIcon: "log-out",
        // },
        // {
        //     name: "Styleguide",
        //     prefixIcon: "venn-abc",
        //     suffixIcon: "down-chevron",
        // },
        // {
        //     name: "Components",
        //     prefixIcon: "particulates",
        //     suffixIcon: "down-chevron",
        // },
        // {
        //     name: "Support",
        //     prefixIcon: "help-question",
        //     suffixIcon: "down-chevron",
        //     children: [
        //         {
        //             name: "FAQs",
        //         },
        //         {
        //             name: "Versions",
        //         },
        //     ],
        // },
    ];

    return (
        <div
            className={`ap-navigation-user-menu-wrapper ${isActive ? "active" : " "}`}
            onClick={() => setTimeout(() => setIsActive(!isActive), 300)}
        >
            <Navigation
                hasLogo={true}
                navList={navList}
                titleTemplate={() => <span>Corporate Tax Flow</span>}
                width={280}
                collapsed={!isActive}
                onClickLogo={onClickLogo}
                onClickItem={redirect}
                onClickSubItem={redirectSub}
                selectedIndex={selectedIndex}
                onClickCollapseEvent={() => setIsActive(!isActive)}
                // userTemplate={() => <UserAvatar onClick={() => setIsActive(!isActive)} />}
                direction="top-right"
            ></Navigation>
        </div>
    );
};
export default LayoutSidebar;
