import React from "react";
import {RadioGroup, Radio} from "@appkit4/react-components";
import {InputPropTypes} from "../CalculationFormElement";

const InputRadioGroup: React.FC<InputPropTypes> = (props) => {
    const [checkedElement, setCheckedElement] = React.useState<string>();

    React.useEffect(() => {
        setCheckedElement((s) => {
            let checked = "";
            if (props.element.children) {
                props.element.children.forEach((child) => {
                    if (child.value === "1") checked = child.xml_node_name;
                });
            }
            return checked === "" ? undefined : props.currentPath + "." + checked;
        });
    }, [props]);

    React.useEffect(() => {
        if (checkedElement) {
            if (props.element.children) {
                props.element.children.forEach((child) => {
                    let v = props.currentPath + "." + child.xml_node_name === checkedElement ? "1" : "0";
                    props.onChangeHandler(child, v, true);
                });
            }
        }
    }, [checkedElement, props]);

    return (
        <RadioGroup name={props.currentPath} value={checkedElement} onChange={(v: string) => setCheckedElement(v)}>
            {/* {renderElement(element)} */}
            {/* {element.children && <RecursiveFormRowsComponent rows={element.children} parentPath={path} />} */}
            {/* {element.children.map((el) => (
                    <CalculationFormElement element={el} parentPath={path} />
                ))} */}
            {props.element.children &&
                props.element.children.map((el) =>
                    el.input_type === "radiobutton" ? (
                        <Radio
                            key={el.xml_node_name}
                            //defaultChecked={!!parseInt(el.value)}
                            checked={checkedElement === el.xml_node_name}
                            value={`${props.currentPath}.${el.xml_node_name}`}
                            // onClick={(v) => {
                            //     console.log(el.xml_node_name);
                            //     setCheckedElement(el.xml_node_name);
                            // }}
                        >
                            {el.description}
                        </Radio>
                    ) : (
                        <></>
                    )
                )}
        </RadioGroup>
    );
};

export default InputRadioGroup;
