import React from "react";
import PagePanel from "../../../misc/PagePanel";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Button, Loading} from "@appkit4/react-components";
import * as O from "fp-ts/Option";
import {DeferredTaxEntry} from "../../../types/DeferredTaxEntry";
import Centered from "../../../misc/Centered";
import {DeferredTaxChildPropTypes} from "./DeferredTaxBase";
import DeferredTaxForm from "./DeferredTaxForm";
import {useNavigate} from "react-router-dom";

declare type PropTypes = DeferredTaxChildPropTypes & {newEntryWasAdded?: boolean};

const DeferredTaxCurrentEntries: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const columns = [
        "type",
        "name",
        "description",
        "tax_type",
        "book_value",
        "tax_value",
        "diferrence",
        "rate",
        "tax",
        "value_adjustment",
        "tax_recognised",
    ];

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [entries, setEntries] = React.useState<DeferredTaxEntry[]>([]);
    const [entriesAreLoaded, setEntriesAreLoaded] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && entriesAreLoaded === false) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/deferred_tax_entry/`)
                .then((res: any) => res.json())
                .then((res: DeferredTaxEntry | any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setEntries(
                            res.map((t: DeferredTaxEntry) => {
                                return t;
                            })
                        );
                    }
                    setEntriesAreLoaded(true);
                });
        }
    }, [entity, calculation, entriesAreLoaded, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        if (props.newEntryWasAdded) {
            setEntriesAreLoaded(false);
        }
    }, [props.newEntryWasAdded]);

    return (
        <>
            {entriesAreLoaded && entries.length > 0 ? (
                <div className="ap-table ap-table-condensed ap-table-striped table-responsive deferred-tax-table-wrapper">
                    <table className="table-head-padding">
                        <thead>
                            <tr>
                                {Object.entries(entries[0])
                                    .filter(([key, val], index) => columns.includes(key))
                                    .map(([key, val]) => (
                                        <th key={key}>{trg(key)}</th>
                                    ))}
                                <th>{trg("action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries
                                .sort((a, b) => (a.adjustment_entry ? -1 : 1))
                                .map((entry) => (
                                    <tr
                                        key={entry.id}
                                        className={`${
                                            entry.adjustment_entry !== null
                                                ? "bg-warning bg-opacity-10"
                                                : "bg-success bg-opacity-10"
                                        }`}
                                    >
                                        {Object.entries(entry)
                                            .filter(([key, val]) => columns.includes(key))
                                            .map(([key, val]) => {
                                                return (
                                                    <td
                                                        key={key}
                                                        className={`${
                                                            isNaN(val) || val === null ? "" : "text-end"
                                                        } text-truncate`}
                                                        style={{maxWidth: 150, wordBreak: "break-word"}}
                                                        title={["description", "name"].includes(key) ? val + "" : ""}
                                                    >
                                                        {isNaN(val) || val === null || val == "" ? (
                                                            key === "type" ? (
                                                                val === null ? (
                                                                    <div className="ms-4">{trg("manual_entry")}</div>
                                                                ) : (
                                                                    <div>
                                                                        <div
                                                                            className={
                                                                                entry.adjustment_entry !== null
                                                                                    ? ""
                                                                                    : "ms-4"
                                                                            }
                                                                        >
                                                                            {entry.adjustment_entry !== null && (
                                                                                <span className="Appkit4-icon icon-lockclosed-locked-outline"></span>
                                                                            )}
                                                                            {trg(val)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <>{val}</>
                                                            )
                                                        ) : (
                                                            <>{parseFloat(val).toFixed(2)}</>
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        <td>
                                            {O.isSome(entity) && O.isSome(calculation) && (
                                                <div className="flex">
                                                    <DeferredTaxForm
                                                        entry={entry}
                                                        entity={entity.value}
                                                        calculation={calculation.value}
                                                        onSaveHandler={() => setEntriesAreLoaded(false)}
                                                        onDeleteHandler={() => setEntriesAreLoaded(false)}
                                                    />
                                                    {entry.adjustment_entry !== null && (
                                                        <Button
                                                            kind="text"
                                                            className="d-inline btn-small"
                                                            onClick={() => {
                                                                navigate(
                                                                    `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/adjustment-entries/${entry.type}/${entry.adjustment_entry}?backlink=${window.location.pathname}`
                                                                );
                                                            }}
                                                        >
                                                            {/* <span className="Appkit4-icon icon-edit-outline"></span> */}
                                                            <span
                                                                className="Appkit4-icon icon-link-outline"
                                                                title={`Entry is editable in: ${trg(
                                                                    entry.type ? entry.type : "adjustment entries"
                                                                )}`}
                                                            ></span>
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            ) : entriesAreLoaded && entries.length === 0 ? (
                <span>{trg("list_of_entries_is_empty")}</span>
            ) : (
                <Centered>
                    <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                </Centered>
            )}
        </>
    );
};

export default DeferredTaxCurrentEntries;
