import {User} from "../types/UserInfo";
import * as O from "fp-ts/Option";
import {Entity} from "../types/Entity";
import {Calculation} from "../types/Calculation";

export const getClients = (userInfo: O.Option<User>) => {
    if (
        O.isNone(userInfo) ||
        userInfo.value.backend_info === undefined ||
        O.isNone(userInfo.value.backend_info.clients)
    )
        return O.none;
    return userInfo.value.backend_info.clients.value;
};

export const containsEntity = (userInfo: O.Option<User>, entityHash: string): boolean => {
    if (
        O.isNone(userInfo) ||
        userInfo.value.backend_info === undefined ||
        O.isNone(userInfo.value.backend_info.clients)
    ) {
        return false;
    } else {
        var entity = null;
        Object.entries(userInfo.value.backend_info.clients).forEach((client) => {
            let c = client[1];
            let filtered = c.entities.filter((e: Entity) => e.hash === entityHash);
            if (filtered.length > 0) entity = filtered[0];
            return entity;
        });
        if (entity) return true;
    }

    return false;
};

export const findCalculationInUserInfo = (user: O.Option<User>, CalculationHash: string) => {
    if (O.isNone(user) || user.value.backend_info === undefined || O.isNone(user.value.backend_info.clients))
        return O.none;

    var calculations: Calculation[] = [];
    Object.entries(user.value.backend_info.clients).forEach(([key, client]) => {
        client.entities.forEach((entity: Entity) => {
            var result: Calculation[] = [];
            if (typeof entity.calculations !== "undefined")
                result = entity.calculations.filter((calculation: Calculation) => calculation.hash === CalculationHash);
            if (result.length > 0) calculations.push(result[0]);
        });
    });

    if (calculations.length > 0) return O.some(calculations[0]);
    return O.none;
};

export const getCalculationBaseUrl = (
    entity?: O.Option<Entity>,
    calculation?: O.Option<Calculation>,
    entity_hash?: string,
    calculation_hash?: string
) => {
    if (entity && calculation && O.isSome(entity) && O.isSome(calculation)) {
        return `/calculation/${entity.value.hash}/${calculation.value.hash}`;
    }
    if (entity_hash && calculation_hash) {
        return `/calculation/${entity_hash}/${calculation_hash}/`;
    }
    return null;
};

export const isAdmin = (userInfo: O.Option<User>) => {
    if (O.isSome(userInfo) && userInfo.value !== undefined && userInfo.value.backend_info !== undefined) {
        return userInfo.value.backend_info.admin;
    }
};
