import React from "react";
import {useNavigate} from "react-router-dom";
import {useTr} from "../../utils/trUtil";

import {Panel} from "@appkit4/react-components";
import "./LayoutSettings.scss";
import {RoutingTable} from "../../Constants";

declare type PropTypes = {
    children: React.ReactNode;
    title?: string;
};

export declare type LeftbarItemType = {
    key: string;
    text: string;
    link: string;
    isActive?: boolean;
};

// TODO: Style, this is sad.
export const LayoutSettings: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");

    const items: LeftbarItemType[] = [
        //{key: "notifications", text: "Notifications", link: RoutingTable.settings_notifications},
        {key: "clients", text: trg("clients"), link: RoutingTable.my_clients},
        {key: "entities", text: trg("entites"), link: RoutingTable.my_clients},
        //{key: "extended", text: "Extended settings", link: RoutingTable.settings_extended},
    ];
    const title: string = trg("settings");

    const navigate = useNavigate();

    return (
        <>
            <div className="container-fluid mt-3">
                <div className="d-flex">
                    <div className="layout-left-settings-sidebar">
                        <Panel>
                            {typeof props.title !== "undefined" ? <h4>{props.title}</h4> : <h4>{title}</h4>}
                            {items.map((item) => {
                                return (
                                    <div
                                        key={item.key}
                                        className={`d-flex leftbar-item ${
                                            window.location.pathname === item.link || item.isActive
                                                ? "leftbar-is-active"
                                                : ""
                                        }`}
                                        onClick={() => navigate(item.link)}
                                    >
                                        <div className="flex-grow-1">
                                            <div className="leftbar-text">{item.text}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Panel>
                    </div>
                    <div className="flex-grow-1 px-3 py-1">{props.children}</div>
                </div>
            </div>
        </>
    );
};
