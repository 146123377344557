import {Button, Checkbox} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/esm/modal/Modal";
import * as O from "fp-ts/Option";
import React from "react";
import {useTr} from "../../utils/trUtil";
import {useGlobalStore} from "../../stores/GlobalStore";

const ImportSettings: React.FC = () => {
    const trg = useTr("global");

    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const updateCurrentCalculationEntity = useGlobalStore((s) => s.updateCurrentCalculationEntity);

    const [isOpen, setIsOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        removing_whitespace_account_1: true,
        zero_padding_account_1: false,
        removing_whitespace_account_2: false,
        zero_padding_account_2: false,
        combination_account_1_and_account_2: false,
    });

    React.useEffect(() => {
        if (O.isSome(entity)) {
            setFormData({
                removing_whitespace_account_1: entity.value.removing_whitespace_account_1,
                zero_padding_account_1: entity.value.zero_padding_account_1,
                removing_whitespace_account_2: entity.value.removing_whitespace_account_2,
                zero_padding_account_2: entity.value.zero_padding_account_2,
                combination_account_1_and_account_2: entity.value.combination_account_1_and_account_2,
            });
        }
    }, [entity]);

    const onSubmitHandler = () => {
        if (O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/`, "patch", formData)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else if (O.isSome(entity)) {
                        updateCurrentCalculationEntity(data);
                        setIsOpen(false);
                    }
                });
        }
    };

    return (
        <>
            <Button kind="tertiary" className="mx-3" onClick={() => setIsOpen(true)}>
                {trg("import_settings")}
            </Button>
            <Modal
                visible={isOpen}
                modalStyle={{width: "48%", minWidth: "360px"}}
                title={trg("import_settings")}
                footer={
                    <>
                        <Button onClick={() => setIsOpen(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={onSubmitHandler} kind="primary">
                            {trg("save")}
                        </Button>
                    </>
                }
            >
                <div className="p-0">
                    <div className="mt-2">
                        <Checkbox
                            checked={formData.removing_whitespace_account_1}
                            onChange={(v) => setFormData((s) => ({...s, removing_whitespace_account_1: v}))}
                        >
                            {trg("remove_white_space_1st_account")}
                        </Checkbox>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 123 456{" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 123456
                        </small>
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            checked={formData.zero_padding_account_1}
                            onChange={(v) => setFormData((s) => ({...s, zero_padding_account_1: v}))}
                        >
                            {trg("zero_padding_1st_account")}
                        </Checkbox>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 23456{" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 023456
                        </small>
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            checked={formData.removing_whitespace_account_2}
                            onChange={(v) => setFormData((s) => ({...s, removing_whitespace_account_2: v}))}
                        >
                            {trg("remove_white_space_2nd_account")}
                        </Checkbox>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 123 456{" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 123456
                        </small>
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            checked={formData.zero_padding_account_2}
                            onChange={(v) => setFormData((s) => ({...s, zero_padding_account_2: v}))}
                        >
                            {trg("zero_padding_2nd_account")}
                        </Checkbox>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 23456{" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 023456
                        </small>
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            checked={formData.combination_account_1_and_account_2}
                            onChange={(v) => setFormData((s) => ({...s, combination_account_1_and_account_2: v}))}
                        >
                            {trg("use_combination_of_1st_2nd_account")}
                        </Checkbox>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 123 (1st) 456789 (2nd){" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 123456789
                        </small>
                        <small className="d-block ap-opacity-6 ms-5">
                            {trg("example_short")}: 123456 (1st) 123456 (2nd){" "}
                            <span className="Appkit4-icon icon-arrow-right-small-outline"></span> 123456
                        </small>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ImportSettings;
