import React from "react";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Button, Input, Modal} from "@appkit4/react-components";
import {DeferredTaxEntry} from "../../../types/DeferredTaxEntry";
import {Calculation} from "../../../types/Calculation";
import {Entity} from "../../../types/Entity";
import i18n from "../../../utils/i18n";
import {convertLocaleNumberToFloat, numberToLocaleString} from "../../../utils/numberToLocaleString";

declare type PropTypes = {
    entry?: DeferredTaxEntry;
    calculation: Calculation;
    entity: Entity;
    onSaveHandler?: () => void;
};

const DeferredTaxSettings: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const {addErrorNotification, reloadCurrentCalculation} = useGlobalStore((s) => ({
        addErrorNotification: s.addErrorNotification,
        reloadCurrentCalculation: s.reloadCurrentCalculation,
    }));

    const [visible, setVisible] = React.useState(false);

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [form, setForm] = React.useState({
        deferred_tax_rate_opening: numberToLocaleString(props.calculation.deferred_tax_rate_opening, i18n.language),
        deferred_tax_rate_closing: numberToLocaleString(props.calculation.deferred_tax_rate_closing, i18n.language),
    });

    React.useEffect(() => {
        setForm((s) => ({
            ...s,
            deferred_tax_rate_opening: numberToLocaleString(
                props.calculation.deferred_tax_rate_opening,
                i18n.language,
                2
            ),
            deferred_tax_rate_closing: numberToLocaleString(
                props.calculation.deferred_tax_rate_closing,
                i18n.language,
                3
            ),
        }));
    }, [props.entity, props.calculation]);

    const onUpdateField = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let val = event.target.value;
        setForm((s) => {
            let new_s = {...s};
            new_s[event.target.name] =
                event.target.name.search("tax_rate") >= 0 ? numberToLocaleString(val, i18n.language, 2) : val;
            return new_s;
        });
    };

    const onSubmit = () => {
        setIsSubmitted(true);
        fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/`, "patch", {
            deferred_tax_rate_opening: convertLocaleNumberToFloat(form.deferred_tax_rate_opening, i18n.language),
            deferred_tax_rate_closing: convertLocaleNumberToFloat(form.deferred_tax_rate_closing, i18n.language),
        })
            .then((r) => r.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    if (props.onSaveHandler) props.onSaveHandler();
                    setIsSubmitted(false);
                    setTimeout(() => {
                        reloadCurrentCalculation();
                        setTimeout(() => {
                            setVisible(false);
                        }, 1000);
                    }, 1000);
                }
            })
            .catch((data) => {
                addErrorNotification(data);
            });
    };

    return (
        <>
            <Button
                kind="tertiary"
                onClick={() => {
                    setVisible(true);
                }}
                icon="icon-setting-outline"
            >
                {trg("settings")}
            </Button>
            <Modal
                visible={visible}
                closable={true}
                maskCloseable={false}
                title={trg("deferred_tax_settings")}
                ariaLabel={trg("deferred_tax_settings")}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "30.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                icons={""}
                footer={
                    <Button onClick={onSubmit} loading={isSubmitted}>
                        {trg("save")}
                    </Button>
                }
                //bodyStyle={{minHeight: "52px"}}
            >
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            name="deferred_tax_rate_opening"
                            title={trg("deferred_tax_rate_opening")}
                            errorNode={
                                <div aria-live="polite" className={"ap-field-validation-error"}>
                                    {trg("please_enter_a_deferred_tax_rate")}
                                </div>
                            }
                            required
                            error={isSubmitted && form.deferred_tax_rate_opening + "" === ""}
                            value={form.deferred_tax_rate_opening}
                            onChange={(v: string) => setForm((s) => ({...s, deferred_tax_rate_opening: v}))}
                            onBlur={onUpdateField}
                        ></Input>
                    </div>

                    <div className="col-md-6">
                        <Input
                            name="deferred_tax_rate_closing"
                            title={trg("deferred_tax_rate_closing")}
                            errorNode={
                                <div aria-live="polite" className={"ap-field-validation-error"}>
                                    {trg("please_enter_a_deferred_tax_rate_closing")}
                                </div>
                            }
                            required
                            error={isSubmitted && form.deferred_tax_rate_closing + "" === ""}
                            value={form.deferred_tax_rate_closing}
                            onChange={(v: string) => setForm((s) => ({...s, deferred_tax_rate_closing: v}))}
                            onBlur={onUpdateField}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeferredTaxSettings;
