import React from "react";
import {useParams} from "react-router-dom";
import {CalculationHashParamName, EntityHashParamName} from "../Constants";
import {useGlobalStore} from "../stores/GlobalStore";
//import {useClientStore} from "../stores/ClientStore";
import {GenericParams} from "../types/Params";
import * as O from "fp-ts/Option";
import LoadingContainer, {CircularWidth} from "./LoadingContainer";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import {containsEntity} from "../utils/userUtil";
import PageAccessDenied from "../components/PageAccessDenied/PageAccessDenied";

interface PropTypes {
    children: React.ReactNode;
}
const CanShowAdminMiddleware: React.FC<PropTypes> = (props: PropTypes) => {
    const userInfo = useGlobalStore((s) => s.userInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    // 0 => loading
    // 1 => can continue
    // 2 => cannot continue
    const [canContinue, setCanContinue] = React.useState<number>(0);

    // React.useEffect(() => {
    //     setCanContinue(
    //         !uriParams[EntityHashParamName] || !uriParams[CalculationHashParamName]
    //             ? 2
    //             : uriParams[EntityHashParamName] &&
    //               uriParams[CalculationHashParamName] &&
    //               O.isSome(findCalculationInUserObject(userInfo, uriParams[CalculationHashParamName]))
    //             ? 1
    //             : 2
    //     );
    // }, [uriParams, userInfo]);

    React.useEffect(() => {
        setCanContinue(
            O.isSome(userInfo) && userInfo.value.backend_info ? (userInfo.value.backend_info.admin ? 1 : 2) : 0
        );
    }, [userInfo]);

    return canContinue === 0 ? (
        <LoadingContainer circularWidthOverride={CircularWidth.big} />
    ) : canContinue === 1 ? (
        <>{props.children}</>
    ) : (
        <PageAccessDenied />
    );
};

export default CanShowAdminMiddleware;
