import React from "react";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useNavigate} from "react-router-dom";
import PagePanel from "../../misc/PagePanel";
import Centered from "../../misc/Centered";
import {Loading} from "@appkit4/react-components";

const Summary: React.FC = () => {
    const trh = useTr("header");
    const navigate = useNavigate();

    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);

    const [dataAreLoaded] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) /*&& calculation.value.status < 6*/) {
            navigate(`/calculation/${entity.value.hash}/${calculation.value.hash}/questionnaire`);
        }
    }, [calculation, navigate, entity]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <div className="content-padding">
                    <PagePanel title={trh("SummaryLabel")}>
                        {dataAreLoaded ? (
                            <div className="m-5 p-5"></div>
                        ) : (
                            <Centered>
                                <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                            </Centered>
                        )}
                    </PagePanel>
                </div>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default Summary;
