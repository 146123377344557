import React, {useState} from "react";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import LoadingContainer, {CircularWidth} from "../../misc/LoadingContainer";
import {LeftbarItemType} from "../Layout/LayoutLeftbar";
import TaxFlowsAdjustmentsByType from "./TaxFlowsAdjustmentsByType";
import {adjustment_types} from "../../Constants";
import {useTr} from "../../utils/trUtil";
import {Tabs, Tab} from "@appkit4/react-components/tabs";

const TaxFlows: React.FC = () => {
    const tr = useTr("tax_flows");
    const navigate = useNavigate();
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const [content, setContent] = React.useState(<LoadingContainer circularWidthOverride={CircularWidth.big} />);
    const [leftbarItems, setLeftbarItems] = React.useState<LeftbarItemType[]>([]);
    const [baseUrl, setBaseUrl] = React.useState<string>("");
    const [currentStep, setCurrentStep] = useState(0);

    const handleIndexChange = React.useCallback(
        (index: number) => {
            setCurrentStep(index);
            navigate(leftbarItems[index].link);
        },
        [leftbarItems, navigate]
    );

    const menu = React.useMemo(
        () => (
            <Tabs type="underline" activeIndex={currentStep} onTabChange={handleIndexChange}>
                <Tab label={tr("accounting_result")} value={tr("accounting_result")}></Tab>
                <Tab label={tr("journal_adjustments")} value={tr("journal_adjustments")}></Tab>
                <Tab label={tr("manual_adjustments")} value={tr("manual_adjustments")}></Tab>
                <Tab label={tr("taxable_result_adjustments")} value={tr("taxable_result_adjustments")}></Tab>
                <Tab label={tr("fixed_assets")} value={tr("fixed_assets")}></Tab>
                <Tab label={tr("provisions")} value={tr("provisions")}></Tab>
                <Tab label={tr("valuation_allowances")} value={tr("valuation_allowances")}></Tab>
                <Tab label={tr("cash_basis")} value={tr("cash_basis")}></Tab>
                <Tab label={tr("helpful")} value={tr("helpful")}></Tab>
            </Tabs>
        ),
        [currentStep, handleIndexChange, tr]
    );
    React.useEffect(() => {
        setLeftbarItems(
            O.isNone(calculation) || O.isNone(entity)
                ? []
                : [
                      {
                          key: adjustment_types.ACCOUNTING_RESULT,
                          text: tr("accounting_result"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.ACCOUNTING_RESULT}`,
                          isComplete: calculation.value.status > 1,
                          required: true,
                      },
                      {
                          key: adjustment_types.JOURNAL,
                          text: tr("journal_adjustments"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.JOURNAL}`,
                          isComplete: calculation.value.status > 1,
                          required: true,
                      },
                      {
                          key: adjustment_types.MANUAL,
                          text: tr("manual_adjustments"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.MANUAL}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.TAXABLE_RESULT,
                          text: tr("taxable_result_adjustments"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.TAXABLE_RESULT}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.FIXED_ASSETS,
                          text: tr("fixed_assets"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.FIXED_ASSETS}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.PROVISIONS,
                          text: tr("provisions"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.PROVISIONS}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.VALUATION_ALLOWANCES,
                          text: tr("valuation_allowances"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.VALUATION_ALLOWANCES}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.VALUATION_ALLOWANCES,
                          text: tr("cash_basis"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.CASH_BASIS}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                      {
                          key: adjustment_types.HELPFUL,
                          text: tr("helpful"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment_types.HELPFUL}`,
                          isComplete: calculation.value.status > 2,
                          required: true,
                      },
                  ]
        );
        setBaseUrl(
            O.isNone(calculation) || O.isNone(entity)
                ? ""
                : `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows`
        );
    }, [calculation, entity, tr]);

    // Set relevant content by current calculation and entity
    React.useEffect(() => {
        if (!O.isNone(calculation) && !O.isNone(entity)) {
            if (window.location.href.endsWith(`tax-flows/${adjustment_types.ACCOUNTING_RESULT}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.ACCOUNTING_RESULT} menu={menu} />);
                setCurrentStep(0);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.JOURNAL}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.JOURNAL} menu={menu} />);
                setCurrentStep(1);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.MANUAL}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.MANUAL} menu={menu} />);
                setCurrentStep(2);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.TAXABLE_RESULT}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.TAXABLE_RESULT} menu={menu} />);
                setCurrentStep(3);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.FIXED_ASSETS}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.FIXED_ASSETS} menu={menu} />);
                setCurrentStep(4);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.PROVISIONS}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.PROVISIONS} menu={menu} />);
                setCurrentStep(5);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.VALUATION_ALLOWANCES}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.VALUATION_ALLOWANCES} menu={menu} />);
                setCurrentStep(6);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.CASH_BASIS}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.CASH_BASIS} menu={menu} />);
                setCurrentStep(7);
            } else if (window.location.href.endsWith(`tax-flows/${adjustment_types.HELPFUL}`)) {
                setContent(<TaxFlowsAdjustmentsByType type={adjustment_types.HELPFUL} menu={menu} />);
                setCurrentStep(8);
            } else if (baseUrl !== "") {
                navigate(`${baseUrl}/${adjustment_types.ACCOUNTING_RESULT}`);
            }
        }
    }, [calculation, entity, baseUrl, navigate, menu]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                {/* <LayoutLeftbar items={leftbarItems} title="Tax Flows"> */}

                {content}
                {/* </LayoutLeftbar> */}
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default TaxFlows;
