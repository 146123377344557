import React from "react";
import {FormRow} from "../../types/FormRow";
import {RecursiveFormRowsComponent} from "./CalculationForm";
import HtmlH2 from "./FormElement/HtmlH2";
import HtmlPanel from "./FormElement/HtmlPanel";
import InputRadioGroup from "./FormElement/InputRadioGroup";
import InputDefault from "./FormElement/InputDefault";
import InputDate from "./FormElement/InputDate";
import InputSelect from "./FormElement/InputSelect";
import InputCheckbox from "./FormElement/InputCheckbox";
import HtmlTableBox from "./FormElement/HtmlTableBox";
import InputTextarea from "./FormElement/InputTextarea";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";

declare type PropTypes = {
    element: FormRow;
    parentPath: string;
};

//** Parameter `is_child` in onChangeHandler was adding for radio buttons in radio group */
export declare type InputPropTypes = {
    element: FormRow;
    parentPath: string;
    currentPath: string;
    children: React.ReactElement;
    onChangeHandler: (el: FormRow, val: any, is_child?: boolean) => void;
};

const CalculationFormElement: React.FC<PropTypes> = (properties) => {
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const {element, parentPath} = properties;
    const path = parentPath === "" ? element.xml_node_name : parentPath + "." + element.xml_node_name;
    const [formDataId, setFormDataId] = React.useState(element.form_data_id);

    const onChangeHandler = (el: FormRow, val: any, is_child?: boolean) => {
        if (O.isSome(entity) && O.isSome(calculation) && el.value !== val) {
            let method = "POST";
            let _id = "";
            if ((is_child && el.form_data_id) || formDataId) {
                method = "PATCH";
                _id = is_child && el.form_data_id ? el.form_data_id + "" : formDataId + "";
            }

            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/formdata/${_id ? _id + "/" : ""}`,
                method,
                {
                    form_id: el.form,
                    form_row_id: el.id,
                    calculation: calculation.value.id,
                    value: val,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setFormDataId(data.id);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    };

    const props = {...properties, onChangeHandler: onChangeHandler};

    if (element.print === false) {
        return <></>;
    }

    const children = element.children ? (
        <RecursiveFormRowsComponent rows={element.children} parentPath={path} />
    ) : (
        <></>
    );
    var content = <></>;
    if (element.input_type === "radiogroup") {
        content = (
            <InputRadioGroup {...props} currentPath={path}>
                {children}
            </InputRadioGroup>
        );
    } else if (element.input_type === "date") {
        return (
            <InputDate {...props} currentPath={path}>
                {children}
            </InputDate>
        );
    } else if (element.input_type === "select") {
        return (
            <InputSelect {...props} currentPath={path}>
                {children}
            </InputSelect>
        );
    } else if (element.input_type === "checkbox") {
        return (
            <InputCheckbox {...props} currentPath={path}>
                {children}
            </InputCheckbox>
        );
    } else if (element.input_type === "textarea") {
        return (
            <InputTextarea {...props} currentPath={path}>
                {children}
            </InputTextarea>
        );
    } else {
        content = children;
    }

    if (element.html_type === "h2") return <HtmlH2 {...props}>{content}</HtmlH2>;
    if (element.html_type === "panel") return <HtmlPanel {...props}>{content}</HtmlPanel>;
    if (element.html_type === "tablebox") return <HtmlTableBox {...props} />;

    return (
        <InputDefault {...props} currentPath={path}>
            {content}
        </InputDefault>
    );
};

export default CalculationFormElement;
