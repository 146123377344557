import React from "react";
import {Modal} from "@appkit4/react-components/modal";
import {Button} from "@appkit4/react-components/button";
import {Entity} from "../../types/Entity";
import {Calculation} from "../../types/Calculation";
import {useTr} from "../../utils/trUtil";
import {Loading} from "@appkit4/react-components/loading";
import {useGlobalStore} from "../../stores/GlobalStore";
import MembersList from "../../misc/MembersList";
import UserClaimAddingForm from "../../misc/UserClaimAddingForm";
import {Member} from "../../types/UserInfo";
import {ClaimTypes} from "../../Constants";

declare type PropTypes = {entity: Entity; calculation: Calculation};

const CalculationQuestionnaireAndAttachmentsMembers: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");

    const [visible, setVisible] = React.useState(false);
    const [showAddMemberForm, setShowAddMemberForm] = React.useState(false);

    const [members, setMembers] = React.useState([]);
    const [membersAreLoading, setMembersAreLoading] = React.useState(true);
    const [reloadMembers, setReloadMembers] = React.useState(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    React.useEffect(() => {
        if (reloadMembers) {
            setMembersAreLoading(true);
            fetchApi(
                `/entity/${props.entity.hash}/claims/?type=${ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS}&calculation_hash=${props.calculation.hash}`
            )
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setMembers(res);
                        setMembersAreLoading(false);
                        setReloadMembers(false);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setMembersAreLoading(false);
                    setReloadMembers(false);
                });
        }
    }, [reloadMembers]);

    const deleteMember = (item: Member) => {
        setMembersAreLoading(true);

        fetchApi(
            `/entity/${props.entity.hash}/claims/?type=${ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS}&calculation_hash=${props.calculation.hash}`,
            "DELETE",
            {email: item.user.email}
        )
            .then((resp) => resp.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    setReloadMembers(true);
                }
            })
            .catch((err) => {
                addErrorNotification(err);
                setMembersAreLoading(false);
            });
    };

    React.useEffect(() => {
        if (visible) setReloadMembers(true);
    }, [visible]);

    const getData = React.useCallback(() => {
        return members.map((member: Member, index: number) => {
            return {
                ...member,
                id: index,
            };
        });
    }, [members]);
    return (
        <>
            <Button
                onClick={() => {
                    setVisible(true);
                }}
                kind="tertiary"
                className="d-inline"
            >
                {trg("users")}
            </Button>
            <Modal
                visible={visible}
                title={trg("list_of_users_who_can_edit_questionaire_and_attachments")}
                ariaLabel={"Lorem ipsum dolor sit"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "53.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                icons={""}
                footer={""}
                bodyStyle={{minHeight: "92px"}}
            >
                <div>
                    <div className="row my-1">
                        <div className="col">
                            {members.length > 0 ? (
                                <MembersList
                                    members={getData()}
                                    getDescription={(item: Member) =>
                                        item.claim_type == ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS ? "" : "admin"
                                    }
                                    getAction={(item: Member) =>
                                        item.claim_type == ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS && (
                                            <div className="mx-2">
                                                <span
                                                    className="Appkit4-icon icon-delete-outline"
                                                    onClick={() => deleteMember(item)}
                                                ></span>
                                            </div>
                                        )
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {(reloadMembers || membersAreLoading) && (
                        <div className="my-1 text-center">
                            <Loading loadingType="circular" indeterminate={true} compact={true}></Loading>
                        </div>
                    )}
                    <div className="d-flex">
                        <div className="flex-grow-1 px-3">
                            {showAddMemberForm && (
                                <UserClaimAddingForm
                                    claimType={ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS}
                                    entity={props.entity}
                                    calculation={props.calculation}
                                    beforeFetch={() => {
                                        setShowAddMemberForm(false);
                                        setMembersAreLoading(true);
                                    }}
                                    afterFetch={() => {
                                        setReloadMembers(true);
                                    }}
                                />
                            )}
                        </div>

                        <div className="mx-3 p-2">
                            <Button
                                kind="secondary"
                                compact
                                icon="icon-plus-outline"
                                onClick={() => setShowAddMemberForm(!showAddMemberForm)}
                            >
                                {trg("add_member")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CalculationQuestionnaireAndAttachmentsMembers;
