import React from "react";
import Centered from "../../misc/Centered";
import {Loading} from "@appkit4/react-components/loading";
import {Table, Column} from "@appkit4/react-components/table";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {useTr} from "../../utils/trUtil";
import {useNavigate} from "react-router-dom";
import {DataCheck} from "../../types/DataCheck";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Checkbox, Button} from "@appkit4/react-components";
import "./ImportDataCheck.scss";
import {Number} from "../../misc/AdjustmentColumns";
import {zeroToString} from "../../utils/zeroToString";
import {numberToLocaleString} from "../../utils/numberToLocaleString";
import i18n from "../../utils/i18n";
import PagePanel from "../../misc/PagePanel";

declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    menu: React.ReactNode;
};

const ImportDataCheck: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");

    const navigate = useNavigate();
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [datacheckIsLoaded, setDatacheckIsLoaded] = React.useState<boolean>(false);
    const [showZeroFillButton, setShowZeroFillButton] = React.useState<boolean>(false);
    const [datacheck, setDatacheck] = React.useState<DataCheck[]>([]);
    const [sumarization, setSumarization] = React.useState<any>();
    const [filteredData, setFilteredData] = React.useState<DataCheck[]>([]);

    const [hideCompletenessWithZero, setHideCompletenessWithZero] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (datacheckIsLoaded === false)
            fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/data_check/`)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setDatacheck(data.data);
                        setSumarization(data.sumarization);
                        setDatacheckIsLoaded(true);
                    }
                });
    }, [props.entity, props.calculation, datacheckIsLoaded, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        if (datacheckIsLoaded === true)
            setFilteredData(
                datacheck && Object.keys(datacheck).length !== 0
                    ? zeroToString(
                          hideCompletenessWithZero
                              ? datacheck.filter((d) => parseFloat(d.completeness) !== 0.0)
                              : datacheck
                      ).sort((a: DataCheck, b: DataCheck) => (a.account_number < b.account_number ? -1 : 1))
                    : []
            );
    }, [datacheck, hideCompletenessWithZero, datacheckIsLoaded]);

    const confirmDataCheck = () => {
        fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/datacheck_completed/`, "post")
            .then((response) => response.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    updateCurrentCalculation(data);
                    navigate(`/calculation/${props.entity.hash}/${props.calculation.hash}/trial-balance`);
                }
            });
    };

    const zeroFillAccountNumbers = () => {
        fetchApi(`/entity/${props.entity.hash}/do_zero_padding_for_account_numbers/`)
            .then((response) => response.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    setDatacheckIsLoaded(false);
                }
            });
    };

    React.useEffect(() => {
        if (datacheck)
            setShowZeroFillButton(() => {
                var show = false;
                var length = 0;
                datacheck.forEach((d) => {
                    if (length === 0) length = d.account_number.length;
                    else if (d.account_number.length !== length) show = true;
                });
                return show;
            });
    }, [datacheck]);

    return (
        <>
            <PagePanel title="Data check">
                {props.menu}
                {datacheckIsLoaded ? (
                    <>
                        <div className="container border rounded p-3">
                            <div className="row align-items-start mb-1">
                                <div className="col">
                                    <strong>{trg("Unadjusted Trial Balance")}</strong>
                                </div>
                                <div className="col text-end">
                                    <strong>{trg("Total")}</strong>
                                </div>
                                <div className="col"></div>
                                <div className="col">
                                    <strong>{trg("Other")}</strong>
                                </div>
                                <div className="col text-end">
                                    <strong>{trg("Total")}</strong>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Opening Balance")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.opening_balance, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Net Movement: Trial Balance")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_net_movement, i18n.language)}
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Debit")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_debit, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Net Movement: Journal")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_je_movement, i18n.language)}
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Credit")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_credit, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Completeness")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.completeness, i18n.language)}
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Net Movement ABS")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_tb_movement_abs, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Completeness ABS")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_completeness_abs, i18n.language)}
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Closing Balance")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.closing_balance, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Trial Balance Adjustments")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_tb_adjustments, i18n.language)}
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">{trg("Net Movement")}</div>
                                <div className="col text-end">
                                    {numberToLocaleString(sumarization.sum_tb_movement, i18n.language)}
                                </div>
                                <div className="col"></div>
                                <div className="col">{trg("Final Trial Balance Closing")}</div>
                                <div className="col text-end">{numberToLocaleString(0, i18n.language)}</div>
                            </div>
                        </div>
                        <div className="d-flex w-100 text-end">
                            <div className="mt-3">
                                <Checkbox onChange={(v) => setHideCompletenessWithZero(v)}>
                                    {trg("hide_completeness_with_zero")}
                                </Checkbox>
                            </div>
                            <div className="m-2 me-auto"></div>
                            {showZeroFillButton && (
                                <Button
                                    kind="text"
                                    //icon="icon-right-chevron-fill"
                                    onClick={zeroFillAccountNumbers}
                                    compact
                                    className="mt-2"
                                    style={{paddingRight: "5px"}}
                                >
                                    {trg("fill_account_numbers_with_zeros")} &nbsp;&nbsp;
                                </Button>
                            )}
                            <Button
                                kind="primary"
                                //icon="icon-right-chevron-fill"
                                onClick={confirmDataCheck}
                                compact
                                className="mt-2"
                                style={{paddingRight: "5px"}}
                            >
                                {trg("continue")} &nbsp;&nbsp;
                                <span className="Appkit4-icon icon-right-chevron-fill p-0"></span>
                            </Button>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table-data-check mt-3"
                                originalData={filteredData}
                                hasTitle={true}
                                striped={true}
                                condensed={true}
                                //sortActive="account_number"
                                //sortPhase={1}
                                //skipInitialSort={true}
                                animatedSorting={false}
                            >
                                <Column
                                    sortKey="account_number"
                                    renderCell={(row, field) => {
                                        return (
                                            <div className="d-flex">
                                                <div>{row.account_number}</div>
                                                <div className="mx-2">
                                                    <i>{row.account_name}</i>
                                                </div>
                                            </div>
                                        );
                                    }}
                                >
                                    {trg("Account number")}
                                </Column>
                                <Column
                                    sortKey="opening_balance"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.opening_balance} />
                                    )}
                                >
                                    {trg("Opening balance")}
                                </Column>
                                <Column
                                    sortKey="net_movement"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.net_movement} />
                                    )}
                                >
                                    {trg("TB Movement")}
                                </Column>
                                <Column
                                    sortKey="closing_balance"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.closing_balance} />
                                    )}
                                >
                                    {trg("Closing balance")}
                                </Column>
                                <Column
                                    sortKey="je_movement"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.je_movement} />
                                    )}
                                >
                                    {trg("JE Movement")}
                                </Column>
                                <Column
                                    sortKey="completeness"
                                    field="completeness"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.completeness} redIfNotZero={true} />
                                    )}
                                >
                                    {trg("Completeness")}
                                </Column>
                                <Column
                                    field="tb_adjustments"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.tb_adjustments} />
                                    )}
                                >
                                    {trg("TB Adjustments")}
                                </Column>
                                <Column
                                    field="adjusted_closing_balance"
                                    renderCell={(row, field) => (
                                        <Number row={row} field={field} amount={row.adjusted_closing_balance} />
                                    )}
                                >
                                    {trg("Adjusted Closing balance")}
                                </Column>
                            </Table>
                        </div>

                        <div className="d-flex w-100 text-end">
                            <div className="m-2 me-auto"></div>
                            <Button
                                kind="primary"
                                //icon="icon-right-chevron-fill"
                                onClick={confirmDataCheck}
                                compact
                                className="mt-2"
                                style={{paddingRight: "5px"}}
                            >
                                {trg("continue")} &nbsp;&nbsp;
                                <span className="Appkit4-icon icon-right-chevron-fill p-0"></span>
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="mt-5">
                        <Centered>
                            <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                        </Centered>
                    </div>
                )}
            </PagePanel>
        </>
    );
};

export default ImportDataCheck;
