import React from "react";
import {useParams} from "react-router-dom";
import {CalculationHashParamName, EntityHashParamName} from "../Constants";
import {useGlobalStore} from "../stores/GlobalStore";
//import {useClientStore} from "../stores/ClientStore";
import {GenericParams} from "../types/Params";
import * as O from "fp-ts/Option";
import LoadingContainer, {CircularWidth} from "./LoadingContainer";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import {containsEntity} from "../utils/userUtil";

interface PropTypes {
    children: React.ReactNode;
}
const CanShowCalculationMiddleware: React.FC<PropTypes> = (props: PropTypes) => {
    const uriParams = useParams<GenericParams>();
    const userInfo = useGlobalStore((s) => s.userInfo);
    const currentCalculation = useGlobalStore((s) => s.currentCalculation);
    const addCalculation = useGlobalStore((s) => s.addCalculation);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadAttachments = useGlobalStore((s) => s.loadAttachments);
    const loadReviewers = useGlobalStore((s) => s.loadReviewers);
    const loadAdjustmentCategories = useGlobalStore((s) => s.loadAdjustmentCategories);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    // 0 => loading
    // 1 => can continue
    // 2 => cannot continue
    const [canContinue, setCanContinue] = React.useState<number>(0);

    // React.useEffect(() => {
    //     setCanContinue(
    //         !uriParams[EntityHashParamName] || !uriParams[CalculationHashParamName]
    //             ? 2
    //             : uriParams[EntityHashParamName] &&
    //               uriParams[CalculationHashParamName] &&
    //               O.isSome(findCalculationInUserObject(userInfo, uriParams[CalculationHashParamName]))
    //             ? 1
    //             : 2
    //     );
    // }, [uriParams, userInfo]);

    React.useEffect(() => {
        if (!uriParams[EntityHashParamName] || !uriParams[CalculationHashParamName]) {
            console.warn("Not exists entityHash and calculationHash in params");
            setCanContinue(2);
        } else if (typeof uriParams[CalculationHashParamName] === undefined) {
            console.warn("Param calculation hash not found");
            setCanContinue(2);
        } else if (!containsEntity(userInfo, uriParams[EntityHashParamName])) {
            console.warn("Entity not found");
            setCanContinue(2);
        } else if (
            O.isSome(currentCalculation) &&
            currentCalculation.value.hash === uriParams[CalculationHashParamName]
        ) {
            /** Calculation was found */
            setCanContinue(1);
            // } else if (O.isSome(findCalculationInUserInfo(userInfo, uriParams[CalculationHashParamName]))) {
            //     let c = findCalculationInUserInfo(userInfo, uriParams[CalculationHashParamName]);
            //     console.warn("Calculation was found");
            //     if (O.isSome(c)) {
            //         updateCurrentCalculation(c.value);
            //     }
            //     setCanContinue(1);
        } else if (true) {
            /** Calculation was not found and will be fetch via API */
            fetchApi(`/entity/${uriParams[EntityHashParamName]}/calculation/${uriParams[CalculationHashParamName]}/`)
                .then((response) => {
                    return response.json();
                })
                .then(
                    (data) => {
                        if (typeof data.error !== "undefined") {
                            addErrorNotification(data);
                            setCanContinue(2);
                        } else if (uriParams[EntityHashParamName]) {
                            addCalculation(data, uriParams[EntityHashParamName]);
                            //loadAdjustments();
                            loadReviewers();
                            loadAttachments();
                            loadAdjustmentCategories();
                            setCanContinue(1);
                        } else {
                            setCanContinue(2);
                        }
                    },
                    (err) => {
                        addErrorNotification(err);
                        setCanContinue(2);
                    }
                );
            //setCanContinue(0);
        }
    }, [
        uriParams,
        userInfo,
        addCalculation,
        currentCalculation,
        addErrorNotification,
        fetchApi,
        loadAdjustmentCategories,
        loadAttachments,
        loadReviewers,
    ]);

    return canContinue === 0 ? (
        <LoadingContainer circularWidthOverride={CircularWidth.big} />
    ) : canContinue === 1 ? (
        <>{props.children}</>
    ) : (
        <PageNotFound />
    );
};

export default CanShowCalculationMiddleware;
