import React from "react";
import {Adjustment} from "../../../types/Adjustment";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Table, Column, Button, Modal, Input} from "@appkit4/react-components";
import {Filter} from "../../../types/Filter";
import {useNavigate} from "react-router-dom";

declare type PropTypes = {
    adjustment: Adjustment;
};

export const FilterList: React.FC<PropTypes> = (props: PropTypes) => {
    const [adjustment, setAdjustment] = React.useState<Adjustment>(props.adjustment);

    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const navigate = useNavigate();

    const [filtersList, setFiltersList] = React.useState<Filter[]>([]);
    const [newFilterName, setNewFilterName] = React.useState<string>("");
    const [visible, setVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        setAdjustment(props.adjustment);
    }, [props]);

    React.useEffect(() => {
        if (adjustment) {
            setFiltersList(
                adjustment.filters.map((af) => {
                    return {...af.filter};
                })
            );
        }
    }, [adjustment]);

    const showFilter = (filter: Filter) => {
        if (O.isSome(entity) && O.isSome(calculation) && filter)
            navigate(
                `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/adjustment/${adjustment.id}/filter/${filter.id}`
            );
    };

    const createNewFilter = () => {
        if (O.isSome(entity) && O.isSome(calculation))
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter/`, "post", {
                name: newFilterName,
                adjustment_id: adjustment.id,
                criteria: [],
            })
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        navigate(
                            `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/adjustment/${adjustment.id}/filter/${data.id}`
                        );
                    }
                });
    };

    return (
        <div>
            <div className="d-flex">
                <div className="flex-grow-1"></div>
                <div>
                    <Button
                        kind="secondary"
                        compact
                        icon="icon-plus-outline"
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        {trg("add")}
                    </Button>
                    <Modal
                        visible={visible}
                        title={trg("create_new_filter")}
                        ariaLabel={trg("create_new_filter")}
                        onCancel={() => setVisible(false)}
                        modalStyle={{width: "33.75rem"}}
                        footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                        header={""}
                        icons={""}
                        maskCloseable={false}
                        footer={
                            <>
                                <Button onClick={() => setVisible(false)} kind="secondary">
                                    {trg("cancel")}
                                </Button>
                                <Button onClick={createNewFilter}>{trg("create")}</Button>
                            </>
                        }
                        bodyStyle={{minHeight: "92px"}}
                    >
                        <div>
                            <Input
                                type="text"
                                title={trg("filter_name")}
                                defaultValue={newFilterName}
                                //value={newFilterName}
                                required={true}
                                onChange={(v: string) => setNewFilterName(v)}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
            {filtersList.length > 0 ? (
                <Table originalData={filtersList} hasTitle={true} striped={true} condensed={true} className="mt-1">
                    <Column sortKey="name">{trg("name")}</Column>
                    <Column
                        field="id"
                        renderCell={(row, field) => {
                            return (
                                <div className="d-flex flex-row-reverse" key={row.id}>
                                    <Button className="mx-1" kind="text" compact onClick={() => showFilter(row)}>
                                        <span className="Appkit4-icon icon-pencil-outline"></span>
                                    </Button>
                                </div>
                            );
                        }}
                    >
                        {trg("action")}
                    </Column>
                </Table>
            ) : (
                <div className="border rounded-bottom p-3">{trg("list_of_filters_is_empty")}</div>
            )}
        </div>
    );
};
