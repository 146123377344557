import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Ruleset} from "../../../types/Ruleset";
import {Table, Column} from "@appkit4/react-components/table";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {useFixtureStore} from "../../../stores/FixtureStore";
import RulesetFormForm from "./Forms/RulesetFormForm";
import {useTr} from "../../../utils/trUtil";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: Ruleset[];
}

const AdminFixtureRulesetForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, setData] = React.useState<Ruleset[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);
    const trg = useTr("global");

    const {fixtureState, init, rulesets, forms} = useFixtureStore((s) => ({
        fixtureState: s,
        init: s.init,
        rulesets: s.rulesets,
        forms: s.forms,
    }));

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        init();
    }, [init]);

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false || dataAreLoading === true) {
            fetchApi(`/settings/_/fixtures/rulesetform/?page=${current}`)
                .then((r) => r.json())
                .then((res: PaginationType) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res.results);
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current]);

    const renderActionCell = (row) => {
        return (
            <>
                <RulesetFormForm
                    key={`edit-${row.id}`}
                    rulesetForm={row}
                    onSaveHandler={() => setDataAreLoaded(false)}
                />
            </>
        );
    };

    const pagination = (
        <Pagination
            current={current}
            total={Math.ceil(totalCount / countPerPage)}
            onPageChange={onPageChange}
        ></Pagination>
    );

    return (
        <CanShowAdminMiddleware>
            {fixtureState.rulesetsAreLoaded && fixtureState.formsAreLoaded && dataAreLoaded && (
                <div className="container-fluid">
                    {pagination}
                    <Table originalData={data} hasTitle striped condensed>
                        <Column field="id" sortKey="id" slot="end">
                            {trg("id")}
                        </Column>
                        <Column
                            field="ruleset"
                            renderCell={(row: any) => rulesets.find((r) => r.id === row.ruleset)?.name}
                        >
                            {trg("ruleset")}
                        </Column>
                        <Column field="form" renderCell={(row: any) => forms.find((c) => c.id === row.form)?.name}>
                            {trg("form")}
                        </Column>
                        <Column field="id" title={"Action"} renderCell={renderActionCell}>
                            {trg("action")}
                        </Column>
                    </Table>
                    {pagination}
                </div>
            )}
            <RulesetFormForm onSaveHandler={() => setDataAreLoaded(false)} />
        </CanShowAdminMiddleware>
    );
};

export default AdminFixtureRulesetForm;
