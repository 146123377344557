import React from "react";
import {Notification, toaster} from "@appkit4/react-components/notification";
import {useGlobalStore} from "../stores/GlobalStore";

interface PropTypes {}
const NotificationToaster: React.FC<PropTypes> = (props: PropTypes) => {
    const notifications = useGlobalStore((s) => s.notifications);
    const removeAllNotifications = useGlobalStore((s) => s.removeAllNotifications);

    const handleClose = () => {
        //console.log("Closed!");
    };

    React.useEffect(() => {
        if (notifications && notifications.length > 0) {
            notifications.forEach((n, index) => {
                setTimeout(() => {
                    toaster.notify(
                        <Notification key={index} id={index + ""} title="" message={n.message} status={n.status} />,
                        {
                            position: "bottomRight",
                            onClose: handleClose,
                            duration: 10000,
                        }
                    );
                }, (index + 1) * 400);
            });
            removeAllNotifications();
        }
    }, [notifications, removeAllNotifications]);

    return (
        <>
            <div></div>
        </>
    );
};
export default NotificationToaster;
