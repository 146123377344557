import React from "react";
import {useTranslation} from "react-i18next";

export const useTr = (ns?: string) => {
    const {t} = useTranslation();
    return React.useCallback(
        (labelName: string) => ((labelName: string) => t(labelName, {ns: ns}))(labelName),
        [ns, t]
    );
};
