import {create} from "zustand";
import {Ruleset} from "../types/Ruleset";
import {useGlobalStore} from "./GlobalStore";
import {Form} from "../types/Form";
import {RulesetForm} from "../types/RulesetForm";
import {FileTemplate} from "../types/FileTemplate";
import {Tag} from "../types/Tag";
import {AdjustmentDefault} from "../types/AdjustmentDefault";

interface FixtureState {
    rulesets: Ruleset[];
    rulesetsAreLoaded: boolean;
    loadRulesets: () => void;
    rulesetForms: RulesetForm[];
    rulesetFormsAreLoaded: boolean;
    loadRulesetForms: () => void;
    forms: Form[];
    formsAreLoaded: boolean;
    loadForms: () => void;
    categories: Form[];
    categoriesAreLoaded: boolean;
    loadCategories: () => void;
    fileTemplates: FileTemplate[];
    fileTemplatesAreLoaded: boolean;
    loadFileTemplates: () => void;
    isInited: boolean;
    init: () => void;
    tags: Tag[];
    loadTags: () => void;
    tagsAreLoaded: boolean;
    adjustmentsDefault: AdjustmentDefault[];
    loadAdjustmentsDefault: () => void;
    adjustmentsDefaultAreLoaded: boolean;
}

export const useFixtureStore = create<FixtureState>((set, get) => ({
    rulesets: [],
    rulesetsAreLoaded: false,
    rulesetForms: [],
    rulesetFormsAreLoaded: false,
    forms: [],
    formsAreLoaded: false,
    categories: [],
    categoriesAreLoaded: false,
    fileTemplates: [],
    fileTemplatesAreLoaded: false,
    isInited: false,
    tags: [],
    tagsAreLoaded: false,
    adjustmentsDefault: [],
    adjustmentsDefaultAreLoaded: false,
    init: () => {
        if (!get().rulesetsAreLoaded) get().loadRulesets();
        if (!get().rulesetFormsAreLoaded) get().loadRulesetForms();
        if (!get().formsAreLoaded) get().loadForms();
        if (!get().categoriesAreLoaded) get().loadCategories();
        if (!get().fileTemplatesAreLoaded) get().loadFileTemplates();
        if (!get().tagsAreLoaded) get().loadTags();
        if (!get().adjustmentsDefaultAreLoaded) get().loadAdjustmentsDefault();
    },
    loadRulesets: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/ruleset/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, rulesets: _d, rulesetsAreLoaded: true};
                });
            });
    },
    loadRulesetForms: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/rulesetform/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, rulesetForms: _d, rulesetFormsAreLoaded: true};
                });
            });
    },
    loadForms: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/form/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, forms: _d, formsAreLoaded: true};
                });
            });
    },
    loadCategories: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/adjustmentcategory/?page_size=1000")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, categories: _d, categoriesAreLoaded: true};
                });
            });
    },
    loadTags: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/tag/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, tags: _d, tagsAreLoaded: true};
                });
            });
    },
    loadFileTemplates: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/file_template/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                    set((s) => {
                        return {...s, fileTemplatesAreLoaded: true};
                    });
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, fileTemplates: _d, fileTemplatesAreLoaded: true};
                });
            });
    },
    loadAdjustmentsDefault: () => {
        useGlobalStore
            .getState()
            .fetchApi("/settings/_/fixtures/adjustmentdefault/")
            .then((res) => res.json())
            .then((res) => {
                let _d = [];
                if (typeof res.error !== "undefined") {
                    useGlobalStore.getState().addErrorNotification(res);
                } else {
                    _d = res.results;
                }

                set((s) => {
                    return {...s, adjustmentsDefault: _d, adjustmentsDefaultAreLoaded: true};
                });
            });
    },
}));
