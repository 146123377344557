import React from "react";

interface VerticalProgressBarProps {
    status: "normal" | "error" | "unanswered" | "important";
    height: number;
}

const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({status, height}) => {
    const getProgressBarColor = (status: "normal" | "error" | "unanswered" | "important") => {
        switch (status) {
            case "normal":
                return "#4EB523"; // Green
            case "error":
                return "#E0301E"; // Red
            case "unanswered":
                return "#B3B3B3"; // Grey
            case "important":
                return "#FFBF1F"; // Orange
            default:
                return "#B3B3B3";
        }
    };

    const progressBarStyle = {
        width: "4px",
        height: "100%",
        backgroundColor: getProgressBarColor(status),
        borderRadius: "2px",
    };

    const progressButtonStyle = {
        marginLeft: "5px",
        color: getProgressBarColor(status),
    };

    const iconClassName =
        status === "error"
            ? "Appkit4-icon icon-circle-delete-outline"
            : status === "unanswered"
            ? "Appkit4-icon icon-circle-minus-outline"
            : status === "important"
            ? "Appkit4-icon icon-circle-warning-outline"
            : "Appkit4-icon icon-circle-checkmark-outline";

    return (
        <div
            className="vertical-progress-bar"
            style={{display: "flex", alignItems: "center", height: `${height}px`, width: "100%"}}
        >
            <div className="progress-bar" style={progressBarStyle}></div>
            <div className="progress-button" style={progressButtonStyle}>
                <span className={iconClassName}></span>
            </div>
        </div>
    );
};

export default VerticalProgressBar;
