import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Table, Column} from "@appkit4/react-components/table";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {useFixtureStore} from "../../../stores/FixtureStore";
import RulesetFormForm from "./Forms/RulesetFormForm";
import CategoryGroupForm from "./Forms/CategoryGroupForm";
import {RulesetCategoryPairs} from "../../../types/RulesetCategoryPairs";
import RulesetCategoryPairsForm from "./Forms/RulesetCategoryPairsForm";
import {useTr} from "../../../utils/trUtil";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: RulesetCategoryPairs[];
}

const AdminRulesetCategoryPairs: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, setData] = React.useState<RulesetCategoryPairs[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);
    const trg = useTr("global");
    console.log(data);

    const {loadCategories, categories, loadRulesets, rulesets, init, fixtureState} = useFixtureStore((s) => ({
        loadCategories: s.loadCategories,
        categories: s.categories,
        loadRulesets: s.loadRulesets,
        rulesets: s.rulesets,
        init: s.init,
        fixtureState: s,
    }));

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        init();
    }, [init]);

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false || dataAreLoading === true) {
            fetchApi(`/settings/_/fixtures/adjustmentcategoryruleset/?page=${current}`)
                .then((r) => r.json())
                .then((res: PaginationType) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res.results);
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current]);

    const mergedData = data.map((dataItem) => {
        const category = categories.find((cat) => cat.id === dataItem.adjustment_category);

        if (category) {
            return {
                ...dataItem,
                name_en: category.name_en,
                name_sk: category.name_sk,
            };
        } else {
            return {
                ...dataItem,
                categoryName: "Uncategorized",
                categoryDescription: "No description",
            };
        }
    });

    const renderActionCell = (row) => {
        return (
            <>
                <RulesetCategoryPairsForm
                    key={`edit-${row.id}`}
                    rulesetCategoryPairs={row}
                    onSaveHandler={() => setDataAreLoaded(false)}
                />
            </>
        );
    };

    const pagination = (
        <Pagination
            current={current}
            total={Math.ceil(totalCount / countPerPage)}
            onPageChange={onPageChange}
        ></Pagination>
    );

    return (
        <CanShowAdminMiddleware>
            {fixtureState.rulesetsAreLoaded && fixtureState.formsAreLoaded && dataAreLoaded && (
                <div className="container-fluid">
                    {pagination}
                    <Table originalData={mergedData} hasTitle striped condensed>
                        <Column field="id" sortKey="id" slot="end">
                            {trg("id")}
                        </Column>
                        <Column field="adjustment_category" sortKey="adjustment_category">
                            {trg("category_id")}
                        </Column>
                        <Column field="ruleset" sortKey="ruleset">
                            {trg("ruleset_id")}
                        </Column>
                        <Column field="name_en" sortKey="name_en">
                            {trg("category_name_en")}
                        </Column>
                        <Column field="name_sk" sortKey="name_sk">
                            {trg("category_name_sk")}
                        </Column>
                        <Column field="id" title={"Action"} renderCell={renderActionCell}>
                            {trg("category_name_en")}
                        </Column>
                    </Table>
                    {pagination}
                </div>
            )}
            <RulesetCategoryPairsForm onSaveHandler={() => setDataAreLoaded(false)} />
        </CanShowAdminMiddleware>
    );
};

export default AdminRulesetCategoryPairs;
