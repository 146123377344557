import React from "react";
import "./MyClients.scss";
import PwcIcon, {PwcIconTypes} from "../../misc/PwcIcon";
import {DropDownOpenableContext, IDropDownOpenableContext} from "./MyClients";
import {Button} from "@appkit4/react-components";

export enum ItemLevel {
    first = "top-level",
    second = "second-level",
}

interface ItemPropTypes {
    id: string;

    title: string;
    itemLevel: ItemLevel;

    activationClick: (id: string) => void;

    buttonText: string;
    buttonClick: () => void;

    canShowEdit: boolean;
    editButtonClick?: (hash: string, type: string) => void;

    isFavourite: boolean;
    starClick: () => void;

    isArchived: boolean;

    children: React.ReactNode;
}

const DropdownOpenableItem: React.FC<ItemPropTypes> = (props: ItemPropTypes) => {
    const {activeIds, shouldShowArchived} = React.useContext<IDropDownOpenableContext>(DropDownOpenableContext);

    // TODO: Remove when MakeFavorite will be implemented
    const [isFavourite, setIsFavourite] = React.useState<boolean>(false);

    const starProps = React.useMemo(
        () => ({
            size: 28,
            className: `Appkit4-icon ap-font-28 mx-2 icon-entity-item clickable ${
                props.isFavourite || isFavourite ? "icon-rating-fill" : "icon-rating-outline"
            }`,
            onClick: () => {
                props.starClick();
                setIsFavourite((s) => !s);
            },
        }),
        [props.isFavourite, props.starClick, isFavourite]
    );
    const isActive = React.useMemo(() => activeIds.some((id) => id === props.id), [activeIds, props.id]);

    if (props.isArchived && !shouldShowArchived) return <></>;
    return (
        <div className="col entity-item-wrap">
            <div className="entity-item-bg client-list-toolbar d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="d-flex justify-content-end me-3 align-items-center entity-item-items-wrapper">
                    <div className="d-flex me-auto clickable" onClick={() => props.activationClick(props.id)}>
                        <PwcIcon
                            iconName="down-chevron"
                            iconType={PwcIconTypes.outline}
                            additionalClassNames={`icon-strong-2-5 icon-primary dropdown-clients-icon ${
                                isActive ? "chevron-active" : "chevron-deactive"
                            }`}
                        />
                        <h5 className="me-auto">{props.title}</h5>
                    </div>
                    <Button
                        kind={props.itemLevel === ItemLevel.first ? "tertiary" : "secondary"}
                        className="me-2"
                        icon={props.itemLevel === ItemLevel.second ? "icon-plus-fill" : ""}
                        onClick={props.buttonClick}
                        compact
                    >
                        {props.buttonText}
                    </Button>
                    {props.canShowEdit && typeof props.editButtonClick != undefined && (
                        <span
                            className="Appkit4-icon icon-setting-outline ap-font-28 mx-2 icon-entity-item clickable"
                            aria-hidden="true"
                            onClick={() =>
                                props.editButtonClick &&
                                props.editButtonClick(
                                    props.id,
                                    props.itemLevel === ItemLevel.first ? "client" : "entity"
                                )
                            }
                        ></span>
                    )}
                    <span {...starProps}></span>
                </div>
            </div>
            {isActive && props.children}
        </div>
    );
};

export default DropdownOpenableItem;
