export const stripHtml = (html: string) => {
    // Create a DOMParser
    var parser = new DOMParser();

    // Parse the HTML string into a DOM document
    var doc = parser.parseFromString(html, "text/html");

    // Get all script elements in the document
    var scriptElements = doc.getElementsByTagName("script");

    // Remove each script element
    Array.from(scriptElements).forEach(function (script) {
        script.parentNode?.removeChild(script);
    });

    // Convert the modified document back to an HTML string
    var modifiedHtml = new XMLSerializer().serializeToString(doc);

    let tmp = document.createElement("DIV");
    tmp.innerHTML = modifiedHtml;
    return tmp.textContent || tmp.innerText || "";
};
