import React from "react";
import {Button, Panel} from "@appkit4/react-components";
import {Table, Column} from "@appkit4/react-components/table";

import {useTr} from "../../utils/trUtil";
import {Adjustment} from "../../types/Adjustment";
import {useGlobalStore} from "../../stores/GlobalStore";
import "./ImportDataCheck.scss";
import * as O from "fp-ts/Option";
import {Amount, Category, Coefficient, Comment, DeferredTaxClassification} from "../../misc/AdjustmentColumns";
import {RoutingTable, adjustment_types} from "../../Constants";
import LoadingContainer, {CircularWidth} from "../../misc/LoadingContainer";
import PagePanel from "../../misc/PagePanel";
import {getCalculationBaseUrl} from "../../utils/userUtil";
import {useNavigate} from "react-router-dom";
import AdjustmentCreateModal from "./Adjustment/AdjustmentCreateModal";
import {ProvideRouteParams} from "../../utils/routeUtils";

declare type PropTypes = {
    type: string;
    menu: React.ReactNode;
};

const TaxFlowsAdjustmentsByType: React.FC<PropTypes> = (props: PropTypes) => {
    const tr2 = useTr("tax_flows");
    const trg = useTr("global");
    const navigate = useNavigate();

    const adjustments = useGlobalStore((s) => s.currentCalculationAdjustments);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);
    const adjustmentsAreLoading = useGlobalStore((s) => s.adjustmentsAreLoading);
    const [adjustmentToShow, setAdjustmentToShow] = React.useState<Adjustment>();

    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [initDefaultAdjustmentsInProgress, setInitDefaultAdjustmentsInProgress] = React.useState<boolean>(false);

    React.useEffect(() => {
        loadAdjustments();
    }, [loadAdjustments]);

    React.useEffect(() => {
        if (adjustmentToShow !== undefined && O.isSome(adjustments)) {
            let a = adjustments.value.filter((adj) => adj.id === adjustmentToShow.id);
            setAdjustmentToShow(a.length > 0 ? a[0] : undefined);
        }
    }, [adjustments, adjustmentToShow]);

    const getFilteredData = () => {
        if (O.isSome(adjustments)) {
            let data = adjustments.value.filter((item) => item.type === props.type);
            return data;
        } else {
            return [];
        }
    };

    const onRowClickHandler = (event: MouseEvent, index: number, data: any) => {
        setAdjustmentToShow(data);
    };

    const init_default_adjustments = () => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            setInitDefaultAdjustmentsInProgress(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/init_default_adjustments/?type=${props.type}`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") addErrorNotification(data);
                    loadAdjustments();
                    setInitDefaultAdjustmentsInProgress(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setInitDefaultAdjustmentsInProgress(false);
                });
        }
    };

    return (
        <PagePanel
            title={tr2(`${props.type}_label`)}
            submenu={props.menu}
            buttons={
                <div className="d-flex">
                    <Button
                        kind="secondary"
                        compact={true}
                        onClick={init_default_adjustments}
                        loading={initDefaultAdjustmentsInProgress}
                    >
                        {trg("reinit_default_adjustments")}
                    </Button>
                    {[
                        adjustment_types.PROVISIONS,
                        adjustment_types.VALUATION_ALLOWANCES,
                        adjustment_types.CASH_BASIS,
                    ].includes(props.type) ? (
                        O.isSome(entity) &&
                        O.isSome(calculation) && (
                            <Button
                                kind="primary"
                                compact
                                onClick={() => {
                                    navigate(
                                        ProvideRouteParams(RoutingTable.entity_tax_flows_adjustment_entries, [
                                            {
                                                paramName: "type",
                                                paramValue: props.type,
                                            },
                                            {
                                                paramName: "entityHash",
                                                paramValue: entity.value.hash,
                                            },
                                            {
                                                paramName: "calculationHash",
                                                paramValue: calculation.value.hash,
                                            },
                                            {
                                                paramName: "entry_id",
                                                paramValue: "",
                                            },
                                        ])
                                    );
                                }}
                            >
                                {trg("show_entries")}
                            </Button>
                        )
                    ) : (
                        <AdjustmentCreateModal type={props.type} />
                    )}
                </div>
            }
        >
            {getFilteredData().length > 0 && adjustmentsAreLoading === true && (
                <div className="row text-center p-0">
                    <div className="col-md-12 text-center p-0">
                        <LoadingContainer circularWidthOverride={CircularWidth.small} />
                    </div>
                </div>
            )}

            <Table
                className="table-adustments"
                originalData={getFilteredData()}
                hasTitle={true}
                striped={true}
                condensed={true}
                onRowClick={onRowClickHandler}
            >
                <Column sortKey="name" style={{width: "17%"}}>
                    {tr2("name")}
                </Column>
                <Column
                    sortKey="category"
                    style={{width: "17%"}}
                    renderCell={(row, field) => <Category row={row} field={field} />}
                >
                    {tr2("category")}
                </Column>
                <Column
                    style={{width: "25%"}}
                    field="comment_official"
                    renderCell={(row, field) => <Comment row={row} field={field} />}
                >
                    {tr2("comment")}
                </Column>
                <Column
                    style={{width: "15%"}}
                    sortKey="deferred_tax_classification"
                    renderCell={(row, field) => <DeferredTaxClassification row={row} field={field} />}
                >
                    {tr2("tax_classification")}
                </Column>
                <Column
                    style={{width: "6%"}}
                    sortKey="coefficient"
                    renderCell={(row, field) => <Coefficient row={row} field={field} />}
                >
                    {tr2("coefficient")}
                </Column>

                <Column
                    style={{width: "15%"}}
                    sortKey="final_amount"
                    renderCell={(row, field) => <Amount row={row} field={field} amount={row.final_amount} />}
                >
                    {trg("final_amount")}
                </Column>
                <Column
                    field="id"
                    style={{width: "5%", textAlign: "right"}}
                    renderCell={(row, field) => {
                        return (
                            <div className="d-flex flex-row-reverse">
                                <Button
                                    compact
                                    kind="text"
                                    className=""
                                    onClick={(e) => {
                                        //setAdjustmentToShow(row);
                                        navigate(
                                            `${getCalculationBaseUrl(entity, calculation)}/tax-flows/adjustment/${
                                                row.id
                                            }`
                                        );
                                    }}
                                    style={{padding: "3px 1px 5px 7px"}}
                                >
                                    <span className="Appkit4-icon icon-pencil-outline"></span>
                                </Button>
                            </div>
                        );
                    }}
                >
                    {tr2("actions")}
                </Column>
            </Table>

            {O.isSome(entity) &&
                O.isSome(calculation) &&
                getFilteredData().length === 0 &&
                adjustmentsAreLoading === false && (
                    <Panel className="mt-3">
                        <div className="row text-center">
                            <div className="col-md-12 mt-3">
                                <h4 className="text-muted">{trg("list_of_adjustments_is_empty")}</h4>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="d-inline-block mx-auto my-3">
                                    {[
                                        adjustment_types.PROVISIONS,
                                        adjustment_types.CASH_BASIS,
                                        adjustment_types.VALUATION_ALLOWANCES,
                                    ].includes(props.type) ? (
                                        <Button
                                            kind="primary"
                                            onClick={() => {
                                                navigate(
                                                    ProvideRouteParams(
                                                        RoutingTable.entity_tax_flows_adjustment_entries,
                                                        [
                                                            {
                                                                paramName: "type",
                                                                paramValue: props.type,
                                                            },
                                                            {
                                                                paramName: "entityHash",
                                                                paramValue: entity.value.hash,
                                                            },
                                                            {
                                                                paramName: "calculationHash",
                                                                paramValue: calculation.value.hash,
                                                            },
                                                        ]
                                                    )
                                                );
                                            }}
                                        >
                                            {trg("go_to_adjustment_entries")}
                                        </Button>
                                    ) : (
                                        <AdjustmentCreateModal type={props.type} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Panel>
                )}
            {getFilteredData().length === 0 && adjustmentsAreLoading === true && (
                <Panel className="p-0 mb-5">
                    <div className="row text-center p-0">
                        <div className="col-md-12 text-center p-0">
                            <LoadingContainer circularWidthOverride={CircularWidth.small} />
                        </div>
                    </div>
                </Panel>
            )}
        </PagePanel>
    );
};

export default TaxFlowsAdjustmentsByType;
