import {Avatar, DropdownButton, Modal} from "@appkit4/react-components";
import React from "react";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import {getUserClaim} from "../../utils/claimUtils";
import {getInitials} from "../../utils/nameFormatter";
import {ItemDataType, ValueType} from "@appkit4/react-components/esm/dropdown/DropdownButton";
import {AvailableLanguages} from "../../types/i18n";
import i18n from "../../utils/i18n";
import {useAuth} from "react-oidc-context";

interface PropTypes {
    //onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}
const UserAvatar: React.FC<PropTypes> = (props: PropTypes) => {
    const auth = useAuth();
    const logout = useGlobalStore((s) => s.logout);

    const menuClickHandler = (value: ValueType, item: ItemDataType) => {
        switch (value) {
            case "lang_sk":
                i18n.changeLanguage(AvailableLanguages.Slovak);
                window.location.reload();
                break;
            case "lang_en":
                i18n.changeLanguage(AvailableLanguages.English);
                window.location.reload();
                break;
            case "logout":
                //window.location.href = "/.auth/logout";
                //setAccessToken(undefined);
                //auth.removeUser();
                logout();
                break;
            default:
                break;
        }
    };
    const prefixeMenuTemplate = (item: ItemDataType) => {
        if (item.value === "lang_sk") return <img src="https://flagpedia.net/data/flags/icon/16x12/sk.webp" alt="sk" />;
        if (item.value === "lang_en") return <img src="https://flagpedia.net/data/flags/icon/16x12/gb.webp" alt="en" />;
        if (item.value === "logout") return <span className="Appkit4-icon icon-log-out-outline"></span>;
        return <></>;
    };

    return (
        <>
            {auth.user ? (
                <div
                    className="user-avatar-wrapper"
                    title={auth.user.profile.preferredMail + ""}
                    style={{justifyContent: "space-evenly"}}
                >
                    <DropdownButton
                        kind="text"
                        style={{cursor: "pointer"}}
                        data={[
                            {
                                label: "english",
                                value: "lang_en",
                            },
                            {
                                label: "slovenčina",
                                value: "lang_sk",
                            },
                            {
                                label: "Log out",
                                value: "logout",
                            },
                        ]}
                        onSelect={menuClickHandler}
                        role="link"
                        customTriggerNode={true}
                        customTriggerClassName="custom-node"
                        splitButton={false}
                        prefixTemplate={prefixeMenuTemplate}
                    >
                        <Avatar
                            label={getInitials(auth.user && auth.user.profile.name ? auth.user.profile.name : "")}
                            className="keyboard-focus"
                            ariaLabel="settings menu"
                            //style={{cursor: "pointer"}}
                            //role="link"
                            disabled={false}
                        />
                    </DropdownButton>
                </div>
            ) : (
                <Avatar />
            )}
            {/* <Modal></Modal> */}
        </>
    );
};

export default UserAvatar;
