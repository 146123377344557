import React from "react";
import {Adjustment} from "../../../types/Adjustment";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Table, Column, Panel, Button, Loading} from "@appkit4/react-components";
import {Number} from "../../../misc/AdjustmentColumns";
import {zeroToString} from "../../../utils/zeroToString";
import {useParams} from "react-router-dom";

declare type PropTypes = {
    adjustment?: Adjustment;
};

export const AdjustmentJournalEntries: React.FC<PropTypes> = (props: PropTypes) => {
    const params = useParams();

    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);

    const [adjustmentId, setAdjustmentId] = React.useState<number>();
    const [entries, setEntries] = React.useState<any[]>([]);
    const [entriesAreLoaded, setEntriesAreLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (params.id) {
            setAdjustmentId(parseInt(params.id));
        } else if (props.adjustment) {
            setAdjustmentId(props.adjustment.id);
        }
    }, [props, params]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && adjustmentId && entriesAreLoaded === false) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustmentId}/result/`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setEntries(
                            data.journal_entries.map((entry: any) => {
                                return entry;
                            })
                        );
                    }
                    setEntriesAreLoaded(true);
                    loadAdjustments();
                })
                .catch((error) => {
                    //console.log(error);
                    setEntriesAreLoaded(true);
                });
        }
    }, [entity, calculation, adjustmentId, entriesAreLoaded, addErrorNotification, fetchApi, loadAdjustments]);

    return (
        <div className="row">
            <div className="col-md-12 mt-3">
                <Panel>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5>
                                {trg("list_of_transactions_by_filters")}
                                <small className="text-muted fw-normal">
                                    &nbsp;&nbsp;({entries.length > 100 && "shows only first 100 from "}
                                    {entries.length} entries)
                                </small>
                            </h5>
                        </div>

                        <div>
                            <Button
                                icon="icon-refresh-outline"
                                compact
                                kind="text"
                                onClick={() => setEntriesAreLoaded(false)}
                                loading={entriesAreLoaded === false}
                            >
                                {trg("refresh")}
                            </Button>
                        </div>
                    </div>
                    <div style={{height: "15px"}}>
                        {entriesAreLoaded === false && (
                            <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                        )}
                    </div>
                    <Table
                        striped={true}
                        condensed={true}
                        hasTitle={true}
                        originalData={zeroToString(entries.slice(0, 100))}
                    >
                        <Column sortKey="account__number">{trg("account")}</Column>
                        <Column sortKey="posting_date">{trg("posting_date")}</Column>
                        <Column sortKey="description">{trg("description")}</Column>
                        <Column sortKey="document_no">{trg("document_no")}</Column>
                        <Column
                            sortKey="amount"
                            renderCell={(row, field) => <Number field={field} row={row} amount={row.amount} />}
                        >
                            {trg("amount")}
                        </Column>
                        <Column
                            sortKey="debit_amount"
                            renderCell={(row, field) => <Number field={field} row={row} amount={row.debit_amount} />}
                        >
                            {trg("debit_amount")}
                        </Column>
                        <Column
                            sortKey="credit_amount"
                            renderCell={(row, field) => <Number field={field} row={row} amount={row.credit_amount} />}
                        >
                            {trg("credit_amount")}
                        </Column>
                    </Table>
                    {entriesAreLoaded && entries.length === 0 && (
                        <div className="border rounded-bottom p-3">{trg("list_of_entries_is_empty")}</div>
                    )}
                </Panel>
            </div>
        </div>
    );
};
