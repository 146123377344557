import {Button, Loading, Modal, Notice} from "@appkit4/react-components";
import React from "react";
import {useTr} from "../../../utils/trUtil";
import {Entity} from "../../../types/Entity";
import {Calculation} from "../../../types/Calculation";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Question} from "../../../types/Question";
import useDownloadUrl from "../../../hooks/useDownloadUrl";
import {BackgroundExport} from "../../../types/BackgroundExport";
import {parseJsonSourceFileConfigFileContent} from "typescript";

export type Props = {
    entity: Entity;
    calculation: Calculation;
    questions: Question[];
    isVisible: (q: Question) => boolean;
};

const ModalExportComponent: React.FC<Props> = ({entity, calculation, questions, isVisible}) => {
    const [visibleExportModal, setVisibleExportModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [lastZipExportCount, setLastZipExportCount] = React.useState<number>(1);
    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const {renderLink, download} = useDownloadUrl();

    const [lastExportAttachmentsZip, setLastExportAttachmentsZip] = React.useState<BackgroundExport>();

    const reloadLastExportAttachmentsZip = React.useCallback(() => {
        if (entity && calculation) {
            fetchApi(`/entity/${entity.hash}/calculation/${calculation.hash}/question/last_export_attachments_zip/`)
                .then((res) => res.json())
                .then((res: BackgroundExport) => {
                    setLastExportAttachmentsZip(res ? res : undefined);
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    }, [entity, calculation, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setLastZipExportCount(lastZipExportCount + 1);
            reloadLastExportAttachmentsZip();
        }, 5000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [lastZipExportCount, reloadLastExportAttachmentsZip]);

    const downloadQuestionnaire = () => {
        if (entity && calculation) {
            fetchApi(
                `/entity/${entity.hash}/calculation/${calculation.hash}/question/export_questionnaire/`,
                "post",
                questions.map((q) => ({question_id: q.id, is_visible: isVisible(q)}))
            )
                .then((res) => {
                    if (res.status >= 400) {
                        return res.json();
                    }
                    return res.blob();
                })
                .then((response) => {
                    if (typeof response.error !== "undefined") {
                        addErrorNotification(response);
                    } else {
                        const blobUrl = URL.createObjectURL(response);
                        const filename = `questionnaire_export_${calculation.hash}.xlsx`;
                        download(blobUrl, filename);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    };

    const prepareAttachments = () => {
        setIsLoading(true);
        if (entity && calculation) {
            fetchApi(`/entity/${entity.hash}/calculation/${calculation.hash}/question/export_attachments_zip/`)
                .then((res) => res.json())
                .then((res) => {
                    if (typeof res.zip_public_url !== "undefined") {
                        download(res.zip_public_url, "questionnaire_attachments.zip");
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsLoading(false);
                });
        }
    };

    const renderLastExportAttachmentsZip = React.useMemo(() => {
        if (!lastExportAttachmentsZip) return <></>;
        if ([1, 2].includes(lastExportAttachmentsZip.status))
            return (
                <div className="alert alert-warning m-1 py-1 px-2">
                    <div className=" d-flex">
                        <Loading indeterminate={true} loadingType="circular" compact={true} className="mt-1" />
                        <div>&nbsp;&nbsp;{trg("preparing_new_file_to_download")}</div>
                    </div>
                </div>
            );
        if (lastExportAttachmentsZip.status === 3)
            return (
                <div className="alert alert-success py-1 px-2">
                    <a href={lastExportAttachmentsZip.public_url} title={lastExportAttachmentsZip.filename}>
                        {lastExportAttachmentsZip.filename}
                    </a>
                    <br />
                    <small className="text-muted">
                        ({new Date(lastExportAttachmentsZip.running_at).toLocaleString()})
                    </small>
                </div>
            );
        if (lastExportAttachmentsZip.status === 4)
            return <div className="alert alert-danger m-1 py-1 px-2">{trg("please_try_again_later")}</div>;
    }, [lastExportAttachmentsZip, trg]);

    return (
        <>
            <Button onClick={() => setVisibleExportModal(true)} compact>
                {trg("export")}
            </Button>
            <Modal
                visible={visibleExportModal}
                title={trg("questionnaire_exports")}
                ariaLabel={""}
                onCancel={() => setVisibleExportModal(false)}
                modalStyle={{minWidth: "740px"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={<></>}
                icons={""}
                footer={
                    <Button
                        kind="secondary"
                        onClick={() => {
                            setVisibleExportModal(false);
                        }}
                    >
                        {trg("cancel")}
                    </Button>
                }
                // bodyStyle={{minHeight: "92px"}}
            >
                <div className="row">
                    <div className="col text-center">
                        <Button
                            kind="secondary"
                            icon="icon-download-light-outline"
                            className="mx-auto"
                            onClick={() => downloadQuestionnaire()}
                        >
                            {trg("export_questionnaire")} (.xlsx)
                        </Button>
                        <Notice
                            title=""
                            message={trg("questionnaire_export_xls_notice")}
                            status="icon-information-outline"
                            closeable={false}
                            className="mt-3"
                        ></Notice>
                    </div>
                    <div className="col text-center">
                        <Button
                            onClick={() => prepareAttachments()}
                            kind="secondary"
                            icon="icon-download-light-outline"
                            className="mx-auto"
                            loading={isLoading}
                        >
                            {trg("prepare_download")} (.zip)
                        </Button>
                        <div className="mt-3">{renderLastExportAttachmentsZip}</div>
                    </div>
                </div>
            </Modal>
            {renderLink}
        </>
    );
};

export default ModalExportComponent;
