import React from "react";
import {FormRow} from "../../../types/FormRow";
import {RadioGroup, Radio, Input, Accordion, AccordionItem} from "@appkit4/react-components";
import {RecursiveFormRowsComponent} from ".././CalculationForm";

declare type PropTypes = {
    element: FormRow;
    parentPath: string;
    children: React.ReactElement;
};

const HtmlPanel: React.FC<PropTypes> = ({element, parentPath, children}) => {
    const [activeKeys, setActiveKeys] = React.useState<string[]>(["1"]);
    return (
        <div className="border p-0 mt-2 mb-2 ms-2 me-0">
            <Accordion
                multiple={true}
                activeKeys={activeKeys}
                onClick={() => setActiveKeys((p) => (p.length > 0 ? [] : ["1"]))}
                className="p-0 m-0"
            >
                <AccordionItem title={element.description} itemKey="1">
                    <div className="AccordionItem-text">{children}</div>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default HtmlPanel;
