import React, {useState} from "react";
import {Checkbox, CheckboxGroup, CheckboxValueType} from "@appkit4/react-components";
import {DefaultComponentProps} from "../Questionnaire";
import {useTr} from "../../../utils/trUtil";

const CheckboxComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const initialValue: CheckboxValueType[] = question.answer?.data?.value || [];
    const trg = useTr("global");
    const [values, setValues] = useState<CheckboxValueType[]>(initialValue);

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        setValues(checkedValues);
        const stringValues: string[] = checkedValues.map(String);
        onChangeHandler(question, stringValues);
    };

    const normalizedValues: CheckboxValueType[] = Array.isArray(values) ? values : [];

    return (
        <CheckboxGroup value={normalizedValues} onChange={handleCheckboxChange}>
            {question.choices.map((choice, index) => (
                <Checkbox className="ms-3" key={index} value={choice}>
                    {trg(choice)}
                </Checkbox>
            ))}
        </CheckboxGroup>
    );
};

export default CheckboxComponent;
