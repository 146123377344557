import React from "react";
import {FormRow} from "../../../types/FormRow";
import {Combobox, Loading} from "@appkit4/react-components";

import i18n from "../../../utils/i18n";
import HtmlPanel from "./HtmlPanel";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {InputPropTypes} from "../CalculationFormElement";

const InputSelect: React.FC<InputPropTypes> = (props) => {
    const {element, parentPath, currentPath, children} = props;
    const [data, setData] = React.useState<any[]>([]);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    React.useEffect(() => {
        if (element.source_data === "codebooks.sk_nace") {
            fetchApi(`/codebooks/sk_nace/`)
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setData(
                            data.map((d: any) => ({
                                key: d.codeAcronym,
                                label: d.codeAcronym + " - " + d.codeOfficialTitle,
                            }))
                        );
                    }
                    setDataAreLoaded(true);
                });
        } else {
            setDataAreLoaded(true);
        }
    }, []);

    const dropwdown = dataAreLoaded ? (
        <Combobox
            data={data}
            valueKey={"key"}
            labelKey={"label"}
            placeholder="--"
            defaultValue={element.value}
            onSelect={(v) => props.onChangeHandler(element, v)}
        ></Combobox>
    ) : (
        <Loading loadingType="circular" indeterminate={true} compact={false} style={{margin: "10px 0"}}></Loading>
    );

    return element.html_type === "panel" ? (
        <>
            <HtmlPanel {...props}>
                <>
                    <div className="border mx-2 px-2">{dropwdown}</div>
                    {children}
                </>
            </HtmlPanel>
        </>
    ) : (
        <>
            <div className="d-flex">
                <div style={{width: "60%"}} className="p-2">
                    {element.description}
                </div>
                <div className="px-5 text-end pt-3" style={{width: "20%"}}>
                    {element.info}
                </div>
                <div>{dropwdown}</div>
            </div>
            {children}
        </>
    );
};

export default InputSelect;
