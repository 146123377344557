import React from "react";
import {Link} from "react-router-dom";
import Centered from "../../misc/Centered";

const PageAccessDenied: React.FC = () => (
    <Centered>
        <h2 className="text-center">403 - Access to the requested resource is forbidden!</h2>
        <p className="a-p-30 text-center">
            <Link to="/">Go Home</Link>
        </p>
    </Centered>
);

export default PageAccessDenied;
