import React from "react";
import {Button, Modal} from "@appkit4/react-components";
import {useTr} from "../utils/trUtil";

const Confirmation = (props: {
    solid?: boolean;
    buttonKind?: string;
    buttonText?: string;
    buttonStyle?: {};
    title?: string;
    description?: string;
    onConfirm?: () => any;
}) => {
    const [visible, setVisible] = React.useState(false);
    const trg = useTr("global");

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOnConfirm = () => {
        if (props.onConfirm) props.onConfirm();
        setVisible(false);
        return true;
    };

    return (
        <>
            <Button onClick={showModal} kind={props.buttonKind} style={props.buttonStyle}>
                {props.buttonText ? props.buttonText : "delete"}
            </Button>
            <Modal
                visible={visible}
                className="ap-pattern-confirmation"
                title={props.title ? props.title : trg("delete")}
                closable
                maskCloseable={false}
                onCancel={handleCancel}
                footer={
                    <>
                        <Button kind="secondary" onClick={handleCancel}>
                            {trg("cancel")}
                        </Button>
                        <Button kind="negative" onClick={handleOnConfirm}>
                            {trg("ok")}
                        </Button>
                    </>
                }
            >
                <div className="ap-pattern-confirmation-message">
                    {props.description ? props.description : trg("are_you_sure_you_want_to_delete")}
                </div>
            </Modal>
        </>
    );
};

export default Confirmation;
