import React, {useEffect, useState} from "react";
import {Select, Avatar, Tooltip, Modal} from "@appkit4/react-components";
import {useTr} from "../../../utils/trUtil";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../../stores/GlobalStore";
import UserClaimAddingForm from "../../../misc/UserClaimAddingForm";
import {ClaimTypes} from "../../../Constants";
import {avatarColor} from "../../../utils/avatarColor";
import {useAuth} from "react-oidc-context";
import {isPwCUser} from "../../../utils/isPwCUser";

interface User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
}

const AssignedToComponent: React.FC<{
    initialUsers: User[];
    assignedUser: User;
    setAssignedUser: (user: User | undefined) => void;
}> = ({initialUsers, assignedUser, setAssignedUser}) => {
    const trg = useTr("global");
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const reloadCalculation = useGlobalStore((s) => s.reloadCurrentCalculation);
    const hasUserClaim = useGlobalStore((s) => s.hasUserClaim);

    const [users, setUsers] = useState<User[]>(initialUsers);
    const [assignedUserLocal, setAssignedUserLocal] = useState<User | undefined>(assignedUser);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        if (reloadUsers) {
            reloadCalculation();
            setReloadUsers(false);
        }
    }, [reloadUsers, users]);

    useEffect(() => {
        if (O.isSome(calculation)) setUsers(calculation.value.participants);
    }, [calculation]);

    const handleChange = (value: any) => {
        if (value === "add") {
            setShowAddUserModal(true);
            return;
        }

        const selectedUser = users.find((user) => user.id === value);
        if (selectedUser?.email === assignedUserLocal?.email) {
            setAssignedUserLocal(undefined);
            setAssignedUser(undefined);
            return;
        }
        if (selectedUser) {
            setAssignedUserLocal(selectedUser);
            setAssignedUser(selectedUser);
        }
    };

    return auth.user && isPwCUser(auth.user.profile.preferredMail + "") ? (
        <div>
            <Select
                key={users.length}
                data={[
                    {value: "add", label: trg("add_new_user")},
                    ...users.map((user) => ({
                        value: user.id,
                        label: user.first_name ? user.first_name + " " + user.last_name : user.email,
                        user: user,
                    })),
                ]}
                value={assignedUserLocal ? assignedUserLocal.id : ""}
                onSelect={handleChange}
                searchable
                style={{marginBottom: "8px"}}
                placeholder={trg("assign_question")}
                dropdownMatchWidth={false}
                itemTemplate={(label, item) => (
                    <Tooltip
                        trigger="hover"
                        position="left"
                        distance={10}
                        content={item.user ? item.user.email : trg("add_new_user")}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            {item.value === "add" ? (
                                <span
                                    className="Appkit4-icon icon-add-user-outline"
                                    style={{fontSize: "30px", marginRight: "10px"}}
                                ></span>
                            ) : (
                                <Avatar
                                    label={
                                        item.user.first_name && item.user.last_name
                                            ? item.user.first_name.toUpperCase().charAt(0) +
                                              item.user.last_name.toUpperCase().charAt(0)
                                            : item.user.email.toUpperCase().charAt(0)
                                    }
                                    bgColor={`#${avatarColor(
                                        item.user.first_name
                                            ? item.user.first_name + item.user.last_name
                                            : item.user.email
                                    )}`}
                                    compact
                                />
                            )}
                            <span style={{marginLeft: "10px"}}>
                                {item.user && item.user.first_name && item.user.last_name
                                    ? item.user.first_name + " " + item.user.last_name
                                    : label === trg("add_new_user")
                                    ? label
                                    : item.user.email}
                            </span>
                        </div>
                    </Tooltip>
                )}
                valueTemplate={(label, item) => {
                    if (Array.isArray(item)) return label;
                    return (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Avatar
                                label={
                                    item.user.first_name && item.user.last_name
                                        ? item.user.first_name.toUpperCase().charAt(0) +
                                          item.user.last_name.toUpperCase().charAt(0)
                                        : item.user.email.toUpperCase().charAt(0)
                                }
                                bgColor={`#${avatarColor(
                                    item.user.first_name ? item.user.first_name + item.user.last_name : item.user.email
                                )}`}
                                compact
                            />
                            <span style={{marginLeft: "10px"}}>
                                {item.user && item.user.first_name && item.user.last_name
                                    ? item.user.first_name + " " + item.user.last_name
                                    : label === trg("add_user")
                                    ? label
                                    : item.user.email}
                            </span>
                        </div>
                    );
                }}
            />
            <Modal visible={showAddUserModal} title={trg("add_new_user")} onCancel={() => setShowAddUserModal(false)}>
                {O.isSome(entity) &&
                    O.isSome(calculation) &&
                    hasUserClaim(ClaimTypes.ENTITY, `${entity.value.hash}`) && (
                        <UserClaimAddingForm
                            claimType={ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS}
                            entity={entity.value}
                            calculation={calculation.value}
                            beforeFetch={() => {
                                setShowAddUserModal(false);
                            }}
                            afterFetch={() => {
                                const usersBefore = users;
                                setReloadUsers(true);
                                setShowAddUserModal(false);
                                setAssignedUser(users.find((user) => !usersBefore.includes(user)));
                            }}
                        />
                    )}
            </Modal>
        </div>
    ) : (
        <></>
    );
};

export default AssignedToComponent;
