import React, {useEffect, useRef, useState} from "react";
import {Form, Table} from "react-bootstrap";
import {Panel} from "@appkit4/react-components";
import {useTr} from "../../../utils/trUtil";
import {DefaultComponentProps} from "../Questionnaire";

const CustomComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const tableType = question.choices;
    const trg = useTr("global");
    const rows = [
        "A - " + trg("fixed_intangible_assets"),
        "B - " + trg("low_value_fixed_tangible_assets"),
        "C - " + trg("fixed_tangible_assets"),
        "A+B+C " + trg("total_assets"),
    ];

    const columnsForTable = (table: string): string[] => {
        switch (table) {
            case "fixed_assets_depreciation":
                return [
                    trg("accounting_depreciation"),
                    trg("tax_depreciation"),
                    trg("interrupted_depreciation_of_tangible_assets"),
                    trg("unused_part_of_fixed_asset_depreciation_in_the_year_of_capitalisation"),
                    trg("difference_between_tax_and_accounting_depreciation"),
                ];
            case "fixed_asset_disposal_by_sale":
                return [
                    trg("accounting_net_book_value"),
                    trg("tax_net_book_value"),
                    trg("revenue_from_sale"),
                    trg("difference_between_tax_and_accounting_value"),
                ];
            case "fixed_asset_disposal_by_scrapping_liquidation":
                return [
                    trg("accounting_net_book_value"),
                    trg("tax_net_book_value"),
                    trg("difference_between_tax_and_accounting_value"),
                ];
            case "damaged_or_stolen_assets":
                return [
                    trg("accounting_net_book_value"),
                    trg("tax_net_book_value_of_individual_assets_up_to_compensation_received"),
                    trg("tax_net_book_value_of_individual_assets_exceeding_compensation_received"),
                    trg("compensation_received_for_individual_damaged_assets_exceeding_tax_book_value"),
                    trg("total_tax_net_book_value"),
                    trg("total_compensation_received"),
                    trg("difference_between_tax_and_accounting_value"),
                ];
            default:
                return [];
        }
    };

    const initialInputValues: Record<string, string[][]> = {};
    tableType.forEach((table) => {
        initialInputValues[table] =
            question.answer?.data?.value?.[table] ??
            Array.from({length: rows.length}, () => Array(columnsForTable(table).length).fill(""));
    });
    // console.log("initialInputValues", initialInputValues);
    const [inputValues, setInputValues] = useState<Record<string, string[][]>>(initialInputValues);
    const prevInputValues = useRef<Record<string, string[][]>>(initialInputValues);

    const handleInputChange = (rowIndex: number, table: string, colIndex: number, value: string) => {
        setInputValues((prevInputValues) => {
            const newInputValues = {...prevInputValues};
            newInputValues[table][rowIndex][colIndex] = value;
            return newInputValues;
        });
    };

    const calculateTotalForColumn = (table: string, colIndex: number): string => {
        const columnValues = inputValues[table].map((row) => parseFloat(row[colIndex]) || 0);
        const sum = columnValues.reduce((acc, value) => acc + value, 0);
        return sum.toString();
    };

    const calculateDifferenceForRow = (table: string, rowIndex: number): string => {
        const firstColumnValue = parseFloat(inputValues[table][rowIndex][0]) || 0;
        const secondColumnValue = parseFloat(inputValues[table][rowIndex][1]) || 0;

        const difference = firstColumnValue - secondColumnValue;

        return difference.toString();
    };

    const renderTable = (table: string) => {
        const columns = columnsForTable(table);

        return (
            <Table key={table}>
                <thead>
                    <tr>
                        <th>{trg(table)}</th>
                        {columns.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.slice(0, 3).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{row}</td>
                            {columns.map((col, colIndex) =>
                                colIndex === columns.length - 1 ? (
                                    <td key={colIndex}>{calculateDifferenceForRow(table, rowIndex)}</td>
                                ) : (
                                    <td key={colIndex}>
                                        <Form.Control
                                            type="number"
                                            value={inputValues[table][rowIndex]?.[colIndex] || ""}
                                            onChange={(e) =>
                                                handleInputChange(rowIndex, table, colIndex, e.target.value)
                                            }
                                        />
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                    <tr>
                        <td>{rows[3]}</td>
                        {columns.map((_, colIndex) => (
                            <td key={colIndex}>
                                {colIndex < columns.length - 1
                                    ? calculateTotalForColumn(table, colIndex)
                                    : inputValues[table][3]?.[colIndex] || ""}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        );
    };

    useEffect(() => {
        onChangeHandler(question, inputValues);
    }, [inputValues]);

    return <Panel className="bg-light">{tableType.map((table) => renderTable(table))}</Panel>;
};

export default CustomComponent;
