import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select, Checkbox} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {CategoryGroup} from "../../../../types/CategoryGroup";
import {nonEmptyArray} from "fp-ts";
import {Category} from "../../../../types/Category";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    categoryGroup?: Category;
    onSaveHandler?: () => void;
};

const CategoryForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<Category | undefined>(
        props.categoryGroup ? props.categoryGroup : undefined
    );

    const {loadCategories} = useFixtureStore((s) => ({
        loadCategories: s.loadCategories,
    }));

    const [data, setData] = React.useState<Category>({
        id: props.categoryGroup ? props.categoryGroup.id : 0,
        name: props.categoryGroup ? props.categoryGroup.name : "",
        name_sk: props.categoryGroup ? props.categoryGroup.name_sk : "",
        category_group: props.categoryGroup ? props.categoryGroup.category_group : "",
        default_deferred_tax_classification: props.categoryGroup
            ? props.categoryGroup.default_deferred_tax_classification
            : "",
        default_generic_comment_en: props.categoryGroup ? props.categoryGroup.default_generic_comment_en : "",
        default_generic_comment_sk: props.categoryGroup ? props.categoryGroup.default_generic_comment_sk : "",
        coefficient: props.categoryGroup ? props.categoryGroup.coefficient : 0,
        priority: props.categoryGroup ? props.categoryGroup.priority : 0,
        adjustment_type: props.categoryGroup ? props.categoryGroup.adjustment_type : "",
        for_release: props.categoryGroup ? props.categoryGroup.for_release : false,
        for_creation: props.categoryGroup ? props.categoryGroup.for_creation : false,
        for_adjustment_entry_tax_type: props.categoryGroup ? props.categoryGroup.for_adjustment_entry_tax_type : null,
        increase_tax_base: props.categoryGroup ? props.categoryGroup.increase_tax_base : false,
        calculation_step: props.categoryGroup ? props.categoryGroup.calculation_step : 0,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.categoryGroup
            ? `/settings/_/fixtures/adjustmentcategory/${props.categoryGroup.id}/`
            : `/settings/_/fixtures/adjustmentcategory/`;

        fetchApi(endpoint, props.categoryGroup ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    loadCategories();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const trg = useTr("global");

    React.useEffect(() => {
        setRulesetForm(props.categoryGroup);
        setData({
            id: props.categoryGroup ? props.categoryGroup.id : 0,
            name: props.categoryGroup ? props.categoryGroup.name : "",
            name_sk: props.categoryGroup ? props.categoryGroup.name_sk : "",
            category_group: props.categoryGroup ? props.categoryGroup.category_group : "",
            default_deferred_tax_classification: props.categoryGroup
                ? props.categoryGroup.default_deferred_tax_classification
                : "",
            default_generic_comment_en: props.categoryGroup ? props.categoryGroup.default_generic_comment_en : "",
            default_generic_comment_sk: props.categoryGroup ? props.categoryGroup.default_generic_comment_sk : "",
            coefficient: props.categoryGroup ? props.categoryGroup.coefficient : 0,
            priority: props.categoryGroup ? props.categoryGroup.priority : 0,
            adjustment_type: props.categoryGroup ? props.categoryGroup.adjustment_type : "",
            for_release: props.categoryGroup ? props.categoryGroup.for_release : false,
            for_creation: props.categoryGroup ? props.categoryGroup.for_creation : false,
            for_adjustment_entry_tax_type: props.categoryGroup
                ? props.categoryGroup.for_adjustment_entry_tax_type
                : null,
            increase_tax_base: props.categoryGroup ? props.categoryGroup.increase_tax_base : false,
            calculation_step: props.categoryGroup ? props.categoryGroup.calculation_step : 0,
        });
    }, [props.categoryGroup]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Category ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "290px"}}>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name")}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name_sk")}
                            value={data.name_sk}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name_sk: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("category_group")}
                            value={data.category_group}
                            onChange={(val: string) => setData((s) => ({...s, category_group: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={["temporary", "permanent"]}
                            value={data.default_deferred_tax_classification}
                            onChange={(val: string) =>
                                setData((s) => ({...s, default_deferred_tax_classification: val}))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("default_generic_comment_en")}
                            value={data.default_generic_comment_en}
                            onChange={(val: string) => setData((s) => ({...s, default_generic_comment_en: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("default_generic_comment_sk")}
                            value={data.default_generic_comment_sk}
                            onChange={(val: string) => setData((s) => ({...s, default_generic_comment_sk: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("coefficient")}
                            value={data.coefficient}
                            required
                            onChange={(val: number) => setData((s) => ({...s, coefficient: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("priority")}
                            value={data.priority}
                            requried
                            onChange={(val: number) => setData((s) => ({...s, priority: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={[
                                "journal",
                                "manual",
                                "tacable_result",
                                "fixed_assets",
                                "provisions",
                                "valuation_allowances",
                                "accounting_result",
                                "cash_basis",
                                "helpful",
                            ]}
                            placeholder={trg("adjustment_type")}
                            value={data.adjustment_type}
                            searchable={true}
                            onSelect={(val) => setData((s) => ({...s, adjustment_type: val.toString()}))}
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Checkbox checked={data.for_release} onChange={(v) => setData((s) => ({...s, for_release: v}))}>
                            {trg("for_release")}
                        </Checkbox>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Checkbox
                            checked={data.for_creation}
                            onChange={(v) => setData((s) => ({...s, for_creation: v}))}
                        >
                            {trg("for_creation")}
                        </Checkbox>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={[
                                "null",
                                "TD",
                                "TND-T",
                                "TND-P",
                                "TND-U",
                                "BS",
                                "TD - Bad Debt",
                                "TND-T - Bad Debt",
                                "TND-P - Bad Debt",
                                "TND-U - Bad Debt",
                                "BS - Bad Debt",
                                "TD - Inventory",
                                "TND-T - Inventory",
                                "TND-P - Inventory",
                                "TND-U - Inventory",
                                "BS - Inventory",
                                "TD - F.Assets",
                                "TND-T - F.Assets",
                                "TND-P - F.Assets",
                                "TND-U - F.Assets",
                                "BS - F.Assets",
                                "Compens. fees",
                                "Rent",
                                "Market./Oth. studies",
                                "Intermediary fees",
                                "Fees non-treat. ctry.",
                                "Prof. services",
                                "Contr. penalties",
                                "Sponsoring",
                                "Ad. by non-profit org.",
                                "Non-life ins. tax",
                                "Other cash basis",
                            ]}
                            value={data.for_adjustment_entry_tax_type}
                            placeholder={trg("for_adjustment_entry_tax_type")}
                            searchable={true}
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    for_adjustment_entry_tax_type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Checkbox
                            checked={data.increase_tax_base}
                            onChange={(v) => setData((s) => ({...s, increase_tax_base: v}))}
                        >
                            {trg("increase_tax_base")}
                        </Checkbox>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("calcualtion_step")}
                            value={data.calculation_step}
                            required
                            onChange={(val: number) => setData((s) => ({...s, calculation_step: val}))}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CategoryForm;
