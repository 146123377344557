import React from "react";
import {Button, Modal, Input} from "@appkit4/react-components";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Adjustment} from "../../../types/Adjustment";
import * as O from "fp-ts/Option";

import {useTr} from "../../../utils/trUtil";
import {stripHtml} from "../../../utils/stripHtml";
import {getCalculationBaseUrl} from "../../../utils/userUtil";
import {useNavigate} from "react-router-dom";

declare type PropTypes = {
    type: string;
    adjustment?: Adjustment;
    filter?: any;
};

const AdjustmentCreateModal: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("adjustment_form");
    const trg = useTr("global");
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const addSuccessNotification = useGlobalStore((s) => s.addSuccessNotification);

    const [name, setName] = React.useState<string>("");
    const [errorName, setErrorName] = React.useState<string>("");
    const [filterName, setFilterName] = React.useState<string>("");

    const saveHandler = () => {
        if (!name || name === "") {
            setErrorName(trg("name_is_required"));
            return;
        }
        setErrorName("");

        if (O.isSome(calculation) && O.isSome(entity)) {
            setIsLoading(true);
            createAdjustment(calculation, entity)
                .then(handleAdjustmentResponse)
                .catch(handleError)
                .finally(() => setIsLoading(false));
        }
    };

    const createAdjustment = (calculation, entity) => {
        return fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/`, "post", {
            name: name,
            type: props.type,
        }).then((response) => response.json());
    };

    const handleAdjustmentResponse = (data) => {
        if (data.error) {
            addErrorNotification(data);
            loadAdjustments();
        } else {
            loadAdjustments();
            if (props.filter && O.isSome(calculation) && O.isSome(entity)) {
                createFilter(data.id, calculation, entity)
                    .then(handleFilterResponse)
                    .catch(handleError)
                    .finally(() => setIsLoading(false));
            } else {
                navigate(`${getCalculationBaseUrl(entity, calculation)}/tax-flows/adjustment/${data.id}`);
            }
        }
    };

    const createFilter = (adjustmentId, calculation, entity) => {
        return fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter/`, "post", {
            name: filterName,
            adjustment_id: adjustmentId,
            criteria: props.filter,
        }).then((response) => response.json());
    };

    const handleFilterResponse = (data) => {
        if (data.error) {
            addErrorNotification(data);
            loadAdjustments();
        } else {
            loadAdjustments();
            addSuccessNotification("The adjustment with this filter was successfully created");
            setVisible(false);
        }
    };

    const handleError = () => {
        loadAdjustments();
        setIsLoading(false);
    };

    return (
        <>
            <Button className="mx-1" icon="icon-plus-fill" compact onClick={() => setVisible(true)}>
                {tr("add_adjustment")}
            </Button>
            <Modal
                visible={visible}
                title={tr("add_adjustment")}
                modalStyle={{width: "30%", minWidth: "400px"}}
                onCancel={() => setVisible(false)}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <div className="mt-2 d-flex">
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} kind="primary" loading={isLoading}>
                            {trg("next")}
                        </Button>
                    </div>
                }
            >
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <Input
                            type="text"
                            title={trg("adjustment_name")}
                            error={errorName && errorName.length > 0}
                            errorNode={errorName}
                            required={true}
                            value={name}
                            onChange={(v) => {
                                setName(stripHtml(v));
                                setErrorName("");
                            }}
                        />
                        {props.filter && (
                            <div>
                                <div className="clearfix">&nbsp;</div>
                                <Input
                                    type="text"
                                    title={trg("filter_name")}
                                    error={errorName && errorName.length > 0}
                                    errorNode={errorName}
                                    required={true}
                                    value={filterName}
                                    onChange={(v) => {
                                        setFilterName(stripHtml(v));
                                        setErrorName("");
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AdjustmentCreateModal;
