import React, {useCallback, useEffect, useState, useMemo} from "react";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import PagePanel from "../../misc/PagePanel";
import {Table, Column} from "@appkit4/react-components/table";
import {useTr} from "../../utils/trUtil";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import "./TrialBalance.scss";
import {Amount, Category, Coefficient, Comment, DeferredTaxClassification, Review} from "../../misc/AdjustmentColumns";
import {zeroToString} from "../../utils/zeroToString";
import {Button, Input, Switch, List, ListItem} from "@appkit4/react-components";
import {useNavigate} from "react-router-dom";
import ClassNames from "classnames";
import LoadingContainer, {CircularWidth} from "../../misc/LoadingContainer";

declare type PropTypes = {};
const ReviewBase: React.FC<PropTypes> = (props: PropTypes) => {
    const trh = useTr("header");
    const trg = useTr("global");
    const trtf = useTr("tax_flows");

    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const adjustments = useGlobalStore((s) => s.currentCalculationAdjustments);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);
    const navigate = useNavigate();
    let adjustmentsAreLoading = useGlobalStore((s) => s.adjustmentsAreLoading);

    const initialData = O.isSome(adjustments)
        ? zeroToString(adjustments.value.filter((item) => item.type !== null))
        : [];

    const [data, setData] = useState(initialData);
    const [filterName, setFilterName] = useState<string>("");
    const [filterType, setFilterType] = useState<string>("");
    const [filterCategory, setFilterCategory] = useState<string>("");
    const [filterTaxClass, setFilterTaxClass] = useState<string>("");
    const [filterComment, setFilterComment] = useState<string>("");
    const [filterAmountMin, setFilterAmountMin] = useState<string>("");
    const [filterCoefficientMin, setFilterCoefficientMin] = useState<string>("");
    const [filterAmountMax, setFilterAmountMax] = useState<string>("");
    const [filterCoefficientMax, setFilterCoefficientMax] = useState<string>("");
    const [filterReviewStatus, setFilterReviewStatus] = useState<string>("all");
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [allowedSorting, setAllowedSorting] = React.useState<boolean>(false);
    const [sortedIds, setSortedIds] = React.useState<string[]>();
    const [selectedId, setSelectedId] = React.useState("");

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    useEffect(() => {
        if (O.isSome(adjustments)) {
            setData(
                zeroToString(
                    adjustments.value.filter((item) => {
                        const amountMin = parseFloat(filterAmountMin);
                        const amountMax = parseFloat(filterAmountMax);
                        const coefficientMin = parseFloat(filterCoefficientMin);
                        const coefficientMax = parseFloat(filterCoefficientMax);
                        return (
                            (!filterName || item.name.toLowerCase().includes(filterName.toLowerCase())) &&
                            (!filterType || item.type.toLowerCase().includes(filterType.toLowerCase())) &&
                            (!filterCategory ||
                                item.category?.name.toLowerCase().includes(filterCategory.toLowerCase())) &&
                            (!filterTaxClass ||
                                item.deferred_tax_classification
                                    ?.toLowerCase()
                                    .includes(filterTaxClass.toLowerCase())) &&
                            (!filterComment ||
                                item.comment_official?.toLowerCase().includes(filterComment.toLowerCase())) &&
                            (isNaN(amountMin) || item.amount! >= amountMin) &&
                            (isNaN(amountMax) || item.amount! <= amountMax) &&
                            (isNaN(coefficientMin) || item.coefficient! >= coefficientMin) &&
                            (isNaN(coefficientMax) || item.coefficient! <= coefficientMax) &&
                            (filterReviewStatus === "" ||
                                filterReviewStatus === "all" ||
                                item.review_status === filterReviewStatus)
                        );
                    })
                )
            );
        } else {
            setData([]);
        }
    }, [
        filterName,
        filterType,
        filterCategory,
        filterTaxClass,
        filterComment,
        filterAmountMin,
        filterAmountMax,
        filterCoefficientMin,
        filterCoefficientMax,
        filterReviewStatus,
        adjustments,
    ]);

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterReviewStatus(event.target.value);
    };

    React.useEffect(() => {
        if (sortedIds && allowedSorting && O.isSome(adjustments) && O.isSome(calculation) && O.isSome(entity)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/prioritize_based_on_list/`,
                "patch",
                sortedIds
            )
                .then((r) => r.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    }, [sortedIds, fetchApi, addErrorNotification, allowedSorting]);

    const handleKeyDown = (item: any, event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === " ") {
            setSelectedId(item.id);
        }
    };

    const renderItem = useCallback(
        (item: any, index: number, isDragging?: boolean, iskeyBoardDragging?: boolean) => {
            const classes = ClassNames({
                selected: item.id === selectedId,
            });

            return (
                <ListItem
                    item={item}
                    index={index}
                    aria-selected={parseInt(item.id) === parseInt(selectedId)}
                    onClick={() => {
                        setSelectedId(item.id + "");
                    }}
                    className={classes}
                    onKeyDown={(e) => handleKeyDown(item, e)}
                    divider={false}
                    style={{width: "100%"}}
                >
                    <div className="ap-list-item-draggable">
                        <span
                            className={`Appkit4-icon ${
                                isDragging && iskeyBoardDragging ? "icon-elevator-outline" : "icon-menu-outline"
                            }`}
                        ></span>
                        <div className="ap-list-item-draggable-box">
                            <div className="ap-list-item-draggable-body">
                                <span className="primary-text">
                                    {item.id} - {item.name}
                                </span>

                                <span className="secondary-text">
                                    &nbsp;|&nbsp;
                                    <i>
                                        <strong>{item.type}</strong>
                                    </i>
                                </span>
                            </div>
                            <div className="ap-list-item-draggable-body">{item.description}</div>
                        </div>
                    </div>
                </ListItem>
            );
        },
        [selectedId]
    );

    const handleOnDragEnd = useCallback(() => {
        setTimeout(() => {
            let allIds: string[] = [];
            const steps = [1, 2, 3, 4];
            steps.forEach((step) => {
                const el = document.getElementById(`draggable-question-list-step-${step}`);
                if (el) {
                    for (const child of el.children) {
                        for (const ch of child.children) {
                            let val = ch.getAttribute("data-rbd-draggable-id");
                            if (typeof val === "string") allIds.push(val);
                        }
                    }
                }
            });
            setSortedIds(allIds);
        }, 1000);
    }, []);

    const sorting = useMemo(() => {
        const steps = [1, 2, 3, 4];
        const sortedData = steps.map((step) =>
            data.filter((item) => {
                const calculationStep = item?.category?.calculation_step;
                return calculationStep === step || (calculationStep == null && step === 4);
            })
        );

        return (
            <div>
                {sortedData.map((items, index) => (
                    <List
                        key={index}
                        id={`draggable-question-list-step-${index + 1}`}
                        draggable
                        data={items.map((i) => ({...i, id: i.id + ""}))}
                        itemKey="id"
                        bordered={true}
                        renderItem={renderItem}
                        width={"100%"}
                        style={{display: "inline-block", padding: 8, marginBottom: 10}}
                        onDragEnd={handleOnDragEnd}
                    />
                ))}
            </div>
        );
    }, [data, renderItem, handleOnDragEnd]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={trh("ReviewLabel")}
                    buttons={
                        <div className="d-flex justify-content-between">
                            <Switch
                                showIndicator
                                className="float-end me-2 mt-1"
                                checked={allowedSorting}
                                onChange={(v) => {
                                    setAllowedSorting(v);
                                    if (v === false) {
                                        loadAdjustments();
                                    }
                                }}
                            >
                                {allowedSorting ? trg("sorting_allowed") : trg("allow_sorting")}
                            </Switch>
                            <Button
                                style={{
                                    display: filterVisible && !allowedSorting ? "block" : "none",
                                    marginRight: "2px",
                                }}
                                kind="text"
                                onClick={() => {
                                    setFilterName("");
                                    setFilterType("");
                                    setFilterCategory("");
                                    setFilterTaxClass("");
                                    setFilterComment("");
                                    setFilterAmountMax("");
                                    setFilterAmountMin("");
                                    setFilterCoefficientMax("");
                                    setFilterCoefficientMin("");
                                    setFilterReviewStatus("all");
                                }}
                            >
                                {trg("reset_filter")}
                            </Button>
                            <Button
                                style={{float: "right"}}
                                onClick={() => {
                                    setFilterVisible(!filterVisible);
                                }}
                                disabled={allowedSorting}
                            >
                                {trg("filter")}
                            </Button>
                        </div>
                    }
                >
                    {filterVisible && !allowedSorting && <div className="clearfix">&nbsp;</div>}
                    {adjustmentsAreLoading && <LoadingContainer circularWidthOverride={CircularWidth.small} />}
                    {filterVisible && !allowedSorting && (
                        <div className="container-fluid" style={{display: filterVisible ? "block" : "none"}}>
                            <div className="row">
                                <div className="col">
                                    <label>{trg("Name")}</label>
                                    <Input
                                        size="small"
                                        type={"text"}
                                        hideTitleOnInput={true}
                                        value={filterName}
                                        onChange={(v: string) => setFilterName(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Type")}</label>
                                    <Input
                                        size="small"
                                        type={"text"}
                                        hideTitleOnInput={true}
                                        value={filterType}
                                        onChange={(v: string) => setFilterType(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Category")}</label>
                                    <Input
                                        size="small"
                                        type={"text"}
                                        hideTitleOnInput={true}
                                        value={filterCategory}
                                        onChange={(v: string) => setFilterCategory(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Tax Classification")}</label>
                                    <Input
                                        size="small"
                                        type={"text"}
                                        hideTitleOnInput={true}
                                        value={filterTaxClass}
                                        onChange={(v: string) => setFilterTaxClass(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Comment")}</label>
                                    <Input
                                        size="small"
                                        type={"text"}
                                        hideTitleOnInput={true}
                                        value={filterComment}
                                        onChange={(v: string) => setFilterComment(v)}
                                    ></Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>{trg("Amount min")}</label>
                                    <Input
                                        size="small"
                                        type={"number"}
                                        hideTitleOnInput={true}
                                        value={filterAmountMin}
                                        onChange={(v) => setFilterAmountMin(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Amount max")}</label>
                                    <Input
                                        size="small"
                                        type={"number"}
                                        hideTitleOnInput={true}
                                        value={filterAmountMax}
                                        onChange={(v) => setFilterAmountMax(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Coefficient min")}</label>
                                    <Input
                                        size="small"
                                        type={"number"}
                                        hideTitleOnInput={true}
                                        value={filterCoefficientMin}
                                        onChange={(v) => setFilterCoefficientMin(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label>{trg("Coefficient max")}</label>
                                    <Input
                                        size="small"
                                        type={"number"}
                                        hideTitleOnInput={true}
                                        value={filterCoefficientMax}
                                        onChange={(v) => setFilterCoefficientMax(v)}
                                    ></Input>
                                </div>
                                <div className="col">
                                    <label htmlFor="reviews">{trg("Review status")}</label>
                                    <br />
                                    <select
                                        name="reviews"
                                        id="reviews"
                                        onChange={(v) => handleStatusChange(v)}
                                        defaultValue={filterReviewStatus}
                                    >
                                        <option value="all">{trg("all")}</option>
                                        <option value="for_review">{trg("for_review")}</option>
                                        <option value="for_second_review">{trg("for_second_review")}</option>
                                        <option value="in_progress">{trg("in_progress")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                    {filterVisible && !allowedSorting && <div className="clearfix">&nbsp;</div>}
                    {O.isSome(adjustments) && O.isSome(calculation) && O.isSome(entity) && !allowedSorting ? (
                        <Table
                            className="table-adustments"
                            originalData={data}
                            hasTitle={true}
                            striped={true}
                            condensed={true}
                        >
                            <Column
                                style={{width: "12%"}}
                                sortKey="name"
                                renderCell={(row, field) => (
                                    <Button
                                        kind="text"
                                        onClick={() => {
                                            navigate(
                                                `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/adjustment/${row.id}?tab_index=0`
                                            );
                                        }}
                                    >
                                        {row.name}
                                    </Button>
                                )}
                            >
                                {trh("name")}
                            </Column>
                            <Column
                                style={{width: "12%"}}
                                sortKey="type"
                                renderCell={(row, field) => trtf(`${row.type}_label`)}
                            >
                                {trg("type")}
                            </Column>
                            <Column
                                style={{width: "12%"}}
                                sortKey="category"
                                renderCell={(row, field) => <Category row={row} field={field} />}
                            >
                                {trh("category")}
                            </Column>
                            <Column
                                style={{width: "12%"}}
                                sortKey="deferred_tax_classification"
                                renderCell={(row, field) => <DeferredTaxClassification row={row} field={field} />}
                            >
                                {trh("tax_classification")}
                            </Column>
                            <Column
                                style={{width: "20%"}}
                                field="comment_official"
                                renderCell={(row, field) => <Comment row={row} field={field} />}
                            >
                                {trh("comment")}
                            </Column>
                            <Column
                                style={{width: "12%"}}
                                sortKey="amount"
                                renderCell={(row, field) => (
                                    <Amount row={row} field={field} amount={row.final_amount} />
                                )}
                            >
                                {trh("amount")}
                            </Column>
                            <Column
                                style={{width: "6%"}}
                                sortKey="coefficient"
                                renderCell={(row, field) => <Coefficient row={row} field={field} />}
                            >
                                {trh("coefficient")}
                            </Column>
                            <Column
                                style={{width: "14%"}}
                                sortKey="review_status"
                                renderCell={(row, field) => <Review row={row} field={field} />}
                            >
                                {trh("review_status")}
                            </Column>
                        </Table>
                    ) : (
                        sorting
                    )}
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default ReviewBase;
