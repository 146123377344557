import {useEffect, useState} from "react";
import {useTr} from "../utils/trUtil";
import {Badge, Select} from "@appkit4/react-components";
import {Load} from "../types/Load";
import objectFlip from "../utils/objectFlip";

declare type PropTypes = {
    load?: Load;
    type: "journal" | "trial_balance";
};

declare type ResultType = (props: PropTypes) => {
    mappingTable: React.ReactNode;
    sheetSelector: React.ReactNode;
    columns: string[];
    mappedColumns: object;
    defaultColumns: object;
    sheet?: string;
    checkTriedSending: () => boolean;
};

const validators = {
    trial_balance: {
        requiredFields: ["account", "account_name", "opening_balance", "closing_balance", "debit_credit_net_movement"],
        customBadgeCondition: (key, mappedColumns) =>
            key === "debit_credit_net_movement"
                ? (mappedColumns["debit"] !== null && mappedColumns["credit"] !== null) ||
                  mappedColumns["net_movement"] !== null
                : mappedColumns[key] !== null,
        checkTriedValidationCondition: (mappedColumns) =>
            mappedColumns["account"] === null ||
            mappedColumns["account_name"] === null ||
            mappedColumns["opening_balance"] === null ||
            mappedColumns["closing_balance"] === null ||
            !(
                (mappedColumns["debit"] !== null && mappedColumns["credit"] !== null) ||
                mappedColumns["net_movement"] !== null
            ),
    },
    journal: {
        requiredFields: ["account", "description", "debit_credit_net_movement"],
        customBadgeCondition: (key, mappedColumns) =>
            key === "debit_credit_net_movement"
                ? (mappedColumns["debit_amount"] !== null && mappedColumns["credit_amount"] !== null) ||
                  mappedColumns["amount"] !== null
                : mappedColumns[key] !== null,
        checkTriedValidationCondition: (mappedColumns) =>
            mappedColumns["account"] === null ||
            mappedColumns["description"] === null ||
            !(
                (mappedColumns["debit_amount"] !== null && mappedColumns["credit_amount"] !== null) ||
                mappedColumns["amount"] !== null
            ),
    },
};

const useColumnMapping: ResultType = ({load, type}: PropTypes) => {
    const tr = useTr("calculation_base");
    const tr2 = useTr("calculation_import");
    const trg = useTr("global");

    const [sheetNames, setSheetNames] = useState<string[]>([]);
    const [sheet, setSheet] = useState<string>();
    const [columns, setColumns] = useState<string[]>([]);
    const [defaultColumns, setDefaultColumns] = useState<object>({}); // Nase stlpce, ktore mozeme mapovat
    const [mappedColumns, setMappedColumns] = useState<object>({}); //* Nase stlpce (key): Nazov stlpca z excelu (value), default value == null - vstupuju do body request
    const [data, setData] = useState<any[]>([]);
    const [triedSending, setTriedSending] = useState<boolean>(false);

    useEffect(() => {
        if (load && Object.keys(mappedColumns).length === 0) {
            setSheet(load.sheet_name);
            setSheetNames(Object.entries(load.initial_data.sheet_names).map(([index]) => index));
            setMappedColumns(load.mapped_columns);
            setDefaultColumns({"": "", ...load.default_columns});
        }
    }, [load, mappedColumns]);

    useEffect(() => {
        if (load && sheet) {
            setData(load.initial_data.sheet_names[sheet].data);
            let pom = Object.entries(load.initial_data.sheet_names).filter(([index, data]) => index === sheet);
            let d = pom.length > 0 ? pom[0][1] : undefined;
            if (d) {
                let cols = d.columns.map((column: string, index: number) => column);
                setColumns(cols);
            }
        }
    }, [load, sheet]);

    useEffect(() => {
        if (load && load.sheet_name && sheetNames.includes(load.sheet_name)) {
            setSheet(load.sheet_name);
        } else if (sheetNames && sheetNames.length > 0) {
            setSheet(sheetNames[0]);
        } else {
            setSheet(undefined);
        }
    }, [sheetNames, load]);

    /**
     *
     * @param system_column: string - System column name
     * @param user_column: string - User column name from excel
     */
    const onSelectColumn = ([system_column, user_column]) => {
        setMappedColumns((s) => {
            let new_s = {...s};
            Object.entries(new_s).forEach(([sys_col, usr_col], i) => {
                if (usr_col === user_column) {
                    new_s[sys_col] = null;
                }
            });
            new_s[system_column] = user_column;
            return new_s;
        });
    };

    const checkTriedSending = () => {
        if (mappedColumns === undefined) {
            return false;
        }
        setTriedSending(true);
        return validators[type].checkTriedValidationCondition(mappedColumns);
    };

    const sheetSelector = (
        <Select
            data={sheetNames}
            value={sheet}
            placeholder={tr2("select_sheet")}
            dropdownMatchWidth={false}
            onSelect={(v: any) => setSheet(v)}
        ></Select>
    );

    const mappingTable = (
        <>
            {mappedColumns && (
                <>
                    <label>{trg("required_fields")}</label>
                    <div className="badgeRequired mb-2 mt-1">
                        {validators[type].requiredFields.map((key) => (
                            <CustomBadge
                                key={key}
                                label={tr(key)}
                                valid={validators[type].customBadgeCondition(key, mappedColumns)}
                                triedSending={triedSending}
                            />
                        ))}
                    </div>
                </>
            )}
            <div className="table-responsive" style={{height: "500px"}}>
                <table className="table table-striped table-condensed small table-bordered table-hover">
                    <thead>
                        <tr>
                            {columns.map((col, idx) => (
                                <th key={idx}>
                                    <div style={{width: "150px"}}>{col}</div>
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {columns.map((col) => {
                                let flippedMappedColumns = objectFlip(mappedColumns);
                                return (
                                    <td key={col}>
                                        <Select
                                            placeholder=""
                                            data={Object.entries(defaultColumns).map((column, index) => {
                                                return {
                                                    label:
                                                        column[0].length > 0 && column[0] !== "" ? tr(column[0]) : "",
                                                    value: column[0],
                                                };
                                            })}
                                            searchable={true}
                                            dropdownMatchWidth={false}
                                            hideTitleOnInput={true}
                                            value={
                                                typeof flippedMappedColumns[col] !== "undefined"
                                                    ? flippedMappedColumns[col]
                                                    : ""
                                            }
                                            onSelect={(v) => onSelectColumn([v, col])}
                                        ></Select>
                                    </td>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row: any, idx: number) => {
                            return (
                                <tr key={idx} style={{height: "10px", padding: "0"}}>
                                    {row.map((v: any, idx: number) => {
                                        return (
                                            <td key={idx} style={{height: "10px", padding: "5px"}}>
                                                {v}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-md-12">
                        <span className="text-muted">{trg("this_is_only_a_preview")}</span>
                    </div>
                </div>
            </div>
        </>
    );
    return {
        mappingTable: mappingTable,
        sheetSelector: sheetSelector,
        mappedColumns: mappedColumns,
        defaultColumns: defaultColumns,
        columns: columns,
        sheet: sheet,
        checkTriedSending: checkTriedSending,
    };
};

export default useColumnMapping;

export const CustomBadge = ({label, valid, triedSending}) => {
    return (
        <Badge
            className="m-0 me-2"
            value={
                <div>
                    {label}
                    {valid ? "" : "*"}
                    {valid && (
                        <small
                            style={{lineHeight: "inherit"}}
                            className="Appkit4-icon icon-check-hook-outline ap-font-24 p-0 m-0"
                        ></small>
                    )}
                </div>
            }
            size={"lg"}
            type={!valid ? (triedSending ? "warning-outlined" : "info-outlined") : "success-outlined"}
        />
    );
};
