import React from "react";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Modal, Button, TextArea, Input} from "@appkit4/react-components";
import {EntityInfo} from "../../types/EntityInfo";
import {useTr} from "../../utils/trUtil";
import {triggerAsyncId} from "async_hooks";
import {stripHtml} from "../../utils/stripHtml";

declare type PropTypes = {
    entityInfo?: EntityInfo | null;
    hideButton?: boolean;
    showModal: boolean;
    onModalClose?: () => void;
};

const EntityInfoForm: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");

    const [visible, setVisible] = React.useState<boolean>(props.showModal);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");

    const onSubmitHandler = () => {
        let data = {
            info_id: props.entityInfo ? props.entityInfo.id : null,
            name: name,
            description: description,
        };
        if (O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/info/`, props.entityInfo ? "patch" : "post", data)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    if (props.onModalClose) props.onModalClose();
                });
        }
    };

    const onDeleteHandler = () => {
        if (props.entityInfo) {
            let data = {
                info_id: props.entityInfo.id,
            };
            if (O.isSome(entity)) {
                fetchApi(`/entity/${entity.value.hash}/info/`, "delete", data)
                    .then((res) => res.json())
                    .then((data) => {
                        if (typeof data.error !== "undefined") {
                            addErrorNotification(data);
                        }
                        if (props.onModalClose) props.onModalClose();
                    });
            }
        }
    };

    React.useEffect(() => {
        if (props.entityInfo) {
            setName(props.entityInfo.name);
            setDescription(props.entityInfo.description);
        } else {
            setName("");
            setDescription("");
        }
        if (typeof props.showModal !== "undefined") setVisible(props.showModal);
    }, [props]);

    return (
        <Modal
            className="adjustment-form-modal"
            visible={visible}
            title={trg("edit_entity_info")}
            modalStyle={{width: "50%", minWidth: "400px"}}
            onCancel={() => {
                if (props.onModalClose) props.onModalClose();
            }}
            icons={""}
            footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
            header={""}
            footer={
                <div className="mt-3 d-flex">
                    <div className="flex-grow-1">
                        {props.entityInfo && (
                            <span
                                onClick={onDeleteHandler}
                                className="Appkit4-icon icon-delete-fill text-gray mt-1"
                                style={{cursor: "pointer"}}
                                title={trg("delete_this_entity_info")}
                            ></span>
                        )}
                    </div>
                    <Button onClick={props.onModalClose} kind="tertiary" style={{marginRight: "10px"}}>
                        {trg("cancel")}
                    </Button>
                    <Button onClick={onSubmitHandler} kind="primary">
                        {trg("save")}
                    </Button>
                </div>
            }
        >
            <div className="row">
                <div className="col-md-12 mt-3">
                    <Input type="text" title={trg("name_info")} value={name} onChange={(v) => setName(stripHtml(v))} />
                </div>
                <div className="col-md-12 mt-3">
                    <TextArea
                        maxLength={10000}
                        title={trg("description")}
                        value={description}
                        onChange={(v) => setDescription(stripHtml(v))}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EntityInfoForm;
