import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {CategoryGroup} from "../../../../types/CategoryGroup";
import {nonEmptyArray} from "fp-ts";
import {TaxTreatmentType} from "../../../../types/TaxTreatmentTypes";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    taxTreatmentType?: TaxTreatmentType;
    onSaveHandler?: () => void;
};

const TaxTreatmentTypeForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<TaxTreatmentType | undefined>(
        props.taxTreatmentType ? props.taxTreatmentType : undefined
    );
    const trg = useTr("global");

    const [data, setData] = React.useState<TaxTreatmentType>({
        id: props.taxTreatmentType ? props.taxTreatmentType.id : 0,
        type: props.taxTreatmentType ? props.taxTreatmentType.type : "",
        type_sk: props.taxTreatmentType ? props.taxTreatmentType.type_sk : "",
        adjustment_type: props.taxTreatmentType ? props.taxTreatmentType.adjustment_type : "",
        tag_id: props.taxTreatmentType ? props.taxTreatmentType.tag_id : 0,
        creation_category_id: props.taxTreatmentType ? props.taxTreatmentType.creation_category_id : 0,
        release_category_id: props.taxTreatmentType ? props.taxTreatmentType.release_category_id : 0,
        timing: props.taxTreatmentType ? props.taxTreatmentType.timing : "",
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.taxTreatmentType
            ? `/settings/_/fixtures/taxtreatmenttype/${props.taxTreatmentType.id}/`
            : `/settings/_/fixtures/taxtreatmenttype/`;

        fetchApi(endpoint, props.taxTreatmentType ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRulesetForm(props.taxTreatmentType);
        setData({
            id: props.taxTreatmentType ? props.taxTreatmentType.id : 0,
            type: props.taxTreatmentType ? props.taxTreatmentType.type : "",
            type_sk: props.taxTreatmentType ? props.taxTreatmentType.type_sk : "",
            adjustment_type: props.taxTreatmentType ? props.taxTreatmentType.adjustment_type : "",
            tag_id: props.taxTreatmentType ? props.taxTreatmentType.tag_id : 0,
            creation_category_id: props.taxTreatmentType ? props.taxTreatmentType.creation_category_id : 0,
            release_category_id: props.taxTreatmentType ? props.taxTreatmentType.release_category_id : 0,
            timing: props.taxTreatmentType ? props.taxTreatmentType.timing : "",
        });
    }, [props.taxTreatmentType]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Tax Treatment types Form ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={trg("ruleset_form")}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "290px"}}>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("type")}
                            value={data.type}
                            required
                            onChange={(val: string) => setData((s) => ({...s, type: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("type_sk")}
                            value={data.type_sk}
                            required
                            onChange={(val: string) => setData((s) => ({...s, type_sk: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={[
                                "null",
                                "journal",
                                "manual",
                                "tacable_result",
                                "fixed_assets",
                                "provisions",
                                "valuation_allowances",
                                "accounting_result",
                                "cash_basis",
                                "helpful",
                            ]}
                            value={data.adjustment_type}
                            searchable={true}
                            required
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    adjustment_type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("tag_id")}
                            value={data.tag_id}
                            required
                            onChange={(val: number) => setData((s) => ({...s, tag_id: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("creation_category_id")}
                            value={data.creation_category_id}
                            required
                            onChange={(val: number) => setData((s) => ({...s, creation_category_id: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("release_category_id")}
                            value={data.release_category_id}
                            required
                            onChange={(val: number) => setData((s) => ({...s, release_category_id: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("timing")}
                            value={data.timing}
                            required
                            onChange={(val: string) => setData((s) => ({...s, timing: val}))}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TaxTreatmentTypeForm;
