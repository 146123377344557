import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Button, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    rulesetForm?: RulesetForm;
    onSaveHandler?: () => void;
};

const RulesetFormForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<RulesetForm | undefined>(
        props.rulesetForm ? props.rulesetForm : undefined
    );
    const trg = useTr("global");

    const {init, rulesets, forms, rulesetsAreLoaded, formsAreLoaded, loadRulesetForms} = useFixtureStore((s) => ({
        init: s.init,
        rulesets: s.rulesets,
        forms: s.forms,
        rulesetsAreLoaded: s.rulesetsAreLoaded,
        formsAreLoaded: s.formsAreLoaded,
        loadRulesetForms: s.loadRulesetForms,
    }));

    const [data, setData] = React.useState<RulesetForm>({
        id: props.rulesetForm ? props.rulesetForm.id : 0,
        ruleset: props.rulesetForm ? props.rulesetForm.ruleset : 0,
        form: props.rulesetForm ? props.rulesetForm.form : 0,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.rulesetForm
            ? `/settings/_/fixtures/rulesetform/${props.rulesetForm.id}/`
            : `/settings/_/fixtures/rulesetform/`;

        fetchApi(endpoint, props.rulesetForm ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    loadRulesetForms();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRulesetForm(props.rulesetForm);
        setData({
            id: props.rulesetForm ? props.rulesetForm.id : 0,
            ruleset: props.rulesetForm ? props.rulesetForm.ruleset : 0,
            form: props.rulesetForm ? props.rulesetForm.form : 0,
        });
    }, [props.rulesetForm]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Ruleset Form ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row">
                    <div className="col-md-6 mt-3" style={{height: "23.75rem"}}>
                        <Select
                            data={rulesets.map((ruleset) => ({
                                key: ruleset.id,
                                label: ruleset.name,
                                value: ruleset.id,
                            }))}
                            style={{width: "100%"}}
                            placeholder={trg("ruleset")}
                            value={data.ruleset}
                            searchable={true}
                            onSelect={(val: SelectValue) => setData((s) => ({...s, ruleset: parseInt(val + "")}))}
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={forms.map((form) => ({
                                key: form.id,
                                label: form.name,
                                value: form.id,
                            }))}
                            value={data.form}
                            placeholder={trg("tax_form")}
                            searchable={true}
                            onSelect={(val) => setData((s) => ({...s, form: parseInt(val + "")}))}
                        ></Select>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RulesetFormForm;
