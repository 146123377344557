import React from "react";
import {CalendarPicker} from "@appkit4/react-components/datepicker";

import i18n from "../../../utils/i18n";
import {InputPropTypes} from "../CalculationFormElement";
import isValidDate from "../../../utils/isValidDate";

const InputDate: React.FC<InputPropTypes> = (props) => {
    const [format, setFormat] = React.useState<string>();
    const [validDate, setValidDate] = React.useState<Date | undefined>(
        isValidDate(new Date(props.element.value)) ? new Date(props.element.value) : undefined
    );

    React.useEffect(() => {
        let isValid = isValidDate(new Date(props.element.value));
        if (format && isValid) setValidDate(new Date(props.element.value));
    }, [props.element.value, format]);

    React.useEffect(() => {
        const split = props.element.data_type ? props.element.data_type.split("|") : [];
        setFormat(split.length === 2 ? split[1] : "DD.MM.YYYY");
    }, [props.element.data_type]);

    return (
        <div className="border p-0 m-2">
            <div className="d-flex">
                <div style={{width: "60%"}} className="p-2">
                    {props.element.description}
                </div>
                <div className="px-5 text-end pt-3" style={{width: "20%"}}>
                    {props.element.info}
                </div>
                {format && (
                    <CalendarPicker
                        id={`calendar-picker-${props.element.id}`}
                        style={{width: "22%"}}
                        placeholder={format}
                        format={format}
                        locale={i18n.language}
                        hideTitleOnInput={true}
                        value={validDate}
                        onChange={(v: any) => {
                            props.onChangeHandler(props.element, v);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default InputDate;
