import {Loading, LoadingProps} from "@appkit4/react-components";
import React from "react";
import Centered from "./Centered";

export enum CircularWidth {
    small = "24px",
    big = "80px",
}
interface PropTypes {
    circularWidthOverride: CircularWidth;
    loadingProps?: LoadingProps;
}
const LoadingContainer: React.FC<PropTypes> = (props: PropTypes) => {
    return (
        <Centered>
            <Loading
                loadingType="circular"
                indeterminate={true}
                circularWidth={props.circularWidthOverride}
                {...props.loadingProps}
            />
        </Centered>
    );
};
export default LoadingContainer;
