import {AllAcceptableChars} from "../Constants";

export const randomstring = (length: number) => {
    const charset = AllAcceptableChars;
    let result = "";
    const timestamp = new Date().getTime();

    for (let i = 0; i < length; i++) {
        const charIndex = (timestamp + i) % charset.length;
        result += charset[charIndex];
    }

    return result;
};
