import React from "react";

export enum PwcIconTypes {
    outline = "outline",
    fill = "fill",
}
interface PropTypes {
    iconName: string;
    iconType: PwcIconTypes;
    additionalClassNames?: string;
}
const PwcIcon: React.FC<PropTypes> = (props: PropTypes) => (
    <i className={`Appkit4-icon icon-${props.iconName}-${props.iconType} ${props.additionalClassNames ?? ""}`} />
);
export default PwcIcon;
