import React from "react";
import {useNavigate} from "react-router-dom";
import Layout from "../Layout/Layout";
import {HeaderType} from "../../types/HeaderType";
import PagePanel from "../../misc/PagePanel";
import CustomSearch from "../../misc/CustomSearch";
import {ArrowClockwise} from "react-bootstrap-icons";
import {Switch, Button} from "@appkit4/react-components";
import {Entity} from "../../types/Entity";
import {useTr} from "../../utils/trUtil";
import {isAdmin} from "../../utils/userUtil";
import * as O from "fp-ts/Option";
import "./MyClients.scss";
import DropdownOpenableItem, {ItemLevel} from "./DropdownOpenableItem";
import CalculationList from "./CalculationList";
import {useGlobalStore} from "../../stores/GlobalStore";
//import {useClientStore} from "../../stores/ClientStore";
import {RoutingTable} from "../../Constants";
import {useTranslation} from "react-i18next";

export interface IDropDownOpenableContext {
    activeIds: string[];
    shouldShowArchived: boolean;
}
export const DropDownOpenableContext = React.createContext<IDropDownOpenableContext>({
    activeIds: [],
    shouldShowArchived: false,
});

// TODO: Remember:
// - which were open/closed
// - if archived was selected
// Save it into local storage
const MyClients: React.FC = () => {
    const tr = useTr("client_overview");
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const ActivationClick = (id: string) => {
        //setActiveIds((prev) => (prev.some((s) => s === id) ? prev.filter((k) => k !== id) : [...prev, id]));
        addActiveIds(id);
    };
    const NewCalculationClick = (e: Entity) => {
        navigate(`calculation/${e.hash}/new`);
    };
    const OpenSettings = (hash: string, type: string) => {
        if (type === "client") navigate(`${RoutingTable.settings_clients}/${hash}`);
        if (type === "entity") navigate(`${RoutingTable.settings_entities}/${hash}`);
    };
    const MakeFavourite = () => {
        console.warn("TODO - should make it favourite");
    };

    //const [activeIds, setActiveIds] = React.useState<string[]>([]);
    const [shouldShowArchived, setShouldShowArchived] = React.useState<boolean>(false);

    const userInfo = useGlobalStore((s) => s.userInfo);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const getUserClaims = useGlobalStore((s) => s.getUserClaims);
    const hasUserClaim = useGlobalStore((s) => s.hasUserClaim);
    const activeIds = useGlobalStore((s) => s.activeIds);
    const removeActiveIds = useGlobalStore((s) => s.removeActiveIds);
    const addActiveIds = useGlobalStore((s) => s.addActiveIds);
    const trg = useTr("global");

    const clients =
        O.isSome(userInfo) &&
        userInfo.value.backend_info !== undefined &&
        !O.isNone(userInfo.value.backend_info.clients)
            ? userInfo.value.backend_info.clients
            : O.none;
    // TODO: Generate

    if (O.isNone(clients) || O.isNone(userInfo)) {
        return (
            <Layout headerType={HeaderType.Blank}>
                <PagePanel className="no-clients" title="My Clients">
                    <div className="clientsNotFound row justify-content-center">
                        <div className="col">
                            <span className="Appkit4-icon icon-particulates-outline ap-font-40"></span>
                            <h4>{trg("no_client_was_found")}</h4>
                            <Button onClick={() => window.location.reload()} kind="primary">
                                {trg("refresh")}
                            </Button>
                        </div>
                    </div>
                </PagePanel>
            </Layout>
        );
    } else {
        //console.log(getUserClaims());
        return (
            <Layout headerType={HeaderType.Blank}>
                <PagePanel
                    title={tr("LayoutTitle")}
                    withoutPanel={true}
                    buttons={
                        <div className="d-flex">
                            <div className="mt-2 mx-2">
                                <ArrowClockwise
                                    size={24}
                                    style={{cursor: "pointer"}}
                                    className="icon-strong-0-75 icon-primary icon-client-overview-arrow"
                                    onClick={() => loadUserInfo()}
                                />
                            </div>
                            <div className="mt-2 mx-2">
                                <Switch
                                    checked={shouldShowArchived}
                                    onChange={() => setShouldShowArchived((prev) => !prev)}
                                >
                                    <span>{tr("ArchivedLabel")}</span>
                                </Switch>
                            </div>

                            {isAdmin(userInfo) && (
                                <div className="mt-1 mx-2">
                                    <Button
                                        kind="secondary"
                                        className="d-inline mx-3 btn-sm"
                                        icon="icon-plus-outline"
                                        compact
                                        onClick={() => navigate(RoutingTable.client_create)}
                                    >
                                        {tr("new_client")}
                                    </Button>
                                </div>
                            )}
                            <div className="mx-2">
                                <CustomSearch />
                            </div>
                        </div>
                    }
                >
                    <DropDownOpenableContext.Provider
                        value={{activeIds: activeIds, shouldShowArchived: shouldShowArchived}}
                    >
                        {!O.isNone(clients) &&
                            Object.entries(clients).map((client) => {
                                let c = client[1];
                                if (!O.isNone(c.entities)) {
                                    return (
                                        <div className="row" key={c.hash}>
                                            <DropdownOpenableItem
                                                id={c.hash}
                                                title={c.name}
                                                itemLevel={ItemLevel.first}
                                                activationClick={ActivationClick}
                                                buttonText={`${c.entities.length.toString()} ${tr(
                                                    c.entities.length == 1
                                                        ? "entity_count_1"
                                                        : c.entities.length >= 2 && c.entities.length <= 4
                                                        ? "entity_count_2_4"
                                                        : "entity_count"
                                                )}`}
                                                buttonClick={() => ActivationClick(c.hash)}
                                                canShowEdit={hasUserClaim("user_client", c.hash)}
                                                editButtonClick={() => OpenSettings(c.hash, "client")}
                                                isFavourite={false}
                                                starClick={MakeFavourite}
                                                isArchived={false}
                                            >
                                                {c.entities.map((e: Entity) => {
                                                    return (
                                                        <DropdownOpenableItem
                                                            key={e.hash}
                                                            id={e.hash}
                                                            title={e.name}
                                                            itemLevel={ItemLevel.second}
                                                            activationClick={ActivationClick}
                                                            buttonText={tr("new_calculation")}
                                                            buttonClick={() => NewCalculationClick(e)}
                                                            canShowEdit={
                                                                hasUserClaim("user_entity", e.hash) ||
                                                                hasUserClaim("user_client", c.hash)
                                                            }
                                                            editButtonClick={() => OpenSettings(e.hash, "entity")}
                                                            isFavourite={e.is_favourite}
                                                            starClick={MakeFavourite}
                                                            isArchived={false}
                                                        >
                                                            <CalculationList
                                                                key={e.hash}
                                                                entity={e}
                                                                isActive={activeIds.includes(e.hash)}
                                                            />
                                                        </DropdownOpenableItem>
                                                    );
                                                })}
                                            </DropdownOpenableItem>
                                        </div>
                                    );
                                }
                            })}
                    </DropDownOpenableContext.Provider>
                </PagePanel>
            </Layout>
        );
    }
};

// <div className="row">
//     <DropdownOpenableItem
//         id="cl-Global XYZ 2"
//         title={"Global XYZ 2"}
//         itemLevel={ItemLevel.first}
//         activationClick={ActivationClick}
//         buttonText={"X Entities"}
//         buttonClick={NewCalculationClick}
//         canShowEdit={true}
//         editButtonClick={OpenSettings}
//         isFavourite={false}
//         starClick={MakeFavourite}
//         isArchived={false}
//     >
//         <DropdownOpenableItem
//             id="en-Global XYZ 2"
//             title={"Example entity 2"}
//             itemLevel={ItemLevel.second}
//             activationClick={ActivationClick}
//             buttonText={"New calculation"}
//             buttonClick={NewCalculationClick}
//             canShowEdit={true}
//             editButtonClick={OpenSettings}
//             isFavourite={true}
//             starClick={MakeFavourite}
//             isArchived={true}
//         >
//             <p>b</p>
//         </DropdownOpenableItem>
//     </DropdownOpenableItem>
// </div>;

export default MyClients;

// const ClientOverview: React.FC = () => {
//     const {t, i18n} = useTranslation();
//     const setTheme = useThemeStore((s) => s.setTheme);

//     const changeLanguage = (lng: AvailableLanguages) => {
//         i18n.changeLanguage(lng);
//     };

//     return (
//         <Layout headerType={HeaderType.Blank}>
//             <p>{t("welcome")}</p>
//             <button onClick={() => changeLanguage(AvailableLanguages.Slovak)} style={{marginRight: "20px"}}>
//                 Slovensky
//             </button>
//             <button onClick={() => changeLanguage(AvailableLanguages.English)} style={{marginRight: "20px"}}>
//                 Anglicky
//             </button>

//             <button onClick={() => setTheme(Theme.LIGHT)} style={{marginRight: "20px"}}>
//                 Light
//             </button>
//             <button onClick={() => setTheme(Theme.DARK)} style={{marginRight: "20px"}}>
//                 Dark
//             </button>
//             <button onClick={() => setTheme(Theme.ORANGE)} style={{marginRight: "20px"}}>
//                 Orange
//             </button>
//             <button onClick={() => setTheme(Theme.TEAL)} style={{marginRight: "20px"}}>
//                 Teal
//             </button>
//             <Button>lakdjsfklajsdf</Button>
//         </Layout>
//     );
// };
