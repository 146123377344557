import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {CategoryGroup} from "../../../../types/CategoryGroup";
import {nonEmptyArray} from "fp-ts";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    categoryGroup?: CategoryGroup;
    onSaveHandler?: () => void;
};

const CategoryGroupForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<CategoryGroup | undefined>(
        props.categoryGroup ? props.categoryGroup : undefined
    );

    const [data, setData] = React.useState<CategoryGroup>({
        id: props.categoryGroup ? props.categoryGroup.id : 0,
        name: props.categoryGroup ? props.categoryGroup.name : "",
        name_sk: props.categoryGroup ? props.categoryGroup.name_sk : "",
        adjustment_type: props.categoryGroup ? props.categoryGroup.adjustment_type : null,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.categoryGroup
            ? `/settings/_/fixtures/categorygroup/${props.categoryGroup.id}/`
            : `/settings/_/fixtures/categorygroup/`;

        fetchApi(endpoint, props.categoryGroup ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    const trg = useTr("global");

    React.useEffect(() => {
        setRulesetForm(props.categoryGroup);
        setData({
            id: props.categoryGroup ? props.categoryGroup.id : 0,
            name: props.categoryGroup ? props.categoryGroup.name : "",
            name_sk: props.categoryGroup ? props.categoryGroup.name_sk : "",
            adjustment_type: props.categoryGroup ? props.categoryGroup.adjustment_type : null,
        });
    }, [props.categoryGroup]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Category Form ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "290px"}}>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name")}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name_sk")}
                            value={data.name_sk}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name_sk: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={[
                                "null",
                                "journal",
                                "manual",
                                "tacable_result",
                                "fixed_assets",
                                "provisions",
                                "valuation_allowances",
                                "accounting_result",
                                "cash_basis",
                                "helpful",
                            ]}
                            value={data.adjustment_type}
                            searchable={true}
                            placeholder={trg("adjustment_type")}
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    adjustment_type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CategoryGroupForm;
