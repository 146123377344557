import React from "react";
import {Button, Input, Select, Switch} from "@appkit4/react-components";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {useTr} from "../../utils/trUtil";

declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    afterSaveHandler: () => void;
};

const ImportTrialBalanceAdjustmentForm: React.FC<PropTypes> = (props: PropTypes) => {
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [accountNumber, setAccountNumber] = React.useState<string>();
    const [accountName, setAccountName] = React.useState<string>();
    const [description, setDescription] = React.useState<string>();
    const [amount, setAmount] = React.useState<string>();
    const [debit, setDebit] = React.useState<string>();
    const [credit, setCredit] = React.useState<string>();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [newAccount, setNewAccount] = React.useState<boolean>(false);
    const [availableAccounts, setAvailableAccounts] = React.useState<any[]>([]);

    const [accountsAreLoaded, setAccountsAreLoaded] = React.useState<boolean>(false);
    const [buttonIsLoading, setButtonIsLoading] = React.useState<boolean>(false);
    const tr = useTr("Import_trial_balance_adjustmentForm");
    const trg = useTr("global");

    React.useEffect(() => {
        if (accountsAreLoaded === false)
            fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/available_accounts/`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : setAvailableAccounts(data);
                    setAccountsAreLoaded(true);
                });
    }, [accountsAreLoaded]);

    const customAmountParseFloat = (e) => {
        let value = e.target.value;
        let float = parseFloat(value);
        let fixedAmount = isNaN(float) ? "0.00" : float.toFixed(2);
        setAmount(fixedAmount);
    };

    React.useEffect(() => {
        if (typeof amount !== "undefined") {
            if (parseFloat(amount) < 0) {
                setCredit((parseFloat(amount) * -1).toFixed(2));
                setDebit("0.00");
            } else if (parseFloat(amount) > 0) {
                setDebit(amount);
                setCredit("0.00");
            } else {
                setDebit("0.00");
                setCredit("0.00");
            }
        }
    }, [amount]);

    const onSaveHandler = () => {
        if (
            accountNumber &&
            accountNumber !== "" &&
            (!newAccount || (accountName && accountName !== "")) &&
            description &&
            description !== "" &&
            parseFloat(amount ? amount : "0") !== 0
        ) {
            setButtonIsLoading(true);
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance/adjustment/`,
                "post",
                {
                    account_number: accountNumber,
                    account_name: accountName,
                    amount: amount,
                    credit: credit,
                    debit: debit,
                    description: description,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setAccountNumber("");
                        setAccountName("");
                        setDescription("");
                        setAmount("");
                        setCredit("");
                        setDebit("");
                        setButtonIsLoading(false);
                        props.afterSaveHandler();
                    }
                });
            setErrorMessage("");
        } else {
            setErrorMessage("Please fill all fields");
        }
    };

    return (
        <>
            <div className="row">
                <div className="col">
                    <Switch
                        checked={newAccount}
                        onChange={() => setNewAccount(!newAccount)}
                        className="m-2"
                        large={true}
                    >
                        <div className="text-muted small" style={{width: "120px"}}>
                            {tr("add_new_account")}
                        </div>
                    </Switch>
                </div>
                {newAccount && (
                    <>
                        <div className="col-md-2 mr-2" style={{minWidth: "120px"}}>
                            <Input
                                type="text"
                                value={accountNumber}
                                title={tr("account_number")}
                                onChange={(v) => setAccountNumber(v)}
                            />
                        </div>
                        <div className="col-md-2 mr-2" style={{minWidth: "200px"}}>
                            <Input
                                type="text"
                                value={accountName}
                                title={trg("account_name")}
                                onChange={(v) => setAccountName(v)}
                            />
                        </div>
                    </>
                )}
                {!newAccount && (
                    <div className="col-md-4 mr-2">
                        <Select
                            data={availableAccounts.map(({number, name}) => {
                                return {
                                    value: number,
                                    label: `${number} - ${name}`,
                                    key: `${number} - ${name}`,
                                };
                            })}
                            value={accountNumber}
                            searchable={true}
                            dropdownMatchWidth={false}
                            placeholder={tr("account_number")}
                            onSelect={(val: any) => setAccountNumber(val)}
                        ></Select>
                    </div>
                )}
                <div className="col-md-4 mr-2">
                    <Input
                        type="text"
                        value={description}
                        title={tr("description")}
                        onChange={(v) => setDescription(v)}
                    />
                </div>
                <div className="col me-auto">
                    <Input
                        type="text"
                        value={amount}
                        title={tr("amount")}
                        onChange={(v) => setAmount(v)}
                        onBlur={(e) => {
                            customAmountParseFloat(e);
                        }}
                    />
                </div>
                <div className="col mr-2 d-none">
                    <Input type="text" value={debit} title={trg("debit")} readonly onChange={(v) => setDebit(v)} />
                </div>
                <div className="col me-2 d-none">
                    <Input type="text" value={credit} title={trg("credit")} readonly onChange={(v) => setCredit(v)} />
                </div>
                <div className="col">
                    <div className="d-flex flex-row-reverse">
                        <Button
                            onClick={onSaveHandler}
                            icon="icon-plus-fill"
                            loading={buttonIsLoading}
                            kind="primary"
                            compact
                            className="mt-1"
                        >
                            <small>{tr("add_new")}</small>
                        </Button>
                    </div>
                </div>
            </div>

            {errorMessage !== "" && (
                <div className="row justify-content-end">
                    <div className="col text-end pr-2">
                        <div aria-live="polite" className="ap-field-email-validation-error">
                            {errorMessage}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImportTrialBalanceAdjustmentForm;
