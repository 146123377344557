import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import Layout from "../../Layout/Layout";
import {HeaderType} from "../../../types/HeaderType";
import {Panel} from "@appkit4/react-components/panel";
import {Button} from "@appkit4/react-components/button";
import BackButton from "../../../misc/BackButton";
import {useGlobalStore} from "../../../stores/GlobalStore";
import PageNotFound from "../../PageNotFound/PageNotFound";
import LoadingContainer, {CircularWidth} from "../../../misc/LoadingContainer";
import AdminFixtureRuleset from "./AdminFixtureRuleset";
import AdminFixtureFormRow from "./AdminFixtureFormRow";
import {ALLOWED_FIXTURES} from "../../../Constants";
import {useParams} from "react-router";
import AdminFixtureRulesetForm from "./AdminFixtureRulesetForm";
import useDownloadUrl from "../../../hooks/useDownloadUrl";
import AdminFixtureQuestionnaire from "./AdminFixtureQuestionnaire";
import AdminCategoryGroup from "./AdminFixtureCategoryGroup";
import AdminCategory from "./AdminFixtureCategory";
import AdminRulesetCategoryPairs from "./AdminRulesetCategoryPairs";
import AdminFixtureTaxTreatmentType from "./AdminFixtureTaxTreatmentType";
import AdminTagForRuleset from "./AdminTagForRuleset";
import AdminTag from "./AdminTag";
import AdminAdjustmentDefault from "./AdminAdjustmentDefault";
import AdminAdjustmentDefaultRuleset from "./AdminAdjustmentDefaultRuleset";
import {useTr} from "../../../utils/trUtil";

declare type PropTypes = {};

const AdminFixtureByType: React.FC<PropTypes> = (props: PropTypes) => {
    const params = useParams();

    const [isDumpLoading, setIsDumpLoading] = React.useState<boolean>(false);
    const [content, setContent] = React.useState(<LoadingContainer circularWidthOverride={CircularWidth.big} />);

    const [type, setType] = React.useState<string>("");
    const {renderLink, download} = useDownloadUrl();
    const trg = useTr("global");

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        let idx = ALLOWED_FIXTURES.indexOf(encodeURIComponent(params.type ? params.type : "").toLowerCase());
        setType(idx === -1 ? "" : ALLOWED_FIXTURES[idx]);
    }, [params]);

    const handleDownloadDump = () => {
        setIsDumpLoading(true);
        // Escape user input before using it in the download attribute
        if (type.length > 0) {
            fetchApi(`/settings/_/fixtures/${type}/dump_all/`)
                .then((res) => {
                    return res.json();
                })
                .then((response) => {
                    if (typeof response.error !== "undefined") {
                        addErrorNotification(response);
                    } else {
                        download(
                            "data:application/json;charset=utf-8," +
                                encodeURIComponent(JSON.stringify(response, undefined, 4)),
                            //`${type}.json`
                            `_all.json`
                        );
                    }

                    setIsDumpLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsDumpLoading(false);
                });
        }
    };

    React.useEffect(() => {
        if (params.type)
            switch (params.type) {
                case "ruleset":
                    setContent(<AdminFixtureRuleset />);
                    break;
                case "formrow":
                    setContent(<AdminFixtureFormRow />);
                    break;
                case "rulesetform":
                    setContent(<AdminFixtureRulesetForm />);
                    break;
                case "question":
                    setContent(<AdminFixtureQuestionnaire />);
                    break;
                case "categorygroup":
                    setContent(<AdminCategoryGroup />);
                    break;
                case "category":
                    setContent(<AdminCategory />);
                    break;
                case "rulesetcategorypairs":
                    setContent(<AdminRulesetCategoryPairs />);
                    break;
                case "taxtreatmenttype":
                    setContent(<AdminFixtureTaxTreatmentType />);
                    break;
                case "tagforruleset":
                    setContent(<AdminTagForRuleset />);
                    break;
                case "tag":
                    setContent(<AdminTag />);
                    break;
                case "adjustmentdefault":
                    setContent(<AdminAdjustmentDefault />);
                    break;
                case "adjustmentdefaultruleset":
                    setContent(<AdminAdjustmentDefaultRuleset />);
                    break;
                default:
                    setContent(
                        <div style={{maxWidth: 500}}>
                            <PageNotFound />
                        </div>
                    );
                    break;
            }
    }, [params.type]);

    return (
        <Layout headerType={HeaderType.Blank}>
            <CanShowAdminMiddleware>
                <h2>
                    Data of fixture: {params.type ? `${params.type[0]?.toUpperCase()}${params.type.slice(1)}` : "..."}{" "}
                    <BackButton url="/settings/fixtures" className="float-end" />
                    <Button
                        className="me-2 float-end"
                        onClick={handleDownloadDump}
                        compact={true}
                        loading={isDumpLoading}
                    >
                        {/* Download as <i>{params.type}.json</i> */}
                        {trg("download_json")}
                    </Button>
                    {renderLink}
                </h2>

                <Panel style={{minHeight: 350}}>{content}</Panel>
            </CanShowAdminMiddleware>
        </Layout>
    );
};

export default AdminFixtureByType;
