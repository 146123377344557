import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Layout from "../Layout/Layout";
import {LeftbarItemType} from "../Layout/LayoutLeftbar";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useTr} from "../../utils/trUtil";
import * as O from "fp-ts/Option";
import ImportTrialBalanceFile from "./ImportTrialBalanceFile";
import ImportTrialBalanceColumnMapping from "./ImportTrialBalanceColumnMapping";
import ImportJournalFile from "./ImportJournalFile";
import ImportJournalColumnMapping from "./ImportJournalColumnMapping";
import ImportDataCheck from "./ImportDataCheck";
import LoadingContainer, {CircularWidth} from "../../misc/LoadingContainer";
import ImportTrialBalanceAdjustments from "./ImportTrialBalanceAdjustments";
import ErrorBoundary from "../../misc/ErrorBoundary";
import {Steppers, Stepper} from "@appkit4/react-components/stepper";
import "./Import.scss";
import useUserClaims from "../../hooks/useUserClaims";

const LEFTBAR_ITEM_KEYS = {
    TRIAL_BALANCE_IMPORT: "trial-balance-import",
    TRIAL_BALANCE_MAPPING: "trial-balance-mapping",
    JOURNAL_IMPORT: "journal-import",
    JOURNAL_MAPPING: "journal-mapping",
    TRIAL_BALANCE_ADJUSTMENTS: "trial-balance-adjustments",
    DATA_CHECK: "data-check",
};

const Import: React.FC = () => {
    const navigate = useNavigate();
    const tr = useTr("import");
    const trg = useTr("global");
    const calculation = useGlobalStore((s) => s.currentCalculation);

    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [content, setContent] = React.useState(<LoadingContainer circularWidthOverride={CircularWidth.big} />);
    const [leftbarItems, setLeftbarItems] = React.useState<LeftbarItemType[]>();
    const [reloadMenu, setReloadMenu] = React.useState<boolean>(false);
    const [reloadCalculationInfo, setReloadCalculationInfo] = React.useState<boolean>(true);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const [currentStep, setCurrentStep] = useState(0);
    const [menu, setMenu] = useState<React.ReactNode>();
    const userClaims = useUserClaims();

    const handleIndexChange = useCallback(
        (index: number) => {
            if (leftbarItems) {
                setCurrentStep(index);
                navigate(leftbarItems[index].link);
            }
        },
        [leftbarItems, setCurrentStep, navigate]
    );

    // Set leftbar items by current entity and calculation
    React.useEffect(() => {
        if (O.isSome(calculation) && O.isSome(entity)) {
            setLeftbarItems([
                {
                    key: LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_IMPORT,
                    text: tr("import_trial_balance"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/trial-balance/file`,
                    isComplete: calculation.value.state_trial_balance_uploaded,
                    required: true,
                },
                {
                    key: LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_MAPPING,
                    text: tr("trial_balance_column_mapping"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/trial-balance/column-mapping`,
                    isComplete: calculation.value.state_trial_balance_imported,
                    isRunning: calculation.value.state_trial_balance_import_is_running,
                    required: true,
                },
                {
                    key: LEFTBAR_ITEM_KEYS.JOURNAL_IMPORT,
                    text: tr("import_journal"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/journal/file`,
                    isComplete: calculation.value.state_journal_uploaded,
                    required: true,
                },
                {
                    key: LEFTBAR_ITEM_KEYS.JOURNAL_MAPPING,
                    text: tr("journal_column_mapping"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/journal/column-mapping`,
                    isComplete: calculation.value.state_journal_imported,
                    isRunning: calculation.value.state_journal_import_is_running,
                    required: true,
                },
                {
                    key: LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_ADJUSTMENTS,
                    text: tr("trial_balance_adjustments"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/trial-balance/adjustments`,
                    isComplete: calculation.value.state_trial_balance_adjustment,
                    required: true,
                },
                {
                    key: LEFTBAR_ITEM_KEYS.DATA_CHECK,
                    text: tr("data_check"),
                    link: `/calculation/${entity.value.hash}/${calculation.value.hash}/import/data-check`,
                    isComplete: calculation.value.state_data_checked,
                    required: true,
                },
            ]);
        }
    }, [calculation, entity, tr]);

    // Redirects by calculation status
    React.useEffect(() => {
        if (O.isSome(calculation) && O.isSome(entity) && leftbarItems) {
            if (userClaims.hasCalculationClaim || userClaims.hasEntityClaim || userClaims.hasClientClaim) {
            } else if (userClaims.hasAttachmentClaim) {
                navigate(`/calculation/${entity.value.hash}/${calculation.value.hash}/questionnaire`);
            }
            if (window.location.href.endsWith("import")) {
                let relevantItem: LeftbarItemType | undefined = undefined;
                switch (calculation.value.status) {
                    case 1:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_IMPORT);
                        break;
                    case 2:
                        relevantItem = leftbarItems.find(
                            (item) => item.key === LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_MAPPING
                        );
                        break;
                    case 3:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.JOURNAL_IMPORT);
                        break;
                    case 4:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.JOURNAL_MAPPING);
                        break;
                    case 5:
                        relevantItem = leftbarItems.find(
                            (item) => item.key === LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_ADJUSTMENTS
                        );
                        break;
                    default:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.DATA_CHECK);
                        break;
                }
                if (relevantItem) navigate(relevantItem.link);
            }

            if (
                window.location.href.endsWith("import/trial-balance") ||
                window.location.href.endsWith("import/trial-balance/")
            ) {
                let relevantItem: LeftbarItemType | undefined = undefined;
                switch (calculation.value.status) {
                    case 2:
                        relevantItem = leftbarItems.find(
                            (item) => item.key === LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_MAPPING
                        );
                        break;
                    default:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.TRIAL_BALANCE_IMPORT);
                        break;
                }
                if (relevantItem) navigate(relevantItem.link);
            }

            if (window.location.href.endsWith("import/journal") || window.location.href.endsWith("import/journal/")) {
                let relevantItem: LeftbarItemType | undefined = undefined;
                switch (calculation.value.status) {
                    case 4:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.JOURNAL_MAPPING);
                        break;
                    default:
                        relevantItem = leftbarItems.find((item) => item.key === LEFTBAR_ITEM_KEYS.JOURNAL_IMPORT);
                        break;
                }
                if (relevantItem) navigate(relevantItem.link);
            }
        }
    }, [calculation, entity, leftbarItems, navigate]);

    // Set relevant content by current calculation and entity
    React.useEffect(() => {
        if (O.isSome(calculation) && O.isSome(entity)) {
            if (window.location.href.search("import/trial-balance/file") > 0) {
                setContent(
                    <ImportTrialBalanceFile calculation={calculation.value} entity={entity.value} menu={menu} />
                );
            } else if (window.location.href.search("import/trial-balance/column-mapping") > 0) {
                setCurrentStep(1);
                setContent(
                    <ImportTrialBalanceColumnMapping
                        calculation={calculation.value}
                        entity={entity.value}
                        menu={menu}
                    />
                );
            } else if (window.location.href.search("import/journal/file") > 0) {
                setCurrentStep(2);
                setContent(<ImportJournalFile calculation={calculation.value} entity={entity.value} menu={menu} />);
            } else if (window.location.href.search("import/journal/column-mapping") > 0) {
                setCurrentStep(3);
                setContent(
                    <ImportJournalColumnMapping calculation={calculation.value} entity={entity.value} menu={menu} />
                );
            } else if (window.location.href.search("import/trial-balance/adjustments") > 0) {
                setCurrentStep(4);
                setContent(
                    <ImportTrialBalanceAdjustments calculation={calculation.value} entity={entity.value} menu={menu} />
                );
            } else if (window.location.href.search("import/data-check") > 0) {
                setCurrentStep(5);
                setContent(<ImportDataCheck calculation={calculation.value} entity={entity.value} menu={menu} />);
            } else {
                setContent(<>{trg("preparing")}</>);
            }
        }
    }, [calculation, entity, reloadMenu, menu, trg]);

    useEffect(() => {
        if (
            O.isSome(calculation) &&
            O.isSome(entity) &&
            reloadCalculationInfo === true &&
            (calculation.value.state_trial_balance_import_is_running ||
                calculation.value.state_journal_import_is_running)
        ) {
            setReloadCalculationInfo(false);
            setTimeout(() => {
                fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/`)
                    .then((res) => res.json())
                    .then((data) => {
                        typeof data.error !== "undefined" ? addErrorNotification(data) : updateCurrentCalculation(data);
                        setReloadCalculationInfo(true);
                    })
                    .catch((err) => addErrorNotification(err));
            }, 10000);
        }
    }, [
        calculation,
        entity,
        reloadCalculationInfo,
        addErrorNotification,
        fetchApi,
        setReloadCalculationInfo,
        updateCurrentCalculation,
    ]);

    React.useEffect(() => {
        setMenu(
            <Steppers space={250} activeIndex={currentStep} onActiveIndexChange={handleIndexChange}>
                <Stepper label="Import trial balance" status="normal"></Stepper>
                <Stepper
                    label="Trial balance column mapping"
                    status={
                        O.isSome(calculation) && calculation.value.state_trial_balance_import_is_running
                            ? "warning"
                            : "normal"
                    }
                ></Stepper>
                <Stepper label="Import journal" status="normal"></Stepper>
                <Stepper
                    label="Journal column mapping"
                    status={
                        O.isSome(calculation) && calculation.value.state_journal_import_is_running
                            ? "warning"
                            : "normal"
                    }
                ></Stepper>
                <Stepper label="Trial balance adjustments" status="normal"></Stepper>
                <Stepper label="Data check" status="normal"></Stepper>
            </Steppers>
        );
        setReloadMenu(!reloadMenu);
    }, [calculation, entity, currentStep, handleIndexChange]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <ErrorBoundary>{content}</ErrorBoundary>
                {/* <LayoutLeftbar items={leftbarItems} title={tr("import_steps")}>
                    <ErrorBoundary>{content}</ErrorBoundary>
                </LayoutLeftbar> */}
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default Import;
