import {Button, CalendarPicker, Panel} from "@appkit4/react-components";
import {DefaultComponentProps} from "../Questionnaire";
import {useState} from "react";
import i18n from "../../../utils/i18n";
import {useTr} from "../../../utils/trUtil";
import isValidDate from "../../../utils/isValidDate";

const DateComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const trg = useTr("global");
    const initialValue =
        question.answer && question.answer.data.value ? new Date(question.answer.data.value) : undefined;
    const [value, setValue] = useState<Date | undefined>(initialValue);

    const handleChange = (v: Date, isValid: boolean) => {
        if (isValid && isValidDate(v) && v) {
            setValue(v);
            onChangeHandler(question, v);
        } else {
            onChangeHandler(question, null);
        }
    };

    return (
        <div className="d-flex">
            <div>
                <CalendarPicker
                    fieldTitle={trg("pick_a_date")}
                    placeholder={trg("date_format")}
                    locale={i18n.language}
                    editable={false}
                    value={value && isValidDate(value) ? value : undefined}
                    onChange={handleChange}
                />
            </div>
            <div className="mt-2">
                <Button compact kind="text" onClick={() => setValue(undefined)}>
                    {trg("clear_date")}
                </Button>
            </div>
            <div className="flex-shrink-1"></div>
        </div>
    );
};

export default DateComponent;
