import React from "react";
import {useNavigate} from "react-router";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {FileTemplate, Language} from "../../../../types/FileTemplate";
import {Input, Button, Select} from "@appkit4/react-components";
import {CalendarPicker} from "@appkit4/react-components/datepicker";
import i18n from "../../../../utils/i18n";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {TextArea} from "@appkit4/react-components/field";
import {dateToIsoString} from "../../../../utils/dateToIsoString";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {load} from "mime";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    fileTemplate?: FileTemplate;
    onSaveHandler?: () => void;
    isCopyForm?: boolean;
};

const FileTemplateForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [fileTemplate, setFileTemplate] = React.useState<FileTemplate | undefined>(
        props.fileTemplate ? props.fileTemplate : undefined
    );
    const trg = useTr("global");

    const {loadFileTemplates} = useFixtureStore((s) => ({
        loadFileTemplates: s.loadFileTemplates,
    }));

    const [data, setData] = React.useState<FileTemplate>({
        id: props.fileTemplate ? props.fileTemplate.id : 0,
        name: props.fileTemplate ? props.fileTemplate.name : "",
        description: props.fileTemplate ? props.fileTemplate.description : "",
        public_url: props.fileTemplate ? props.fileTemplate.public_url : "",
        language: props.fileTemplate ? props.fileTemplate.language : "en",
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint =
            props.fileTemplate && props.isCopyForm
                ? `/settings/_/fixtures/file_template/${props.fileTemplate.id}/copy/`
                : props.fileTemplate
                ? `/settings/_/fixtures/file_template/${props.fileTemplate.id}/`
                : `/settings/_/fixtures/file_template/`;

        fetchApi(
            endpoint,
            props.fileTemplate && props.isCopyForm ? "post" : props.fileTemplate ? "patch" : "post",
            data
        )
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    loadFileTemplates();
                    if (props.onSaveHandler) props.onSaveHandler();
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setFileTemplate(props.fileTemplate);
    }, [props.fileTemplate]);

    React.useEffect(() => {
        setData({
            id: fileTemplate ? fileTemplate.id : 0,
            name: fileTemplate ? fileTemplate.name : "",
            description: fileTemplate ? fileTemplate.description : "",
            public_url: fileTemplate ? fileTemplate.public_url?.toString() : "",
            language: fileTemplate ? fileTemplate.language : "en",
        });
    }, [fileTemplate]);

    return (
        <>
            <div className="row">
                <div className="col-md-8 mt-3">
                    <Input
                        type={"text"}
                        title={trg("name")}
                        value={data.name}
                        required
                        onChange={(val: string) => setData((s) => ({...s, name: val}))}
                    ></Input>
                </div>
                <div className="col-md-4 mt-3">
                    <Select
                        data={["sk", "en"]}
                        placeholder={trg("language")}
                        required
                        value={data.language}
                        searchable={true}
                        onSelect={(val: any) => {
                            let v: Language = val;
                            setData((s) => ({...s, language: v}));
                        }}
                    ></Select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type={"text"}
                                title={"Public URL"}
                                value={data.public_url}
                                required
                                onChange={(val: string) => setData((s) => ({...s, public_url: val}))}
                            ></Input>
                        </div>
                        <div className="col-md-12 mt-3">
                            <Button compact onClick={saveHandler} loading={isLoading}>
                                {trg("save")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-3">
                    <TextArea
                        title={trg("description")}
                        required
                        value={data.description}
                        onChange={(val: string) => setData((s) => ({...s, description: val}))}
                    ></TextArea>
                </div>
            </div>
        </>
    );
};

export default FileTemplateForm;
