import React from "react";
import {THEME_KEYWORD} from "../Constants";
import {useThemeStore} from "../stores/ThemeStore";
import {Theme} from "../types/Theme";
import "./ThemeProvider.scss";

interface PropTypes {
    children: React.ReactNode;
}
const ThemeProvider: React.FC<PropTypes> = (props: PropTypes) => {
    const theme = useThemeStore((s) => s.theme);
    const setTheme = useThemeStore((s) => s.setTheme);
    React.useEffect(() => {
        if (THEME_KEYWORD in localStorage) {
            setTheme(localStorage.getItem(THEME_KEYWORD) as Theme);
        }
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            setTheme(Theme.DARK);
        }
    }, [setTheme]);

    return (
        <div id="theme-provider" data-theme={theme}>
            {props.children}
        </div>
    );
};
export default ThemeProvider;
