import {Button, Panel, Notification, Input, Select} from "@appkit4/react-components";
import {FormLabel} from "react-bootstrap";
import React from "react";
import Layout from "../Layout/Layout";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import {useParams, useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";

declare type FormError = {
    key: string;
    message: string;
};

const CreateClient: React.FC = () => {
    const tr = useTr("client_create");
    const formTr = useTr("form");
    const trg = useTr("global");
    const params = useParams();
    const navigate = useNavigate();
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [name, setName] = React.useState<string>("");

    const [errors, setErrors] = React.useState<FormError[]>([]);
    const [responseErrors, setResponseErrors] = React.useState([]);

    const inputHasError = (name: string): boolean => {
        let hasError = errors.findIndex((x) => x.key === name) > -1;
        return hasError;
    };

    const isFormValid = (): boolean => {
        setErrors([]);
        let isValid = true;
        if (name === "") {
            setErrors((s) => [...s, {key: "name", message: "Name of client is empty"}]);
            isValid = false;
        }
        return isValid;
    };

    const submitForm = () => {
        if (!isFormValid()) return false;

        let data = {
            name: name,
        };

        fetchApi(`/client/`, "post", data)
            .then((res) => res.json())
            .then((result) => {
                if (typeof result.error !== "undefined") {
                    addErrorNotification(result);
                    setResponseErrors(result.error.details);
                } else {
                    loadUserInfo();
                    navigate("/");
                }
            });
    };

    return (
        <Layout headerType={HeaderType.Blank}>
            <Panel className="calculation_create_panel">
                <h1 className="d-inline">{tr("CreateLabel")}</h1>
                <div className="container p-3">
                    {Object.entries(responseErrors).map((row) => {
                        return (
                            <div key={row[0]}>
                                <Notification status="error" message={`${row[0]} - ${row[1][0]}`} />
                            </div>
                        );
                    })}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <FormLabel required={true} className="mb-1">
                                {tr("Name")}
                            </FormLabel>
                            <Input
                                hideTitleOnInput={true}
                                error={inputHasError("name")}
                                title={formTr("typeHere")}
                                type="text"
                                onChange={(v: string) => {
                                    setName(v);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <Button kind="secondary" onClick={() => navigate(-1)}>
                                {trg("cancel")}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <Button className="float-end" onClick={submitForm}>
                                {trg("create")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Panel>
        </Layout>
    );
};

export default CreateClient;
