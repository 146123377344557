import {Panel, Radio, RadioGroup} from "@appkit4/react-components";
import {DefaultComponentProps} from "../Questionnaire";
import {useState} from "react";
import {useTr} from "../../../utils/trUtil";

const RadioButtonComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const initialValue = question.answer?.data?.value || "";
    const trg = useTr("global");
    const [value, setValue] = useState(initialValue);

    return (
        <RadioGroup
            value={value}
            className="d-flex"
            onChange={(v: string) => {
                onChangeHandler(question, v);
                setValue(v);
            }}
        >
            {question.choices.map((choice, index) => (
                <Radio className="ms-3" key={index} value={choice}>
                    {trg(choice)}
                </Radio>
            ))}
        </RadioGroup>
    );
};

export default RadioButtonComponent;
