import {create} from "zustand";

interface SidebarState {
    isActive: boolean;
    setIsActive: (newState: boolean) => void;
}

export const useSidebarStore = create<SidebarState>((set) => ({
    isActive: false,
    setIsActive: (newState: boolean) => set(() => ({isActive: newState})),
}));
