import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {CategoryGroup} from "../../../../types/CategoryGroup";
import {nonEmptyArray} from "fp-ts";
import {Checkbox} from "@appkit4/react-components/checkbox";
import {Tag} from "../../../../types/Tag";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    tag?: Tag;
    onSaveHandler?: () => void;
};

const TagForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<Tag | undefined>(props.tag ? props.tag : undefined);
    const trg = useTr("global");

    const [data, setData] = React.useState<Tag>({
        id: props.tag ? props.tag.id : 0,
        name: props.tag ? props.tag.name : "",
        name_en: props.tag ? props.tag.name_en : "",
        name_sk: props.tag ? props.tag.name_sk : "",
        description: props.tag ? props.tag.description : "",
        account_number: props.tag ? props.tag.account_number : "",
        transaction_id: props.tag ? props.tag.transaction_id : "",
        adjustment_entry_tax_type: props.tag ? props.tag.adjustment_entry_tax_type : "",
        adjustment_entry_type: props.tag ? props.tag.adjustment_entry_type : "",
        valid: props.tag ? props.tag.valid : false,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.tag
            ? `/settings/_/fixtures/categorygroup/${props.tag.id}/`
            : `/settings/_/fixtures/categorygroup/`;

        fetchApi(endpoint, props.tag ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRulesetForm(props.tag);
        setData({
            id: props.tag ? props.tag.id : 0,
            name: props.tag ? props.tag.name : "",
            name_en: props.tag ? props.tag.name_en : "",
            name_sk: props.tag ? props.tag.name_sk : "",
            description: props.tag ? props.tag.description : "",
            account_number: props.tag ? props.tag.account_number : "",
            transaction_id: props.tag ? props.tag.transaction_id : "",
            adjustment_entry_tax_type: props.tag ? props.tag.adjustment_entry_tax_type : "",
            adjustment_entry_type: props.tag ? props.tag.adjustment_entry_type : "",
            valid: props.tag ? props.tag.valid : false,
        });
    }, [props.tag]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Tag ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "290px"}}>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name")}
                            value={data.name}
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name_en")}
                            value={data.name_en}
                            onChange={(val: string) => setData((s) => ({...s, name_en: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("name_sk")}
                            value={data.name_sk}
                            onChange={(val: string) => setData((s) => ({...s, name_en: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Input
                            type={"text"}
                            title={trg("description")}
                            value={data.description}
                            onChange={(val: string) => setData((s) => ({...s, description: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={[
                                "null",
                                "TD",
                                "TND-T",
                                "TND-P",
                                "TND-U",
                                "BS",
                                "TD - Bad Debt",
                                "TND-T - Bad Debt",
                                "TND-P - Bad Debt",
                                "TND-U - Bad Debt",
                                "BS - Bad Debt",
                                "TD - Inventory",
                                "TND-T - Inventory",
                                "TND-P - Inventory",
                                "TND-U - Inventory",
                                "BS - Inventory",
                                "TD - F.Assets",
                                "TND-T - F.Assets",
                                "TND-P - F.Assets",
                                "TND-U - F.Assets",
                                "BS - F.Assets",
                                "Compens. fees",
                                "Rent",
                                "Market./Oth. studies",
                                "Intermediary fees",
                                "Fees non-treat. ctry.",
                                "Prof. services",
                                "Contr. penalties",
                                "Sponsoring",
                                "Ad. by non-profit org.",
                                "Non-life ins. tax",
                                "Other cash basis",
                            ]}
                            value={data.adjustment_entry_tax_type}
                            searchable={true}
                            placeholder={trg("adjustment_entry_tax_type")}
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    adjustment_entry_tax_type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Select
                            data={["null", "provisions", "valuation_allowances", "cash_basis", "fixed_assets"]}
                            value={data.adjustment_entry_type}
                            searchable={true}
                            placeholder={trg("adjustment_entry_type")}
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    adjustment_entry_type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Checkbox
                            className="mt-2"
                            checked={data.valid}
                            onChange={(v) => setData((s) => ({...s, valid: v}))}
                        >
                            {trg("valid")}
                        </Checkbox>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TagForm;
