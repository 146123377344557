import {ClaimTypes} from "../Constants";
import {Calculation} from "../types/Calculation";
import {Client} from "../types/Client";
import {Entity} from "../types/Entity";
import {UserClaim} from "../types/UserInfo";

export const getUserClaim = (claims: UserClaim[], typ: string) => claims.filter((s) => s.typ === typ)[0];
export const hasClientClaim = (client: Client, claims: UserClaim[], typ: string) =>
    claims.filter((s) => s.typ === typ && s.val === `${client.hash}`).length > 0;
export const hasEntityClaim = (entity: Entity, claims: UserClaim[], typ: string, clients?: Client[]) => {
    let _has_entity = false;
    clients?.forEach((_c) => {
        _c.entities.forEach((_e) => {
            if (
                _e.hash === entity.hash &&
                claims.filter((s) => s.typ === ClaimTypes.CLIENT && s.val === `${_c.hash}`).length > 0
            )
                _has_entity = true;
        });
    });
    return _has_entity || claims.filter((s) => s.typ === typ && s.val === `${entity.hash}`).length > 0;
};
export const hasCalculationClaim = (calculation: Calculation, entity: Entity, claims: UserClaim[], typ: string) =>
    claims.filter((s) => s.typ === typ && s.val === `${entity.hash}:${calculation.hash}`).length > 0;
