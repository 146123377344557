import React from "react";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";
import "./Layout.scss";
import {HeaderType} from "../../types/HeaderType";
import NotificationToaster from "../../misc/NotificationToaster";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useLocation} from "react-router-dom";
import * as O from "fp-ts/Option";

interface PropTypes {
    children: React.ReactNode;
    headerType: HeaderType;
}

const Layout: React.FC<PropTypes> = (props: PropTypes) => {
    const {setCurrentLocation, calculation, entity} = useGlobalStore((s) => ({
        setCurrentLocation: s.setCurrentLocation,
        calculation: s.currentCalculation,
        entity: s.currentCalculationEntity,
    }));
    const location = useLocation();
    React.useEffect(() => {
        setCurrentLocation(location);
    }, [location, setCurrentLocation]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && location.pathname.search(entity.value.hash) >= 1) {
            document.title = "Corporate Tax Flow | " + entity.value.name + ": " + calculation.value.name;
        } else {
            document.title = "Corporate Tax Flow";
        }
    }, [entity, calculation, location]);
    return (
        <div id="layout-node">
            <div id="layout-header-container">
                <LayoutHeader headerType={props.headerType} />
            </div>
            <div id="content-body">{props.children}</div>
            <div id="layout-footer-container">
                <LayoutFooter />
            </div>
            <NotificationToaster />
        </div>
    );
};

export default Layout;
