import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {HeaderType} from "../../../types/HeaderType";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Loading, Select, Modal, Button, Input, Tooltip, Panel} from "@appkit4/react-components";
import * as O from "fp-ts/Option";
import {useTr} from "../../../utils/trUtil";
import {validateEmail} from "../../../utils/validateEmail";
import {stripHtml} from "../../../utils/stripHtml";
import {ClaimTypes} from "../../../Constants";
import {Entity} from "../../../types/Entity";
import {Calculation} from "../../../types/Calculation";
import {safeBackendFetch} from "../../../utils/apiClient";
import {BackendUserInfo} from "../../../types/UserInfo";
import Layout from "../../Layout/Layout";
import BackButton from "../../../misc/BackButton";

declare type PropTypes = {};

const AdminUsers: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const tr = useTr("settings");

    const getBackendUserInfo = useGlobalStore((s) => s.getBackendUserInfo);
    const [selectedClient, setSelectedClient] = React.useState<string>("");
    const [selectedEntity, setSelectedEntity] = React.useState<string>("");
    const [selectedCalculation, setSelectedCalculation] = React.useState<string>("");
    const [addModalVisible, setAddModalVisible] = React.useState<boolean>(false);
    const [picker, setPicker] = React.useState<string>("");
    const [entities, setEntities] = React.useState<Entity[]>();
    const [calculations, setCalculations] = React.useState<Calculation[]>();

    const [calculationsAreLoaded, setCalculationsAreLoaded] = React.useState<boolean>(true);
    const [membersModalVisible, setMembersModalVisible] = React.useState<boolean>(false);
    const [clientMembers, setClientMembers] = React.useState<BackendUserInfo[]>([]);
    const [entityMembers, setEntityMembers] = React.useState<BackendUserInfo[]>([]);
    const [calculationParticipants, setCalculationParticipants] = React.useState<BackendUserInfo[]>([]);
    const [currentUser, setCurrentUser] = React.useState<O.Option<BackendUserInfo>>(O.none);
    const [currentUserIsOwner, setCurrentUserIsOwner] = React.useState<boolean>(false);

    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<boolean>(false);

    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const userInfo = useGlobalStore((s) => s.userInfo);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const getClientByHash = useGlobalStore((s) => s.getClientByHash);
    const getEntityByHash = useGlobalStore((s) => s.getEntityByHash);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onDeleteHandle = (email: string) => {
        setMembersModalVisible(false);
        fetchApi(`/client/${selectedClient}/delete_member/`, "delete", {email: email})
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined" ? addErrorNotification(data) : loadUserInfo();
            })
            .catch((err) => console.error(err));
    };

    const onAddHandle = () => {
        setError(false);
        if (validateEmail(email) === null) {
            setError(true);
            return;
        }

        let url = "";
        switch (picker) {
            case "client":
                url = `/client/${selectedClient}/add_member/`;
                break;
            case "entity":
                url = `/entity/${selectedEntity}/add_member/`;
                break;
            case "calculation":
                url = `/entity/${selectedEntity}/claims/?type=${ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS}&calculation_hash=${selectedCalculation}`;
                break;
            default:
                return;
        }

        fetchApi(url, "post", {email})
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    addErrorNotification(data);
                } else {
                    loadUserInfo();
                }
            });

        setAddModalVisible(false);
        setEmail("");
    };

    const clients =
        O.isSome(userInfo) &&
        userInfo.value.backend_info !== undefined &&
        !O.isNone(userInfo.value.backend_info.clients)
            ? userInfo.value.backend_info.clients
            : O.none;

    const clientsArray: any[] = clients
        ? Object.entries(clients).map(([key, client]) => ({key, value: client.hash, label: client.name}))
        : [];

    React.useEffect(() => {
        if (!O.isNone(clients)) {
            const client = Object.entries(clients).find(([key, client]) => client.hash === selectedClient);
            if (client) {
                const selectedClient = client[1];
                console.log("Selected Client:", selectedClient);
                if (!O.isNone(selectedClient.entities)) {
                    setEntities(selectedClient.entities);
                } else {
                    console.log("No entities found for the selected client");
                }
            } else {
                console.log("No such client found");
            }
        }
    }, [selectedClient, clients]);

    React.useEffect(() => {
        if (selectedEntity) {
            setCalculationsAreLoaded(false);
            if (selectedEntity) {
                if (O.isSome(userInfo) && accessToken) {
                    safeBackendFetch(`/entity/${selectedEntity}/calculation/`, {}, accessToken)
                        .then((response) => {
                            return response.json();
                        })
                        .then((data: Array<Calculation>) => {
                            setCalculations(data);
                            setCalculationsAreLoaded(true);
                        });
                } else {
                    setCalculationsAreLoaded(false);
                }
            } else {
                console.log("No calculations found for the selected entity");
            }
        } else {
            console.log("No such entity found");
        }
    }, [selectedEntity, userInfo, accessToken]);

    React.useEffect(() => {
        const client = getClientByHash(selectedClient);
        if (O.isSome(client)) {
            fetchApi(`/client/${client.value.hash}/members/`)
                .then((response) => response.json())
                .then((data: any | BackendUserInfo[]) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        let backend_user = getBackendUserInfo();
                        if (!O.isNone(backend_user) && typeof client.value.owner !== "undefined") {
                            setCurrentUserIsOwner(backend_user.value.id === client.value.owner.id);
                        }
                        setCurrentUser(backend_user);
                        setClientMembers(data);
                    }
                });
        }
    }, [selectedClient, addErrorNotification, fetchApi, getBackendUserInfo, getEntityByHash, getClientByHash]);

    React.useEffect(() => {
        const entity = getEntityByHash(selectedEntity);
        if (O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/members/`)
                .then((response) => response.json())
                .then((data: BackendUserInfo[] | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        let backend_user = getBackendUserInfo();
                        if (!O.isNone(backend_user) && typeof entity.value.owner !== "undefined") {
                            setCurrentUserIsOwner(backend_user.value.id === entity.value.owner.id);
                        }
                        setCurrentUser(backend_user);
                        setEntityMembers(data);
                    }
                });
        }
    }, [selectedEntity, addErrorNotification, fetchApi, getBackendUserInfo, getEntityByHash]);

    React.useEffect(() => {
        const calculation = calculations?.find((calculation) => calculation.hash === selectedCalculation);
        if (calculation) {
            setCalculationParticipants(calculation.participants);
        }
    }, [selectedCalculation, calculations]);

    const renderMembers = () => {
        let members: BackendUserInfo[] = [];
        if (picker === "client") {
            members = clientMembers;
        } else if (picker === "entity") {
            members = entityMembers;
        } else if (picker === "calculation") {
            members = calculationParticipants;
        }

        return members.map((member) => (
            <tr key={member.id}>
                <td>{member.first_name ? `${member.first_name} ${member.last_name}` : member.email}</td>
                <td>{member.email}</td>
                <td className="text-end">
                    {O.isSome(currentUser) && currentUser.value.id === member.id && !currentUserIsOwner && ""}
                    {O.isSome(currentUser) && currentUser.value.id === member.id && currentUserIsOwner && tr("owner")}
                    {O.isSome(currentUser) &&
                        O.isSome(getClientByHash(selectedClient)) &&
                        currentUser.value.id !== member.id && (
                            // <DeleteMemberModal key={member.id} member={member} client={client.value} />
                            <button onClick={() => onDeleteHandle(member.email)}>{trg("delete")}</button>
                        )}
                </td>
            </tr>
        ));
    };

    return (
        <Layout headerType={HeaderType.Blank}>
            <CanShowAdminMiddleware>
                <h2>
                    {tr("add_user_to_all")}
                    <BackButton url="/settings" className="float-end" />
                </h2>
                <Panel style={{minHeight: 350}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-4">
                                <h4>{tr("choose_where_to_add_user")}</h4>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Select
                                        placeholder={tr("clients")}
                                        data={clientsArray}
                                        value={selectedClient}
                                        onSelect={(value) => {
                                            if (value.toString() === selectedClient) {
                                                setSelectedClient("");
                                            } else {
                                                setSelectedClient(value.toString());
                                            }
                                        }}
                                        style={{flex: "0 0 90%"}}
                                    />
                                    {selectedClient !== "" && (
                                        <Tooltip content={tr("show_client_members")}>
                                            <button
                                                className="Appkit4-icon icon-audience-outline"
                                                style={{flex: "0 0 10%"}}
                                                onClick={() => {
                                                    setMembersModalVisible(true);
                                                    setPicker("client");
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                {selectedClient !== "" && (
                                    <div className="row">
                                        {!selectedEntity && (
                                            <Button
                                                kind="primary"
                                                className="col"
                                                compact
                                                style={{margin: "10px 10px"}}
                                                onClick={() => {
                                                    setAddModalVisible(true);
                                                    setPicker("client");
                                                }}
                                            >
                                                {tr("add_user")}
                                            </Button>
                                        )}
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Select
                                                placeholder={tr("entities")}
                                                data={
                                                    entities !== undefined
                                                        ? Object.entries(entities).map(([key, entity]) => ({
                                                              key,
                                                              value: entity.hash,
                                                              label: entity.name,
                                                          }))
                                                        : []
                                                }
                                                value={selectedEntity}
                                                onSelect={(value) => {
                                                    if (value.toString() === selectedEntity) {
                                                        setSelectedEntity("");
                                                    } else {
                                                        setSelectedEntity(value.toString());
                                                    }
                                                }}
                                                style={{flex: "0 0 90%"}}
                                            />
                                            {selectedEntity !== "" && (
                                                <Tooltip content={tr("show_entity_members")}>
                                                    <button
                                                        className="Appkit4-icon icon-audience-outline"
                                                        style={{flex: "0 0 10%"}}
                                                        onClick={() => {
                                                            setMembersModalVisible(true);
                                                            setPicker("entity");
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {selectedClient !== "" && selectedEntity !== "" && (
                                    <div className="row">
                                        {!selectedCalculation && (
                                            <Button
                                                kind="primary"
                                                className="col"
                                                compact
                                                style={{margin: "10px 10px"}}
                                                onClick={() => {
                                                    setAddModalVisible(true);
                                                    setPicker("entity");
                                                }}
                                            >
                                                {tr("add_user")}
                                            </Button>
                                        )}
                                        {!calculationsAreLoaded ? (
                                            <Loading loadingType="circular" indeterminate={true} />
                                        ) : (
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <Select
                                                    placeholder={tr("calculations")}
                                                    data={
                                                        calculations !== undefined
                                                            ? Object.entries(calculations).map(
                                                                  ([key, calculation]) => ({
                                                                      key,
                                                                      value: calculation.hash,
                                                                      label: calculation.name,
                                                                  })
                                                              )
                                                            : []
                                                    }
                                                    value={selectedCalculation}
                                                    onSelect={(value) => {
                                                        if (value.toString() === selectedCalculation) {
                                                            setSelectedCalculation("");
                                                        } else {
                                                            setSelectedCalculation(value.toString());
                                                        }
                                                    }}
                                                    style={{flex: "0 0 90%"}}
                                                />
                                                {selectedCalculation !== "" && (
                                                    <Tooltip content={tr("show_calculation_members")}>
                                                        <button
                                                            className="Appkit4-icon icon-audience-outline"
                                                            style={{flex: "0 0 10%"}}
                                                            onClick={() => {
                                                                setMembersModalVisible(true);
                                                                setPicker("calculation");
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {selectedClient !== "" && selectedEntity !== "" && selectedCalculation !== "" && (
                                    <div className="row">
                                        <Button
                                            kind="primary"
                                            className="col"
                                            compact
                                            style={{margin: "10px 10px"}}
                                            onClick={() => setAddModalVisible(true)}
                                        >
                                            {tr("add_user")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Modal
                            visible={addModalVisible}
                            title={tr("add_user")}
                            onCancel={() => setAddModalVisible(false)}
                            modalStyle={{width: "33.75rem"}}
                            icons={""}
                            footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                            header={""}
                            footer={
                                <>
                                    <Button
                                        onClick={() => setAddModalVisible(false)}
                                        kind="tertiary"
                                        style={{marginRight: "10px"}}
                                    >
                                        {tr("cancel")}
                                    </Button>
                                    <Button onClick={onAddHandle} kind="primary">
                                        {tr("add_user")}
                                    </Button>
                                </>
                            }
                        >
                            <Input
                                type="text"
                                required={true}
                                value={email}
                                allowClear={true}
                                title={trg("user_email_address")}
                                onChange={(v) => setEmail(stripHtml(v))}
                                onClick={() => setError(false)}
                                error={error}
                                errorNode={
                                    <div
                                        id="errormessage"
                                        aria-live="polite"
                                        className="ap-field-email-validation-error"
                                    >
                                        {tr("enter_valid_email")}{" "}
                                    </div>
                                }
                            />
                        </Modal>
                        <Modal visible={membersModalVisible} onCancel={() => setMembersModalVisible(false)}>
                            <Panel>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{width: "30%"}}>{tr("user_name")}</th>
                                            <th style={{width: "30%"}}>{tr("email_address")}</th>
                                            <th style={{width: "30%"}}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderMembers()}</tbody>
                                </table>
                            </Panel>
                        </Modal>
                    </div>
                </Panel>
            </CanShowAdminMiddleware>
        </Layout>
    );
};
export default AdminUsers;
