export default function findCommonElements(array1: any[], array2: any[]) {
    var set1 = new Set(array1);
    var set2 = new Set(array2);

    var commonElements = [...set1].filter(function (element) {
        return set2.has(element);
    });

    return commonElements;
}
