import React from "react";
import {Link} from "react-router-dom";
import Centered from "../../misc/Centered";

const PageNotFound: React.FC = () => (
    <Centered>
        <h2 className="text-center">404 - Page Not Found!</h2>
        <p className="a-p-30 text-center">
            <Link to="/">Go Home</Link>
        </p>
    </Centered>
);

export default PageNotFound;
