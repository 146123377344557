import React from "react";
import Layout from "../Layout/Layout";
import {useParams} from "react-router-dom";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useNavigate} from "react-router-dom";
import PagePanel from "../../misc/PagePanel";
import {Table, Column, Button, Loading} from "@appkit4/react-components";
import {GenericParams} from "../../types/Params";
import {containsEntity} from "../../utils/userUtil";
import {EntityHashParamName} from "../../Constants";
import EntityInfoForm from "./EntityInfoForm";
import {EntityInfo as EntityInfoType} from "../../types/EntityInfo";
import Centered from "../../misc/Centered";

const EntityInfo: React.FC = () => {
    const tr = useTr("entity_info");
    const trg = useTr("global");

    const uriParams = useParams<GenericParams>();
    const navigate = useNavigate();

    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const userInfo = useGlobalStore((s) => s.userInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [data, setData] = React.useState<EntityInfoType[]>([]);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [currentInfo, setCurrentInfo] = React.useState<EntityInfoType | null>(null);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const onModalEntityFormClose = () => {
        setTimeout(() => {
            setDataAreLoaded(false);
            setShowModal(false);
        }, 400);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false)
            if (O.isSome(entity))
                fetchApi(`/entity/${entity.value.hash}/info/`)
                    .then((response) => response.json())
                    .then((data) => {
                        typeof data.error !== "undefined" ? addErrorNotification(data) : setData(data);
                        setDataAreLoaded(true);
                    });
    }, [dataAreLoaded, entity]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <div className="content-minimal-padding">
                    {dataAreLoaded ? (
                        <>
                            <PagePanel title={tr("label")}>
                                <Table originalData={data} hasTitle={true} striped={true} condensed={true}>
                                    <Column sortKey="name">{tr("name")}</Column>
                                    <Column sortKey="description">{tr("description")}</Column>
                                    {uriParams[EntityHashParamName] &&
                                        containsEntity(userInfo, uriParams[EntityHashParamName]) && (
                                            <Column
                                                style={{width: "100px"}}
                                                sortKey="id"
                                                renderCell={(row, field) => {
                                                    return (
                                                        <Button
                                                            icon="icon-pencil-outline"
                                                            kind="secondary"
                                                            compact
                                                            onClick={() => {
                                                                setCurrentInfo(row);
                                                                setShowModal(true);
                                                            }}
                                                        >
                                                            {trg("edit")}
                                                        </Button>
                                                    );
                                                }}
                                            >
                                                {tr("actions")}
                                            </Column>
                                        )}
                                </Table>

                                <Button
                                    icon="icon-plus-outline"
                                    kind="secondary"
                                    compact
                                    onClick={() => {
                                        setCurrentInfo(null);
                                        setShowModal(true);
                                    }}
                                    className="mt-3"
                                >
                                    {tr("add_new_info")}
                                </Button>
                            </PagePanel>
                            <EntityInfoForm
                                showModal={showModal}
                                entityInfo={currentInfo}
                                onModalClose={onModalEntityFormClose}
                            />
                        </>
                    ) : (
                        <Centered>
                            <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                        </Centered>
                    )}
                </div>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default EntityInfo;
