import Moment from "moment";
import {randomstring} from "./randomstring";
import {replaceSpecialChars} from "./replaceSpecialChars";
import {Calculation} from "../types/Calculation";
import {Entity} from "../types/Entity";
import {FileModel} from "@appkit4/react-components";
import {get_api_backend_uri} from "./apiClient";
import {getCookie} from "./getCookie";

export const generateIdentifier = (file: FileModel, entity: Entity, calculation: Calculation) => {
    let timestamp = Moment(new Date()).format("YYYYMMDD_HHmmss");
    return (
        entity.hash +
        "/" +
        calculation.hash +
        "/" +
        timestamp +
        "_" +
        randomstring(8) +
        "_" +
        replaceSpecialChars(file.name)
    );
};

export const extraPropToFlowjs = (entity: Entity, calculation: Calculation, accessToken: string) => {
    let props = {
        simultaneousUploads: 1,
        uploadMethod: "POST",
        testChunks: false,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
            Authorization: "Bearer " + accessToken,
        },
        generateUniqueIdentifier: (file: FileModel) => generateIdentifier(file, entity, calculation),
    };

    return process.env.REACT_APP_WITH_CREDENTIALS ? {...props, withCredentials: true} : props;
};

export const uploadUrl = (entity: Entity) => `${get_api_backend_uri()}/entity/${entity.hash}/flowjs/upload/`;
