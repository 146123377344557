import React from "react";
import {Table, Column} from "@appkit4/react-components/table";
import {FileModel, Loading, Tooltip} from "@appkit4/react-components";
import {useTr} from "../../utils/trUtil";
import {Link, useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";
import PagePanel from "../../misc/PagePanel";
import * as O from "fp-ts/Option";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import Layout from "../Layout/Layout";
import {HeaderType} from "../../types/HeaderType";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {Button} from "@appkit4/react-components/button";
import {Upload} from "@appkit4/react-components/upload";
import {Notification} from "@appkit4/react-components/notification";
import {AdjustmentAttachment} from "../../types/AdjustmentAttachment";
import {extraPropToFlowjs, uploadUrl} from "../../utils/uploadUtil";
import {Attachment} from "../../types/Attachment";
import {Adjustment} from "../../types/Adjustment";
import {Entity} from "../../types/Entity";
import {Calculation} from "../../types/Calculation";
import {Question} from "../../types/Question";

declare type PropTypes = {};

declare type AttachmentItem = {
    id: number;
    filename: string;
    created: Date;
    download_url?: string;
    adjustments?: AdjustmentAttachment[];
    attachment: Attachment;
    questions: Question[];
};

const Attachments: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const trh = useTr("header");
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const reloadCurrentCalculation = useGlobalStore((s) => s.reloadCurrentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [attachments, setAttachments] = React.useState<AttachmentItem[]>([]);

    const [toDelete, setToDelete] = React.useState<AttachmentItem>();
    const [toReplace, setToReplace] = React.useState<AttachmentItem>();
    const [visibleDeleteModal, setVisibleDeleteModal] = React.useState<boolean>(false);
    const [deletionIsRunning, setDeletionIsRunning] = React.useState<boolean>(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = React.useState<string>("");

    const [visibleUploadModal, setVisibleUploadModal] = React.useState<boolean>(false);
    const [uploadIsRunning, setUploadIsRunning] = React.useState<boolean>(false);
    const [uploadErrorMessage, setUploadErrorMessage] = React.useState<string>("");

    const fetchApi = useGlobalStore((s) => s.fetchApi);

    const onFileUploadChange = (file: FileModel) => {
        setUploadIsRunning(true);
        setUploadErrorMessage("");
        if (file && file.percent === 100 && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/${
                    toReplace ? toReplace.id + "/" : ""
                }`,
                toReplace !== undefined ? "patch" : "post",
                file.response
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    reloadCurrentCalculation();
                    setTimeout(() => {
                        setUploadIsRunning(false);
                        setVisibleUploadModal(false);
                    }, 2000);
                })
                .catch((error) => {
                    //setUploadIsRunning(false);
                    //setVisibleUploadModal(false);
                    setUploadErrorMessage(error.message);
                });
        }
    };

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        const mappedAttachments = data.map((a) => ({
                            id: a.id,
                            filename: a.file.original_filename,
                            created: a.file.created,
                            download_url: a.file.download_url,
                            adjustments: a.adjustments,
                            attachment: a,
                            questions: a.questions ?? [],
                        }));
                        setAttachments(mappedAttachments);
                    } else {
                        console.error("Unexpected response format");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching attachments:", error);
                });
        }
    }, [calculation, entity]);

    React.useEffect(() => {
        reloadCurrentCalculation();
    }, [reloadCurrentCalculation]);

    React.useEffect(() => {
        setUploadErrorMessage("");
        if (visibleDeleteModal === false) {
            setToDelete(undefined);
        }
    }, [visibleDeleteModal]);

    React.useEffect(() => {
        setUploadErrorMessage("");
        if (visibleUploadModal === false) {
            setTimeout(() => {
                setToReplace(undefined);
            }, 400);
        }
    }, [visibleUploadModal]);

    const deleteAttachment = (d: AttachmentItem) => {
        setToDelete(d);
        setVisibleDeleteModal(true);
        return;
    };

    const replaceAttachment = (d: AttachmentItem) => {
        setToReplace(d);
        setVisibleUploadModal(true);
        return;
    };

    const onConfirmDeleteAttachment = () => {
        if (toDelete && O.isSome(entity) && O.isSome(calculation)) {
            setDeletionIsRunning(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/${toDelete.id}/`,
                "delete"
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                        setVisibleDeleteModal(false);
                        setDeletionIsRunning(false);
                    } else {
                        reloadCurrentCalculation();
                        setTimeout(() => {
                            setVisibleDeleteModal(false);
                            setDeletionIsRunning(false);
                        }, 2000);
                    }
                })
                .catch((err) => {
                    setDeleteErrorMessage(err.message);
                });
        }
    };

    const renderFilename = (d: AttachmentItem) => {
        return <a href={d.download_url}>{d.filename}</a>;
    };

    const renderCreated = (d: AttachmentItem) => {
        return (
            <small>
                <i>{d.created.toString()}</i>
            </small>
        );
    };

    const renderQuestionId = (d: AttachmentItem) => {
        return (
            <small>
                {O.isSome(entity) &&
                    O.isSome(calculation) &&
                    d.questions &&
                    d.questions.map((question, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && ", "}
                            <Link
                                to={`/calculation/${entity.value.hash}/${calculation.value.hash}/questionnaire/?questionId=${question.question_id}`}
                            >
                                {question.question_id}
                            </Link>
                        </React.Fragment>
                    ))}
            </small>
        );
    };

    const closeUploadModal = () => {
        setVisibleUploadModal(false);
    };

    const renderAdjustments = (d: AttachmentItem) => {
        if (d.adjustments && O.isSome(entity) && O.isSome(calculation))
            return d.adjustments.map((a) => (
                <AssignedAdjustment
                    key={a.id}
                    entity={entity.value}
                    calculation={calculation.value}
                    adjustment={a.adjustment}
                    attachment={d.attachment}
                />
            ));
        return "";
    };

    const renderActions = (d: any) => {
        return (
            <>
                <Button
                    className="text-danger p-0 m-0 d-inline-block"
                    kind="text"
                    icon="icon-delete-fill text-danger"
                    title={trg("permanently_delete_attachment_title")}
                    onClick={() => deleteAttachment(d)}
                ></Button>
                <Button
                    className="p-0 m-0 d-inline-block"
                    kind="text"
                    icon="icon-upload-outline"
                    title={trg("replace_attachment_title")}
                    onClick={() => replaceAttachment(d)}
                ></Button>
            </>
        );
    };

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={trh("CalculationAttachmentsLabel")}
                    buttons={
                        <div>
                            <Button
                                kind="secondary"
                                icon="icon-upload-light-outline"
                                onClick={() => {
                                    setVisibleUploadModal(true);
                                }}
                                className="d-inline"
                            >
                                {trg("upload_new_attachment")}
                            </Button>
                        </div>
                    }
                >
                    <Table originalData={attachments} hasTitle striped condensed>
                        <Column field="id" sortKey="id">
                            ID
                        </Column>
                        <Column field="filename" sortKey="filename" renderCell={renderFilename}>
                            {trg("filename")}
                        </Column>
                        <Column field="created" sortKey="created" renderCell={renderCreated}>
                            {trg("created")}
                        </Column>
                        <Column field="adjustments" renderCell={renderAdjustments}>
                            {trg("assigned_adjustments")}
                        </Column>
                        <Column field="questions" renderCell={renderQuestionId}>
                            {trg("questionId")}
                        </Column>
                        <Column field="id" renderCell={renderActions}>
                            {trg("actions")}
                        </Column>
                    </Table>
                    <Modal
                        visible={visibleDeleteModal}
                        title={trg("confirm_file_deletion")}
                        ariaLabel={""}
                        onCancel={() => setVisibleDeleteModal(false)}
                        modalStyle={{width: "33.75rem"}}
                        footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                        header={
                            <>
                                <Badge type="danger" value="!"></Badge>{" "}
                            </>
                        }
                        icons={""}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={() => {
                                        setVisibleDeleteModal(false);
                                        if (deleteErrorMessage) setDeletionIsRunning(false);
                                    }}
                                >
                                    {trg("cancel")}
                                </Button>
                                <Button kind="negative" onClick={onConfirmDeleteAttachment} loading={deletionIsRunning}>
                                    {trg("confirm")}
                                </Button>
                            </>
                        }
                        bodyStyle={{minHeight: "92px"}}
                    >
                        {deleteErrorMessage !== "" && (
                            <Notification title="" message={<div>{deleteErrorMessage}</div>} status="error" closeable />
                        )}
                        {toDelete ? (
                            <>
                                {trg("delete_attachment_description")}
                                <div className="mt-2 mb-3 text-center">
                                    <i>{toDelete.filename}</i>
                                </div>
                                {trg("confirm_deleting_file")}
                            </>
                        ) : (
                            ""
                        )}
                    </Modal>

                    <Modal
                        visible={visibleUploadModal}
                        title={
                            toReplace
                                ? trg("replace_attachment_title") + " (" + toReplace.filename + ")"
                                : trg("upload_new_attachment")
                        }
                        ariaLabel={""}
                        onCancel={closeUploadModal}
                        modalStyle={{width: "33.75rem"}}
                        footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                        icons={""}
                        footer={
                            <>
                                <Button kind="secondary" onClick={closeUploadModal}>
                                    {trg("close")}
                                </Button>
                            </>
                        }
                        header={toReplace && <Badge type="warning" value="Warning"></Badge>}
                        bodyStyle={{minHeight: "92px"}}
                    >
                        {O.isSome(entity) && O.isSome(calculation) && (
                            <>
                                {uploadErrorMessage !== "" && (
                                    <Notification title="" message={<div>{uploadErrorMessage}</div>} status="error" />
                                )}
                                <Upload
                                    style={{width: "100%"}}
                                    action={uploadUrl(entity.value)}
                                    autoUpload={true}
                                    extraPropToFlowjs={extraPropToFlowjs(entity.value, calculation.value, accessToken)}
                                    description={() => (
                                        <>
                                            <span>{trg("drag_and_drop_or")} </span>
                                            <span className="ap-fileupload-drop-browse-span" data-mode="files">
                                                {trg("choose_files")}
                                            </span>
                                        </>
                                    )}
                                    uploadInstruction={
                                        toReplace
                                            ? trg("replace_attachment_instruction") +
                                              " " +
                                              trg("upload_attachment_instruction")
                                            : trg("upload_attachment_instruction")
                                    }
                                    multiple={true}
                                    color={"white"}
                                    onChange={onFileUploadChange}
                                    config={{
                                        trigger: false,
                                        type: "inline",
                                        size: true,
                                    }}
                                ></Upload>
                                {uploadIsRunning && (
                                    <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                                )}
                            </>
                        )}
                    </Modal>
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default Attachments;

declare type PropTypesAssignedAdjustment = {
    entity: Entity;
    calculation: Calculation;
    adjustment: Adjustment;
    attachment: Attachment;
};

const AssignedAdjustment: React.FC<PropTypesAssignedAdjustment> = (props: PropTypesAssignedAdjustment) => {
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const reloadCurrentCalculation = useGlobalStore((s) => s.reloadCurrentCalculation);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const trg = useTr("global");

    const unassingAttachment = () => {
        if (window.confirm(trg("confirm_unassigning_attachment_of_adjustment")) === true) {
            setIsLoading(true);
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/adjustment/${props.adjustment.id}/attachment/`,
                "delete",
                {attachment_id: props.attachment.id}
            )
                .then((r) => r.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : reloadCurrentCalculation();
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                });
            // .catch((data) => {
            //     addErrorNotification(data);
            //     setIsLoading(false);
            // });
        }
    };

    const tooltipClass = "tooltip-class-number-" + props.adjustment.id;

    return (
        <div className="my-1">
            <Tooltip
                target={"." + tooltipClass}
                trigger="hover"
                position="top-left"
                id={tooltipClass}
                appendAfterTarget={true}
                distance={4}
                content={"This attachment will be unassinged from " + props.adjustment.name + " adjustment."}
            ></Tooltip>

            <div className={"d-flex " + tooltipClass}>
                <a role="button">{props.adjustment.name}</a>
                <div className="flex-fill"></div>
                {isLoading ? (
                    <Loading loadingType="circular" indeterminate={true} compact={true}></Loading>
                ) : (
                    <span
                        className="Appkit4-icon icon-link-broken-fill"
                        role="button"
                        onClick={unassingAttachment}
                    ></span>
                )}
            </div>
        </div>
    );
};
