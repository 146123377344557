import React, {Dispatch} from "react";

declare type AttributesProps = {url: string; filename: string};

declare type ResultType = () => {
    setElementId: Dispatch<React.SetStateAction<string>>;
    attributes: AttributesProps;
    renderLink: React.ReactNode;
    download: (url: string, filename: string) => void;
};

const useDownloadUrl: ResultType = () => {
    const [attributes, setAttributes] = React.useState<AttributesProps>({
        url: "",
        filename: "downloaded.file",
    });
    const [elementId, setElementId] = React.useState<string>("temprary-download-link");

    const download = (_url: string, _filename: string) => {
        if (_url.length > 0) {
            setAttributes((s) => ({
                url: _url,
                filename: _filename,
            }));
        }
    };

    React.useEffect(() => {
        if (attributes.filename.length > 0 && attributes.url.length > 0) document.getElementById(elementId)?.click();
    }, [elementId, attributes]);

    return {
        setElementId: setElementId,
        download: download,
        attributes: attributes,
        renderLink: (
            <a style={{visibility: "hidden"}} href={attributes.url} download={attributes.filename} id={elementId}>
                .
            </a>
        ),
    };
};

export default useDownloadUrl;
