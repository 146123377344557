import React, {useState, useEffect} from "react";
import Centered from "../../misc/Centered";
import {Loading} from "@appkit4/react-components/loading";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import PagePanel from "../../misc/PagePanel";
import {useTr} from "../../utils/trUtil";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Accordion, AccordionItem} from "@appkit4/react-components";
import {numberToLocaleString} from "../../utils/numberToLocaleString";
import i18n from "../../utils/i18n";
import * as O from "fp-ts/Option";

interface Step {
    title: string;
    increase_tax_base: any[];
    decrease_tax_base: any[];
    amount: number;
    increase_amount?: number;
    decrease_amount?: number;
}

const TaxBase: React.FC = () => {
    const trh = useTr("header");
    const tr = useTr("tax_base");

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [dataAreLoaded, setDataAreLoaded] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    };

    const itemRender = (item: any) => {
        return (
            <div className="d-flex bd-highlight" key={item.id}>
                <div className="p-2 d-flex flex-grow-1 bd-highlight">{item.name}</div>
                <div className="p-2 bd-highligh " style={{paddingLeft: "0px"}}>
                    {numberToLocaleString(item.amount, i18n.language)}
                </div>
            </div>
        );
    };

    const renderTaxBaseItems = (taxBase: any[]) => {
        return taxBase.map((taxItem, index: number) => {
            return (
                <React.Fragment key={index}>
                    {taxItem.items.map((item: any) => {
                        return itemRender(item);
                    })}
                </React.Fragment>
            );
        });
    };

    useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/calculate/`)
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res);
                    }
                    setDataAreLoaded(true);
                });
        }
    }, [entity, calculation, fetchApi, addErrorNotification]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel title={trh("TaxLabel")}>
                    {dataAreLoaded && data ? (
                        <div className="p-5">
                            <h4>{tr("calculation_of_tax_base")}</h4>
                            <hr />
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <table className="table mt-5 table-tax-base-calculation">
                                        <tbody>
                                            {data.steps &&
                                                Object.entries(data.steps).map(([stepIndex, step]) => {
                                                    const typedStep = step as Step;
                                                    if (typeof step === "object" && step !== null) {
                                                        const increaseAmount = typedStep.increase_amount;
                                                        const decreaseAmount = typedStep.decrease_amount;
                                                        const showAccordion =
                                                            typedStep.amount !== 0 && stepIndex != "4";
                                                        return (
                                                            <>
                                                                <React.Fragment key={stepIndex}>
                                                                    {showAccordion && (
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <Accordion
                                                                                    style={{width: "100%"}}
                                                                                    multiple={true}
                                                                                    onClick={onClickAccordion}
                                                                                    activeKeys={activeKeys}
                                                                                >
                                                                                    <div>
                                                                                        <h5 className="d-flex">
                                                                                            {tr(typedStep.title)}
                                                                                        </h5>
                                                                                    </div>
                                                                                    <AccordionItem
                                                                                        itemKey={`step-${stepIndex}`}
                                                                                        title={tr("increasing_items")}
                                                                                    >
                                                                                        {renderTaxBaseItems(
                                                                                            typedStep.increase_tax_base
                                                                                        )}
                                                                                        {increaseAmount !==
                                                                                            undefined && (
                                                                                            <div className="small px-4">
                                                                                                <div className="d-flex bd-highlight ms-5 w-100">
                                                                                                    <div className="pt-2 d-flex flex-grow-1 bd-highlight">
                                                                                                        {tr(
                                                                                                            "Total amount"
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="bd-highlight pt-2"
                                                                                                        style={{
                                                                                                            marginLeft:
                                                                                                                "200px",
                                                                                                        }}
                                                                                                    >
                                                                                                        {numberToLocaleString(
                                                                                                            increaseAmount,
                                                                                                            i18n.language
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </AccordionItem>
                                                                                    <AccordionItem
                                                                                        itemKey={`step-${stepIndex}-decreasing`}
                                                                                        title={tr("decreasing_items")}
                                                                                    >
                                                                                        {renderTaxBaseItems(
                                                                                            typedStep.decrease_tax_base
                                                                                        )}
                                                                                        {decreaseAmount !==
                                                                                            undefined && (
                                                                                            <div className="small px-4">
                                                                                                <div className="d-flex bd-highlight ms-5 w-100">
                                                                                                    <div className="pt-2 d-flex flex-grow-1 bd-highlight">
                                                                                                        {tr(
                                                                                                            "Total amount"
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="bd-highlight pt-2"
                                                                                                        style={{
                                                                                                            marginLeft:
                                                                                                                "200px",
                                                                                                        }}
                                                                                                    >
                                                                                                        {numberToLocaleString(
                                                                                                            decreaseAmount,
                                                                                                            i18n.language
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </AccordionItem>
                                                                                    {/* <div
                                                                                    className="d-flex bd-highlight"
                                                                                    key={item.id}
                                                                                >
                                                                                    <div className="p-2 d-flex flex-grow-1 bd-highlight">
                                                                                        {item.name}
                                                                                    </div>
                                                                                    <div className="p-2 bd-highligh px-5">
                                                                                        {numberToLocaleString(
                                                                                            item.amount,
                                                                                            i18n.language
                                                                                        )}
                                                                                    </div>
                                                                                </div> */}
                                                                                    <div className="pb-5 d-flex">
                                                                                        <div className="d-flex flex-grow-1">
                                                                                            <h6>{tr("subtotal")}</h6>
                                                                                        </div>
                                                                                        <div className="bd-highligh">
                                                                                            <strong>
                                                                                                {numberToLocaleString(
                                                                                                    typedStep.amount,
                                                                                                    i18n.language
                                                                                                )}
                                                                                            </strong>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <div className="pb-5 d-flex">
                                                    <div className="d-flex flex-grow-1">
                                                        <th>{tr("tax_base")}</th>
                                                    </div>
                                                    <div>
                                                        <th>
                                                            {numberToLocaleString(data.final.amount, i18n.language)}
                                                        </th>
                                                    </div>
                                                </div>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <table className="table mt-5 table-tax-base-calculation">
                                        <tbody>
                                            {data.steps &&
                                                Object.entries(data.steps).map(([stepIndex, step]) => {
                                                    const typedStep = step as Step;
                                                    if (typeof step === "object" && step !== null) {
                                                        const showAccordion =
                                                            typedStep.amount !== 0 && stepIndex === "4";
                                                        return (
                                                            <>
                                                                <React.Fragment key={stepIndex}>
                                                                    {showAccordion && (
                                                                        <tr>
                                                                            <td
                                                                                colSpan={2}
                                                                                className="font-weight-bold"
                                                                            >
                                                                                <div className="bd-highligh">
                                                                                    <strong>
                                                                                        {renderTaxBaseItems(
                                                                                            typedStep.increase_tax_base
                                                                                        )}

                                                                                        {renderTaxBaseItems(
                                                                                            typedStep.decrease_tax_base
                                                                                        )}
                                                                                    </strong>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Centered>
                            <Loading loadingType="circular" indeterminate={true} />
                        </Centered>
                    )}
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default TaxBase;
