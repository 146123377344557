import React from "react";
import {FormRow} from "../../../types/FormRow";
import {RadioGroup, Radio, Input, Accordion, AccordionItem} from "@appkit4/react-components";
import {RecursiveFormRowsComponent} from ".././CalculationForm";

declare type PropTypes = {
    element: FormRow;
    parentPath: string;
    children: React.ReactElement;
};

const HtmlH2: React.FC<PropTypes> = ({element, parentPath, children}) => {
    return (
        <div className="border p-0 m-0">
            <h2 className="text-center border p-5 m-2" style={{color: "black"}}>
                {element.description}
                <br />
                <small className="fw-normal">
                    <small>{element.info}</small>
                </small>
            </h2>
            {children}
        </div>
    );
};

export default HtmlH2;
