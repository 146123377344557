import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select, Loading} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {TextArea} from "@appkit4/react-components/field";
import {Checkbox} from "@appkit4/react-components/checkbox";
import {InputNumber} from "@appkit4/react-components/input-number";
import {FormRow} from "../../../../types/FormRow";
import {FORM_ROW} from "../../../../Constants";
import {useTr} from "../../../../utils/trUtil";
import Centered from "../../../../misc/Centered";
import {Form} from "../../../../types/Form";
import RulesetCategoryIDsForm from "./RulesetCategoryIDsForm";

declare type PropTypes = {
    item?: FormRow;
    onSaveHandler?: (data?: any) => void;
    onDeleteHandler?: (data?: any) => void;
    parent?: FormRow | number;
    form: Form;
    className?: string;
};

const FormRowForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [item, setItem] = React.useState<FormRow>();
    const [rulesetCategoryIDs, setRulesetCategoryIDs] = React.useState<string[]>([]);
    const trg = useTr("global");

    const [data, setData] = React.useState<FormRow>({
        id: props.item ? props.item.id : 0,
        form: props.form.id,
        description: props.item ? props.item.description : "",
        description_en: props.item ? props.item.description_en : "",
        info: props.item ? props.item.info : "",
        info_en: props.item ? props.item.info_en : "",
        ruleset_category_ids:
            props.item && props.item.ruleset_category_ids && props.item.ruleset_category_ids.length
                ? props.item.ruleset_category_ids
                : undefined,
        row_number: props.item ? props.item.row_number : "",
        row_type: props.item ? props.item.row_type : "",
        formula: props.item && props.item.formula && props.item.formula.length ? props.item.formula : "",
        xml_node_name: props.item ? props.item.xml_node_name : "",
        xml_node_type: props.item ? props.item.xml_node_type : "",
        source_data:
            props.item && props.item.source_data && props.item.source_data.length ? props.item.source_data : "",
        html_type: props.item ? props.item.html_type : "",
        data_type: props.item ? props.item.data_type : "",
        input_type: props.item ? props.item.input_type : "",
        calculate_with: props.item ? props.item.calculate_with : FORM_ROW.SIGN_TYPE.SIGN,
        print_with: props.item ? props.item.print_with : FORM_ROW.SIGN_TYPE.SIGN,
        print: props.item ? props.item.print : true,
        show: props.item && props.item.show && props.item.show.length ? props.item.show : undefined,
        priority_xml: props.item ? props.item.priority_xml : 1,
        priority_form: props.item ? props.item.priority_form : 1,
        parent: props.parent ? props.parent : props.item ? props.item.parent : undefined,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.item
            ? `/settings/_/fixtures/formrow/${props.item.id}/`
            : `/settings/_/fixtures/formrow/`;
        fetchApi(endpoint, props.item ? "patch" : "post", {...data, ruleset_category_ids: rulesetCategoryIDs.join("|")})
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler(res);
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const deleteHandler = () => {
        setIsLoading(true);
        if (props.item)
            fetchApi(`/settings/_/fixtures/formrow/${props.item.id}/`, "delete", data)
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        if (typeof props.onDeleteHandler !== "undefined") props.onDeleteHandler();
                        setVisible(false);
                    }
                    setIsLoading(false);
                    if (props.onDeleteHandler !== undefined) props.onDeleteHandler();
                })
                .catch(() => {
                    setIsLoading(false);
                    if (props.onDeleteHandler !== undefined) props.onDeleteHandler();
                });
    };

    React.useEffect(() => {
        setItem(props.item ? props.item : undefined);
    }, [props.item]);

    const renderInputElementByKey = (key: any, val) => {
        if (key === null) return <></>;
        switch (key) {
            case "print_with":
            case "calculate_with":
            case "show":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <Select
                                data={Object.entries(key === "show" ? FORM_ROW.SHOW_TYPE : FORM_ROW.SIGN_TYPE).map(
                                    ([key, value]) => ({
                                        key: value,
                                        value: value,
                                        label: value,
                                    })
                                )}
                                placeholder={key}
                                value={val}
                                onSelect={(v) =>
                                    setData((s) => {
                                        const obj = {...s};
                                        obj[key] = v.toString().length ? v : undefined;
                                        return obj;
                                    })
                                }
                            ></Select>
                        </div>
                    </div>
                );
            case "description":
            case "description_en":
            case "info":
            case "info_en":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <TextArea
                                title={trg(key)}
                                value={val}
                                onChange={(v) =>
                                    setData((s) => {
                                        const obj = {...s};
                                        obj[key] = v;
                                        return obj;
                                    })
                                }
                            />
                        </div>
                    </div>
                );
            case "ruleset_category_ids":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <RulesetCategoryIDsForm
                                form={props.form}
                                formrow={item}
                                onChange={(new_ids) => setRulesetCategoryIDs(new_ids)}
                            />
                        </div>
                    </div>
                );

            case "row_type":
            case "html_type":
            case "data_type":
            case "input_type":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <Select
                                data={Object.entries(FORM_ROW[key.toUpperCase()]).map(([key, value]) => ({
                                    key: value,
                                    value: value,
                                    label: value,
                                }))}
                                placeholder={key}
                                value={val}
                                onSelect={(v) =>
                                    setData((s) => {
                                        const obj = {...s};
                                        obj[key] = v;
                                        return obj;
                                    })
                                }
                            ></Select>
                        </div>
                    </div>
                );

            case "print":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <Checkbox defaultChecked={val}>{trg(key)}</Checkbox>
                        </div>
                    </div>
                );

            case "priority_xml":
            case "priority_form":
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <InputNumber
                                title={trg(key)}
                                value={val}
                                onChange={(v) =>
                                    setData((s) => {
                                        const obj = {...s};
                                        obj[key] = v;
                                        return obj;
                                    })
                                }
                            />
                        </div>
                    </div>
                );
            case "id":
            case "parent":
            case "form":
                return <></>;
            default:
                return (
                    <div key={key} className="row">
                        <div className="col-md-12 mt-3">
                            <Input
                                title={trg(key)}
                                value={val}
                                onChange={(v) =>
                                    setData((s) => {
                                        const obj = {...s};
                                        obj[key] = v.length ? v : key === "source_data" ? null : "";
                                        return obj;
                                    })
                                }
                            />
                        </div>
                    </div>
                );
        }
    };

    return (
        <>
            {item === undefined && props.parent !== undefined ? (
                <Button
                    className={props.className}
                    kind={"tertiary"}
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{marginTop: 5}}
                    compact
                >
                    {trg("add")}
                </Button>
            ) : item === undefined && props.parent === undefined ? (
                <Button
                    className={props.className}
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button
                    onClick={() => setVisible(true)}
                    kind={"tertiary"}
                    compact
                    className={`${props.className ? props.className : ""} d-inline p-1 m-0`}
                >
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            {visible && (
                <Modal
                    visible={visible}
                    maskCloseable={false}
                    title={"Ruleset Form" + (props.parent ? ` (parent: ${props.parent})` : "")}
                    ariaLabel={"Ruleset Form"}
                    onCancel={() => setVisible(false)}
                    modalStyle={{width: "43.75rem"}}
                    footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                    header={
                        item ? (
                            <Badge type={"primary-outlined"} value="Editing"></Badge>
                        ) : (
                            <Badge type="success" value="Adding"></Badge>
                        )
                    }
                    icons={""}
                    footer={
                        <>
                            <div className="d-inline-block me-5">
                                <div className="d-inline-block me-5">
                                    <Button
                                        kind="negative"
                                        className="me-5"
                                        onClick={() =>
                                            window.confirm(
                                                `Do you realy want to delete form row with ID: ${props.item?.id}? Please be carefull! All children will be removed as well.`
                                            ) === true
                                                ? deleteHandler()
                                                : null
                                        }
                                    >
                                        {trg("delete")}
                                    </Button>
                                </div>
                            </div>
                            <Button onClick={() => setVisible(false)} kind="secondary">
                                {trg("cancel")}
                            </Button>
                            <Button onClick={saveHandler} loading={isLoading}>
                                {trg("save")}
                            </Button>
                        </>
                    }
                    bodyStyle={{minHeight: "92px"}}
                >
                    {visible ? (
                        <>
                            form id: {props.form?.id}
                            {Object.entries(data).map(([key, val]) => (
                                <React.Fragment key={key}>{renderInputElementByKey(key, val)}</React.Fragment>
                            ))}
                        </>
                    ) : (
                        <div className="m-5 p-5">
                            <Centered>
                                <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                            </Centered>
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};

export default FormRowForm;
