import React from "react";
import {Button, Notice, Loading} from "@appkit4/react-components";
import {RadioGroup, Radio} from "@appkit4/react-components/radio";
import {Notification} from "@appkit4/react-components/notification";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {Load} from "../../types/Load";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useTr} from "../../utils/trUtil";
import {useNavigate} from "react-router-dom";
import {RadioValueType} from "@appkit4/react-components/esm/radio/Radio";
import Centered from "../../misc/Centered";
import PagePanel from "../../misc/PagePanel";
import ImportSettings from "./ImportSettings";
import useColumnMapping from "../../hooks/useColumnMapping";

declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    menu: React.ReactNode;
};

const ImportJournalColumnMapping: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("calculation_base");
    const tr2 = useTr("calculation_import");
    const tr3 = useTr("base");
    const trg = useTr("global");

    const navigate = useNavigate();
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [lastLoad, setLastLoad] = React.useState<Load>();
    const [lastLoadIsLoaded, setLastLoadIsLoaded] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [cancelIsLoading, setCancelIsLoading] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);

    const [loadType, setLoadType] = React.useState<string>("replace");

    const cm = useColumnMapping({load: lastLoad, type: "journal"});

    React.useEffect(() => {
        if (props.entity && props.calculation)
            fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_last_load/`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined"
                        ? addErrorNotification(data)
                        : data === ""
                        ? addErrorNotification("Any load founded.")
                        : setLastLoad(data);

                    setLastLoadIsLoaded(true);
                });
    }, [props.entity, props.calculation, addErrorNotification, fetchApi]);

    const onChangeLoadType = (value: RadioValueType) => {
        setLoadType("" + value);
    };

    const onColumnMapping = () => {
        setIsLoading(true);
        if (lastLoad?.status === 2) {
            setIsLoading(false);
            setErrors(["Journal was already imported."]);
        } else if (cm.checkTriedSending()) {
            setIsLoading(false);
            setErrors(["All required fields need to be mapped."]);
        } else {
            let mpcol = {...cm.mappedColumns};
            delete mpcol[""];
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_import_load/`,
                "post",
                {
                    load_id: lastLoad?.id,
                    sheet_name: cm.sheet,
                    mapped_columns: mpcol,
                    load_type: loadType,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error === "undefined") {
                        setLastLoad(data.load);
                        updateCurrentCalculation(data.load.calculation);
                        navigate(
                            `/calculation/${props.entity.hash}/${props.calculation.hash}/import/trial-balance/adjustments`
                        );
                    } else {
                        setErrors(data.error.details);
                        addErrorNotification(data);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsLoading(false);
                });
        }
    };

    const onColumnMappingCsv = () => {
        setIsLoading(true);
        if (lastLoad?.status === 2) {
            setIsLoading(false);
            setErrors(["Journal was already imported."]);
        } else if (cm.checkTriedSending()) {
            setIsLoading(false);
            setErrors(["All required fields need to be mapped."]);
        } else {
            let mpcol = {...cm.mappedColumns};
            delete mpcol[""];
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_import_load_csv/`,
                "post",
                {
                    load_id: lastLoad?.id,
                    sheet_name: cm.sheet,
                    mapped_columns: mpcol,
                    load_type: loadType,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error === "undefined") {
                        setLastLoad(data.load);
                        updateCurrentCalculation(data.load.calculation);
                        navigate(
                            `/calculation/${props.entity.hash}/${props.calculation.hash}/import/trial-balance/adjustments`
                        );
                    } else {
                        setErrors(data.error.details);
                        addErrorNotification(data);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsLoading(false);
                });
        }
    };

    const fileType = () => {
        const loadFile = lastLoad?.file.original_filename.split(".")!;
        let fileType = loadFile[1];
        if (fileType === "csv") return false;
        else return true;
    };

    const onCancelImportHandler = () => {
        setCancelIsLoading(true);
        fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_import_cancel/`, "post", {
            load_id: lastLoad?.id,
        })
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined"
                    ? addErrorNotification(data)
                    : updateCurrentCalculation(data.calculation);
                setCancelIsLoading(false);
            })
            .catch((err) => setCancelIsLoading(false));
    };

    return (
        <PagePanel title={trg("journal_column_mapping")}>
            {props.menu}
            {lastLoad ? (
                <>
                    <h2 style={{fontSize: "1.4rem"}}>{tr2("pleaseMapColumns")}</h2>
                    {errors.map((error) => {
                        return (
                            <Notice
                                key={error}
                                title=""
                                style={{width: "auto"}}
                                message={error}
                                status="error"
                                onClose={() => setErrors([])}
                            ></Notice>
                        );
                    })}
                    {cm.sheet && (
                        <div className="row align-items-end">
                            <div className="col-md-4 my-3">
                                <h4 style={{fontSize: "1.2rem", marginBottom: 0}}>{lastLoad.file.original_filename}</h4>
                                <small className="text-muted" style={{display: "block"}}>
                                    {new Date(lastLoad.file.created.toString()).toUTCString()}
                                </small>
                                {cm.sheetSelector}
                            </div>

                            <div className="col-md-6 my-3">
                                <h4 style={{fontSize: "1.2rem"}}>{tr("choose_type")}</h4>
                                <RadioGroup defaultValue={loadType} name="loadType" onChange={onChangeLoadType}>
                                    <Radio value={"replace"}>
                                        {tr("replace")} <small className="text-muted">{tr("old_entries")}</small>
                                    </Radio>
                                    <Radio value={"increment"}>
                                        {tr("increment")} <small className="text-muted">{tr("all_entries")}</small>
                                    </Radio>
                                </RadioGroup>
                            </div>
                            <div className="col-md-2">
                                <div>
                                    {cm.columns.length ? (
                                        <div className="d-flex flex-row-reverse mt-2">
                                            {props.calculation.state_journal_import_is_running ? (
                                                <>
                                                    <Button
                                                        kind="text"
                                                        loading={cancelIsLoading}
                                                        onClick={onCancelImportHandler}
                                                    >
                                                        {trg("cancel")}
                                                    </Button>
                                                    <Button kind="tertiary" disabled={true}>
                                                        {tr2("import_is_running")}
                                                    </Button>
                                                    <Loading
                                                        className="mx-2"
                                                        loadingType="circular"
                                                        indeterminate={true}
                                                        compact={true}
                                                    ></Loading>
                                                </>
                                            ) : props.calculation.state_journal_imported && lastLoad.status >= 2 ? (
                                                <Button kind="secondary" disabled={true}>
                                                    {tr2("import_is_loaded")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    kind="primary"
                                                    loading={isLoading}
                                                    disabled={false}
                                                    onClick={fileType() ? onColumnMapping : onColumnMappingCsv}
                                                >
                                                    {tr3("next")}
                                                </Button>
                                            )}
                                            <ImportSettings />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {props.calculation.state_journal_imported !== true &&
                        props.calculation.journal_import_queue_message &&
                        props.calculation.journal_import_queue_message.error_message && (
                            <Notification
                                title=""
                                style={{width: "100%"}}
                                closeable={false}
                                message={<div>{props.calculation.journal_import_queue_message.error_message}</div>}
                                status="error"
                            ></Notification>
                        )}

                    {cm.mappingTable}
                </>
            ) : lastLoadIsLoaded ? (
                <div className="alert alert-warning">{trg("first_import_journal")}</div>
            ) : (
                <div className="mt-5">
                    <Centered>
                        <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                    </Centered>
                </div>
            )}
        </PagePanel>
    );
};

export default ImportJournalColumnMapping;
