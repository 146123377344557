import React, {useState, useEffect} from "react";
import {Input} from "@appkit4/react-components";
import {Table} from "react-bootstrap";
import {useTr} from "../../../utils/trUtil";
import {DefaultComponentProps} from "../Questionnaire";
import {Button} from "@appkit4/react-components";

const TableComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const trg = useTr("global");
    const initialAnswerValue = question.answer?.data?.value || [{}];
    const [inputValues, setInputValues] = useState<any[]>(initialAnswerValue);
    const [columns, setColumns] = useState<any[]>([]);
    const [initialColumnData, setInitialColumnData] = useState<any[]>([]);
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() => {
        const choices = (question.choices || []) as string[];
        const parsedColumns = choices.map((choice) => {
            const [columnName, columnType] = choice.split("|");
            return {name: columnName, type: columnType};
        });
        setColumns(parsedColumns);
        setInitialColumnData(parsedColumns.map((column) => ({name: column.name})));
    }, [question]);

    const handleInputChange = (index, columnName, event) => {
        const value = event.target.value;
        const newInputValues = inputValues.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [columnName]: value,
                };
            }
            return row;
        });

        setInputValues(newInputValues);
        //setChanged(true);
    };

    const handleAddRow = () => {
        const newRow = {};
        columns.forEach((column) => {
            newRow[column.name] = "";
        });
        setInputValues([...inputValues, newRow]);
    };

    const handleDeleteRow = (index) => {
        if (inputValues.length > 1) {
            const newInputValues = [...inputValues.slice(0, index), ...inputValues.slice(index + 1)];
            setInputValues(newInputValues);
        }
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index} style={{height: "40px"}}>
                            {trg(column.name)}
                        </th>
                    ))}
                    <th style={{width: "5%"}}>{trg("actions")}</th>
                </tr>
            </thead>
        );
    };

    const renderTableRows = () => {
        return (
            <tbody>
                {inputValues.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column, columnIndex) => {
                            const {name} = column;
                            return (
                                <td key={columnIndex} style={{padding: "2px"}}>
                                    <input
                                        value={row[name] || ""}
                                        onChange={(event) => handleInputChange(rowIndex, name, event)}
                                        style={{height: "40px", boxSizing: "border-box", width: "100%"}}
                                    />
                                </td>
                            );
                        })}
                        <td style={{display: "flex", justifyContent: "center", alignItems: "center", padding: 0}}>
                            <Button
                                icon="icon-delete-fill"
                                kind="negative"
                                compact
                                disabled={inputValues.length <= 1}
                                onClick={() => handleDeleteRow(rowIndex)}
                                style={{
                                    display: "inline-block",
                                    position: "relative",
                                    padding: 0,
                                    height: "40px",
                                    width: "90%",
                                }}
                            ></Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    };

    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            const rows = text.split("\n");
            const newInputValues = rows.map((row) => {
                const values = row.split("\t");
                if (values.length > columns.length) {
                    return {unsupported: true};
                }
                let newRow = {};
                columns.forEach((column, index) => {
                    newRow[column.name] = values[index] || "";
                });
                return newRow;
            });
            setInputValues(newInputValues);
            setChanged(true);
        } catch (error) {
            console.error("Error reading from clipboard:", error);
        }
    };

    useEffect(() => {
        if (changed) {
            onChangeHandler(question, inputValues);
            setChanged(false);
        }
    }, [changed, inputValues, onChangeHandler, question]);

    return (
        <div>
            <div className="table-responsive" style={{maxHeight: "375px", overflow: "auto"}}>
                <Table bordered>
                    {renderTableHeader()}
                    {renderTableRows()}
                </Table>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="Appkit4-icon icon-plus-fill" onClick={handleAddRow} role="button"></span>
                <Button className={"ms-3 mb-3"} kind={"primary"} compact onClick={() => setChanged(true)}>
                    {trg("confirm")}
                </Button>
                <Button className={"ms-auto mb-3"} kind={"secondary"} compact onClick={handlePasteFromClipboard}>
                    {trg("paste_from_clipboard")}
                </Button>
            </div>
        </div>
    );
};

export default TableComponent;
