interface IRouteParams {
    paramName: string;
    paramValue: string;
}
export const ProvideRouteParams = (route: string, params: Array<IRouteParams>) => {
    params.forEach(
        (item) =>
            (route = route.replace(
                `:${item.paramName.charAt(0) !== ":" ? item.paramName : item.paramName.substring(1)}`,
                item.paramValue
            ))
    );
    return route;
};
