import {create} from "zustand";
import {safeBackendFetch, safeJsonFetch} from "../utils/apiClient";
import * as O from "fp-ts/Option";
import {BackendUserInfo, User, UserClaim} from "../types/UserInfo";
import {Calculation} from "../types/Calculation";
import {Entity} from "../types/Entity";
import {Client} from "../types/Client";
import {Codebook} from "../types/Codebook";
import {Adjustment} from "../types/Adjustment";
import {AdjustmentCategoryGroup} from "../types/AdjustmentCategory";
import {Reviewer} from "../types/Reviewer";
import {Attachment} from "../types/Attachment";
import {AuthContextProps} from "react-oidc-context";
import {Notification} from "../types/Notification";
import {Location} from "react-router-dom";

interface ClientState {
    auth: null | AuthContextProps;
    setAuth: (auth: AuthContextProps) => void;
    accessToken: string;
    setAccessToken: (accessToken: string | undefined) => void;
    userInfo: O.Option<User>;
    codebooks: Codebook;
    currentCalculation: O.Option<Calculation>;
    currentCalculationEntity: O.Option<Entity>;
    currentClient: O.Option<Client>;
    currentCalculationAdjustments: O.Option<Adjustment[]>;
    currentCalculationReviewers: Reviewer[];
    currentCalculationAttachments: Attachment[];
    currentCalculationAdjustmentCategories: AdjustmentCategoryGroup[];
    activeIds: string[];
    adjustmentsAreLoading: boolean;
    addActiveIds: (hash: string) => void;
    removeActiveIds: (hash: string) => void;
    updateCurrentCalculation: (calculation: Calculation) => Calculation;
    updateCurrentCalculationEntity: (entity: Entity) => Entity;
    removeCurrentCalculation: () => void;
    reloadCurrentCalculation: () => void;
    //tokenRefresher: () => Promise<Boolean>;
    loadUserInfo: () => Promise<Boolean>;
    initCSRFtoken: () => Promise<any>;
    loadCodebooks: (accessToken: string) => Promise<any>;
    loadAdjustments: () => void;
    loadReviewers: () => void;
    loadAttachments: () => void;
    loadAdjustmentCategories: () => void;
    addCalculation: (calculation: Calculation, entityHash: string) => Calculation;
    getEntityByHash: (hash: string) => O.None | O.Option<Entity>;
    getClientByHash: (hash: string) => O.None | O.Option<Client>;
    getBackendUserInfo: () => O.None | O.Option<BackendUserInfo>;
    hasUserClaim: (type: string, value: string) => boolean;
    getUserClaims: () => O.None | O.Option<UserClaim[]>;
    getUserClients: () => Client[];
    fetchApi: (path: string, method?: string, body?: object, init?: RequestInit) => Promise<Response>;
    logout: () => void;
    notifications: Notification[];
    addErrorNotification: (error: any) => void;
    addSuccessNotification: (error: any) => void;
    removeAllNotifications: () => void;
    deleteAdjustmentFromCalculation: (adj: Adjustment) => void;
    currentLocation: Location | null;
    setCurrentLocation: (location: Location) => void;
}

export const useGlobalStore = create<ClientState>((set, get) => ({
    currentLocation: null,
    setCurrentLocation: (location: Location) => set((s) => ({...s, currentLocation: location})),
    auth: null,
    accessToken: "",
    setAccessToken: (accessToken: string | undefined) => set((prev) => ({...prev, accessToken: accessToken})),
    activeIds: [],
    userInfo: O.none,
    currentClient: O.none,
    codebooks: {
        languages: {},
        calculation: {
            statuses: {},
            tax_classification: {},
            adjustment_tax_types: {},
            adjustment_types: {},
            load_statuses: {},
            load_types: {},
        },
        rulesets: {},
    },
    adjustmentsAreLoading: false,
    currentCalculation: O.none,
    currentCalculationEntity: O.none,
    currentCalculationAdjustments: O.none,
    currentCalculationAttachments: [],
    currentCalculationAdjustmentCategories: [],
    currentCalculationReviewers: [],
    notifications: [],
    addErrorNotification: (o: any) => {
        if (typeof o === "string") {
            set((prev) => {
                return {
                    notifications: [...prev.notifications, {message: o, status: "error"}],
                };
            });
        } else if (typeof o.error !== "undefined") {
            if (typeof o.error.details !== "undefined") {
                if (typeof o.error.details.detail !== "undefined") {
                    set((prev) => {
                        return {
                            notifications: [...prev.notifications, {message: o.error.details.detail, status: "error"}],
                        };
                    });
                } else if (typeof o.error.details[0] !== "undefined") {
                    set((prev) => {
                        return {notifications: [...prev.notifications, {message: o.error.details[0], status: "error"}]};
                    });
                } else if (typeof o.error.details === "object") {
                    var errors: any[] = Object.entries(o.error.details)
                        .map(([key, val]) => {
                            if (val && typeof val[0] === "string")
                                return {message: key + ": " + val[0], status: "error"};
                            return null;
                        })
                        .filter((e) => e !== null);
                    set(() => {
                        return {
                            notifications: get().notifications.concat(errors),
                        };
                    });
                }
            }
        } else if (typeof o.message !== "undefined") {
            set((prev) => {
                return {notifications: [...prev.notifications, {message: o.message, status: "error"}]};
            });
        }
    },
    addSuccessNotification: (message: string) => {
        set((prev) => {
            return {notifications: [...prev.notifications, {message: message, status: "success"}]};
        });
    },
    removeAllNotifications: () => {
        set({notifications: []});
    },
    loadUserInfo: async () => {
        // const pwc_user_info = await safeAuthFetch(PwCAuthKnownEndpoints.user_info)
        //     .then((response) => response.json())
        //     .catch((err) => O.none)
        //     .then((data: PwCUserInfo) => {
        //         //set(() => ({accessToken: data[0].access_token}));
        //         return data[0];
        //     });

        const backend_user_info = await safeBackendFetch("/user/me/", {}, get().accessToken)
            .then((response) => {
                if (response.status === 403) {
                    set(() => ({accessToken: ""}));
                }
                return response.json();
            })
            .catch((err) => O.none)
            .then((data: BackendUserInfo) => data);

        set((prev) => ({
            ...prev,
            userInfo: O.some({
                //pwc_info: pwc_user_info,
                backend_info: typeof backend_user_info.error !== "undefined" ? undefined : backend_user_info,
            }),
        }));

        return true;
    },
    addActiveIds: (hash: string) => {
        set((s) => ({
            activeIds: s.activeIds.some((i) => i === hash)
                ? s.activeIds.filter((k) => k !== hash)
                : [...s.activeIds, hash],
        }));
    },
    removeActiveIds: (hash: string) => {
        let ids = get().activeIds;
        const index = ids.indexOf(hash);
        if (index > -1) {
            // only splice array when item is found
            ids.splice(index, 1); // 2nd parameter means remove one item only
        }
        set(() => ({
            activeIds: ids,
        }));
    },
    initCSRFtoken: () => safeJsonFetch(process.env.REACT_APP_BACKEND_URL + "/ctf/").then((response) => response),
    loadCodebooks: (accessToken: string) =>
        safeBackendFetch("/codebooks/", {}, accessToken)
            .then((response) => response.json())
            .then((data: Codebook) => set(() => ({codebooks: data}))),
    loadAdjustments: () => {
        let entity = get().currentCalculationEntity;
        let calculation = get().currentCalculation;
        if (O.isSome(entity) && O.isSome(calculation)) {
            set(() => ({adjustmentsAreLoading: true}));
            get()
                .fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/`)
                .then((response) => response.json())
                .then((data: Adjustment[] | any) => {
                    typeof data.error !== "undefined"
                        ? get().addErrorNotification(data)
                        : set(() => ({
                              currentCalculationAdjustments: O.some(data.filter((item) => item.parent === null)),
                              adjustmentsAreLoading: false,
                          }));
                });
        }
    },
    loadReviewers: () => {
        let entity = get().currentCalculationEntity;
        let calculation = get().currentCalculation;
        if (O.isSome(entity) && O.isSome(calculation)) {
            get()
                .fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/reviewers/`)
                .then((response) => response.json())
                .then((data: Reviewer[] | any) => {
                    if (typeof data.error !== "undefined") {
                        get().addErrorNotification(data);
                    } else {
                        set(() => ({currentCalculationReviewers: data}));
                        get().loadAdjustments();
                    }
                });
        }
    },
    reloadCurrentCalculation: () => {
        let entity = get().currentCalculationEntity;
        let calculation = get().currentCalculation;
        if (O.isSome(entity) && O.isSome(calculation)) {
            get()
                .fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/`)
                .then((response) => response.json())
                .then((data: Calculation | any) => {
                    typeof data.error !== "undefined"
                        ? get().addErrorNotification(data)
                        : set(() => ({currentCalculation: O.some(data)}));
                });
        }
    },
    loadAttachments: () => {
        let entity = get().currentCalculationEntity;
        let calculation = get().currentCalculation;
        if (O.isSome(entity) && O.isSome(calculation)) {
            return get()
                .fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/`)
                .then((response) => response.json())
                .then((data: Attachment[] | any) => {
                    typeof data.error !== "undefined"
                        ? get().addErrorNotification(data)
                        : set(() => ({currentCalculationAttachments: data}));
                });
        }
    },
    loadAdjustmentCategories: () => {
        let entity = get().currentCalculationEntity;
        let calculation = get().currentCalculation;
        if (O.isSome(entity) && O.isSome(calculation)) {
            get()
                .fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment_categories/`)
                .then((response) => response.json())
                .then((data: AdjustmentCategoryGroup[] | any) =>
                    typeof data.error !== "undefined"
                        ? get().addErrorNotification(data)
                        : set(() => ({currentCalculationAdjustmentCategories: data}))
                );
        }
    },
    addCalculation: (calculation: Calculation, entityHash: string) => {
        var entity = get().getEntityByHash(entityHash);
        var userInfo = get().userInfo;

        if (!entity) return calculation;

        if (
            !O.isNone(userInfo) &&
            userInfo.value.backend_info !== undefined &&
            !O.isNone(userInfo.value.backend_info.clients)
        ) {
            var clientIndex = -1;
            var entityIndex = -1;
            Object.entries(userInfo.value.backend_info.clients).forEach((value, clientKey) => {
                value[1].entities.map((e: Entity, entityKey: number) => {
                    if (e.hash === entityHash) {
                        clientIndex = clientKey;
                        entityIndex = entityKey;
                    }
                    return e;
                });
                return value;
            });

            if (clientIndex === -1 || entityIndex === -1) return calculation;

            if (
                typeof userInfo.value.backend_info.clients[clientIndex].entities[entityIndex].calculations ===
                "undefined"
            ) {
                userInfo.value.backend_info.clients[clientIndex].entities[entityIndex].calculations = [];
            }
            userInfo.value.backend_info.clients[clientIndex].entities[entityIndex].calculations.push(calculation);

            set(() => ({
                userInfo: userInfo,
                currentCalculation: O.some(calculation),
                currentCalculationEntity: entity,
                currentClient: O.isSome(userInfo) ? O.some(userInfo.value.backend_info?.clients[clientIndex]) : O.none,
            }));
        }

        return calculation;
    },
    updateCurrentCalculation: (calculation: Calculation) => {
        set(() => ({currentCalculation: O.some(calculation)}));
        get().loadReviewers();
        return calculation;
    },
    updateCurrentCalculationEntity: (entity: Entity) => {
        set(() => ({currentCalculationEntity: O.some(entity)}));
        return entity;
    },
    deleteAdjustmentFromCalculation: (adj: Adjustment) => {
        set(() => {
            let adjustments = get().currentCalculationAdjustments;
            return {
                currentCalculationAdjustments: O.isSome(adjustments)
                    ? O.some(adjustments.value.filter((a) => a.id !== adj.id))
                    : adjustments,
            };
        });
    },
    removeCurrentCalculation: () => {
        set(() => ({currentCalculation: O.none}));
    },
    getEntityByHash: (hash: string) => {
        let userInfo = get().userInfo;
        if (
            O.isNone(userInfo) ||
            userInfo.value.backend_info === undefined ||
            O.isNone(userInfo.value.backend_info.clients)
        ) {
            return O.none;
        } else {
            var entity: O.Option<Entity> = O.none;
            Object.entries(userInfo.value.backend_info.clients).forEach(([i, c]) => {
                let ent = c.entities.filter((e: Entity) => e.hash === hash);
                if (ent.length > 0) entity = O.some(ent[0]);
                return true;
            });

            return entity;
        }
    },
    getClientByHash: (hash: string) => {
        let userInfo = get().userInfo;
        if (
            O.isNone(userInfo) ||
            userInfo.value.backend_info === undefined ||
            O.isNone(userInfo.value.backend_info.clients)
        ) {
            return O.none;
        } else {
            var client: O.Option<Client> = O.none;
            Object.entries(userInfo.value.backend_info.clients).forEach(([i, c]) => {
                if (c.hash === hash) client = O.some(c);
                return true;
            });

            return client;
        }
    },
    getBackendUserInfo: () => {
        let userInfo = get().userInfo;
        if (O.isNone(userInfo)) {
            return O.none;
        } else if (
            userInfo.value.backend_info === undefined ||
            typeof userInfo.value.backend_info.error !== "undefined"
        ) {
            return O.none;
        } else {
            return O.some(userInfo.value.backend_info);
        }
    },
    getUserClients: () => {
        let userInfo = get().userInfo;
        if (O.isNone(userInfo)) {
            return [];
        } else if (userInfo.value.backend_info !== undefined && userInfo.value.backend_info.clients) {
            return Object.entries(userInfo.value.backend_info.clients).map(([i, _c]) => _c);
        } else {
            return [];
        }
    },
    getUserClaims: () => {
        let userInfo = get().userInfo;
        if (O.isNone(userInfo)) {
            return O.none;
        } else if (userInfo.value.backend_info !== undefined && userInfo.value.backend_info.claims) {
            return userInfo.value.backend_info.claims;
        } else {
            return O.none;
        }
    },
    hasUserClaim: (type: string, value: string) => {
        if (O.isNone(get().getUserClaims())) return false;
        return Object.entries(get().getUserClaims()).some(
            ([index, claim]) => claim.typ === type && claim.val === value
        );
    },
    fetchApi: (path: string, method: string = "get", body?: object, init?: RequestInit) => {
        let headerParams = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${get().accessToken}`,
        };
        let requestData = {
            ...init,
            headers: {...init?.headers, ...headerParams},
            method: method.toUpperCase(),
            body: JSON.stringify(body),
        };
        return safeBackendFetch(path, requestData);
    },
    setAuth: (auth: AuthContextProps) => {
        set({auth: auth});
    },
    logout: () => {
        let auth = get().auth;
        if (auth !== null) {
            auth.signoutSilent();
            get()
                .fetchApi("/logout/", "post", {
                    refresh_token: auth.user?.refresh_token,
                    access_token: auth.user?.access_token,
                })
                .then((res) => res.json())
                .then((data) => (typeof data.error !== "undefined" ? get().addErrorNotification(data) : ""));
        }
        set({
            accessToken: "",
        });
    },
    // tokenRefresher if AZURE Authentication is enabled
    /**  tokenRefresher: async () => {
        const pwc_user_info = await safeAuthFetch(AzureAuthKnownEndpoints.user_info)
            .then((response) => response.json())
            .then((data: PwCUserInfo) => {
                set(() => ({accessToken: data[0].access_token}));
                return data[0];
            });

        return true;
    }, */
}));
