import {Button} from "@appkit4/react-components";
import React from "react";
import {useAuth} from "react-oidc-context";
import {useTr} from "../utils/trUtil";

interface PropTypes {}
const LoginPage: React.FC<PropTypes> = (props: PropTypes) => {
    const auth = useAuth();
    const trg = useTr("global");
    return (
        <div
            className="container-fluid p-0 m-0 h-100"
            style={{
                overflow: "hidden",
                backgroundImage: "url(/static/login-page-background_net.svg)",
                backgroundSize: "auto 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
            }}
        >
            <div className="row h-100 p-0 m-0">
                {/* <img className="img-fluid mb-4" src="/static/pwc-logo-dark.svg" alt="pwc logo" /> */}
                <div className="col-md-7">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <div
                            style={{position: "fixed", left: "100px", top: "25px", width: "75px", marginBottom: "50px"}}
                        >
                            <svg id="PwC" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 215.2">
                                <path
                                    fill="#231f20"
                                    d="M191.9 142.1c-9.1 1.5-13.8 8.2-13.8 20.1 0 11.9 6.2 19.9 15.7 19.9 4.4 0 8.5-1.5 16.9-5.6v9.6c-10.2 4.7-16.1 6.1-24.3 6.1-8.9 0-15.1-2.4-20.2-7.5-5.2-5.2-7.8-12.1-7.8-19.8 0-17.1 12.6-28.6 31.1-28.6 12.3 0 20.7 5.6 20.7 13.8 0 5.3-3.9 9-9.8 9-3 0-5.5-.8-8.6-2.6l.1-14.4zM146.9 164.9c8.2-10.3 11.1-14.5 11.1-19.5s-4-9.1-9.4-9.1c-3.3 0-6.3 1.5-7.7 3.2v21l-13.4 17.9v-40.7h-12.8l-21.2 35.1v-35.1h-7.3L67 142.4v4.9l10.5 1.1v43.1h13.6l20.3-33.4v33.4h14.9l20.6-26.6zM26.6 184.7c1.4.1 2.1.1 3 .1 10.9 0 16.8-6.8 16.8-19.9 0-11-4.8-16.9-13.6-16.9-1.5 0-3 .2-6.1.6l-.1 36.1zm0 23.5l8.8 2v5H.9v-5l7.9-2V148H0v-5.2l21.1-6.4h5.5v7.2c9.9-5.9 13.3-6.9 18.3-6.9 11.7 0 20.3 10.8 20.3 25.7 0 17.3-11.5 28.9-29.7 28.9-2 0-5.5-.1-9-.5l.1 17.4zM127.6 92.4h52.8v13.2h-52.8zM270.1 66V26.4h-26.7V10.3H233V0h-52.6v92.4h103.1V66h-13.4zm-2.7-37v37h-24.1V29h24.1zM183 55.4h27.6V66H183V55.4zm0-42.5h47.4v13.4H183V12.9zM230.4 29v37h-17.1V52.8H183V29h47.4zM183 68.6h27.6v21.1H183V68.6zm30.3 0h17.1v21.1h-17.1V68.6zm19.7 0h7.7v21.1H233V68.6zm0-2.6V29h7.7v37H233zm7.7-53.1v13.4H233V13l7.7-.1zM183 2.6h47.4v7.7H183V2.6zm60.4 87.2V68.6h24.1v21.1h-24.1zm37.4 0H270V68.6h10.8v21.2z"
                                />
                            </svg>
                        </div>
                        <div className="mx-auto" style={{maxWidth: "360px", width: "100%"}}>
                            <h5 style={{color: "#252525"}} className="mb-5">
                                Corporate Tax Flow
                            </h5>
                            {window.localStorage.getItem("firstname") ? (
                                <h4 style={{color: "#252525", fontWeight: 300}}>
                                    {trg("its_nice_to_have_you_back")}
                                    <br /> <b style={{fontWeight: 700}}>{window.localStorage.getItem("firstname")}</b>.
                                </h4>
                            ) : (
                                <h4 style={{color: "#252525", fontWeight: 300}}>
                                    {trg("by_clicking_the_button_below")}{" "}
                                    <b style={{fontWeight: 700}}>PwC {trg("account")}</b>.
                                </h4>
                            )}
                            <Button
                                className="w-100 mt-5"
                                onClick={() => void auth.signinRedirect()}
                                //style={{backgroundColor: "#FFBF1F", color: "#252525"}}
                            >
                                {trg("log_in")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 d-none d-md-block"></div>
            </div>
        </div>
    );
};
export default LoginPage;
