import React, {useState, useEffect} from "react";
import {
    Button,
    Modal,
    Notification,
    Upload,
    Loading,
    FileModel,
    Select,
    Tabs,
    Tab,
    List,
    ListItem,
    Tooltip,
} from "@appkit4/react-components";
import {useTr} from "../../../utils/trUtil";
import * as O from "fp-ts/Option";
import {extraPropToFlowjs, uploadUrl} from "../../../utils/uploadUtil";
import {AdjustmentAttachment} from "../../../types/AdjustmentAttachment";
import {Attachment} from "../../../types/Attachment";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Question} from "../../../types/Question";

declare type AttachmentItem = {
    id: number;
    filename: string;
    created: Date;
    download_url?: string;
    adjustments?: AdjustmentAttachment[];
    attachment: Attachment;
    questions: Question[];
};

const FileUploadComponent: React.FC<{
    question: Question;
    attachments: AttachmentItem[];
    onAttachmentChange: (question: Question, newAttachments: Attachment, deleteAttachment?: boolean) => void;
}> = ({question, attachments, onAttachmentChange}) => {
    const trg = useTr("global");
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const reloadCurrentCalculation = useGlobalStore((s) => s.reloadCurrentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [selectVisible, setSelectVisible] = useState<boolean>(true);
    const [visibleUploadModal, setVisibleUploadModal] = useState<boolean>(false);
    const [uploadIsRunning, setUploadIsRunning] = useState<boolean>(false);
    const [uploadErrorMessage, setUploadErrorMessage] = useState<string>("");
    const [selectedAttachmentIds, setSelectedAttachmentIds] = useState<number[]>([]);

    const fetchApi = useGlobalStore((s) => s.fetchApi);

    const handleAttachmentSelection = async (selectedOptions: any) => {
        const newSelectedAttachmentIds = selectedOptions.map((option: any) => parseInt(option));
        const newIds = newSelectedAttachmentIds.filter((id: number) => !selectedAttachmentIds.includes(id));
        const deselectedIds = selectedAttachmentIds.filter((id) => !newSelectedAttachmentIds.includes(id));
        setSelectedAttachmentIds(newSelectedAttachmentIds);

        await Promise.all(deselectedIds.map((attachmentId: number) => unbindAttachment(attachmentId)));
        await Promise.all(newIds.map((attachmentId: number) => bindAttachment(attachmentId)));
    };

    const onFileUploadChange = (file: FileModel) => {
        setUploadIsRunning(true);
        setUploadErrorMessage("");

        if (file && file.percent === 100 && O.isSome(entity) && O.isSome(calculation) && question) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/`,
                "post",
                file.response
            )
                .then((response) => response.json())
                .then((attachmentData) => {
                    fetchApi(
                        `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/question_attachment/`,
                        "post",
                        {
                            question_id: question.id,
                            attachment: attachmentData.id,
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                addErrorNotification(data);
                            }
                            setSelectedAttachmentIds((prevIds) => [...prevIds, attachmentData.id]);
                            reloadCurrentCalculation();
                            setTimeout(() => {
                                setUploadIsRunning(false);
                                setVisibleUploadModal(false);
                                onAttachmentChange(question, attachmentData);
                            }, 2000);
                        })
                        .catch((error) => {
                            setUploadErrorMessage(error.message);
                        });
                })
                .catch((error) => {
                    setUploadErrorMessage(error.message);
                });
        }
    };

    useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            setSelectedAttachmentIds(
                attachments.filter((a) => a.questions.some((q) => q.question_id === question.id)).map((a) => a.id)
            );
        }
    }, [calculation, entity, attachments, question.id]);

    const closeUploadModal = () => {
        setVisibleUploadModal(false);
    };

    const unbindAttachment = async (attachmentId: any): Promise<boolean> => {
        try {
            if (!O.isSome(entity) || !O.isSome(calculation)) {
                throw new Error("Entity or calculation is undefined");
            }
            const matchingAttachment = attachments.find(
                (attachment) => attachment.attachment.id === parseInt(attachmentId)
            );
            if (!matchingAttachment || matchingAttachment === undefined) {
                console.log(`Attachment with ID ${attachmentId} not found.`);
                return true;
            }
            const matchingIdToDelete = matchingAttachment.questions.find((q) => q.question_id === question.id);
            const response = await fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/question_attachment/${matchingIdToDelete?.id}/`,
                "delete"
            );
            if (response.status === 204) {
                const deleteAttachment = true;
                onAttachmentChange(question, matchingAttachment.attachment, deleteAttachment);
                return true;
            }
            const data = await response.json();
            if (data.error) {
                addErrorNotification(data);
                return false;
            }
            return true;
        } catch (error: any) {
            addErrorNotification(error.message);
            return false;
        }
    };

    const bindAttachment = (attachmentId: any): Promise<boolean> => {
        if (!O.isSome(entity) || !O.isSome(calculation)) {
            return Promise.reject(new Error("Entity or calculation is undefined"));
        }

        return fetchApi(
            `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/question_attachment/`,
            "post",
            {
                question_id: question.id,
                attachment: parseInt(attachmentId),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    addErrorNotification(data);
                    return false;
                } else {
                    onAttachmentChange(question, data);
                    return true;
                }
            })
            .catch((error) => {
                addErrorNotification(error.message);
                return false;
            });
    };

    return (
        <div>
            {attachments.filter((attachment) => selectedAttachmentIds.includes(attachment.id)).length > 0 ? (
                <ul style={{paddingLeft: 0}}>
                    <List
                        data={attachments.filter((attachment) => selectedAttachmentIds.includes(attachment.id))}
                        itemKey="id"
                        renderItem={(attachment) => (
                            <ListItem key={attachment.id} className="attachment-item">
                                <a href={attachment.download_url}>{attachment.filename}</a>
                                <Tooltip
                                    trigger="hover"
                                    position="left"
                                    id="tooltipDesc"
                                    content={trg("unselect_attachment")}
                                >
                                    <span
                                        className="Appkit4-icon icon-link-unlinked-outline"
                                        tabIndex={0}
                                        role="button"
                                        aria-hidden="true"
                                        onClick={() => {
                                            unbindAttachment(attachment.id);
                                            setSelectedAttachmentIds(
                                                selectedAttachmentIds.filter((id) => id !== attachment.id)
                                            );
                                        }}
                                    ></span>
                                </Tooltip>
                            </ListItem>
                        )}
                    />
                </ul>
            ) : (
                <p>{trg("no_attachments_yet")}</p>
            )}
            <br />
            <Tooltip trigger="hover" position="right" id="tooltipDesc" content={trg("add_attachment")}>
                <span
                    className="Appkit4-icon icon-plus-fill"
                    onClick={() => setVisibleUploadModal(true)}
                    role="button"
                ></span>
            </Tooltip>
            <Modal
                visible={visibleUploadModal}
                title={trg("upload_new_attachment")}
                onCancel={closeUploadModal}
                modalStyle={{width: "33.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                footer={
                    <Button kind="secondary" onClick={closeUploadModal}>
                        {trg("close")}
                    </Button>
                }
                bodyStyle={{minHeight: "380px"}}
            >
                <Tabs>
                    <Tab label={trg("upload_new_attachment")}>
                        {O.isSome(entity) && O.isSome(calculation) && (
                            <>
                                {uploadErrorMessage !== "" && (
                                    <Notification title="" message={<div>{uploadErrorMessage}</div>} status="error" />
                                )}
                                <Upload
                                    style={{width: "100%"}}
                                    action={uploadUrl(entity.value)}
                                    autoUpload={true}
                                    extraPropToFlowjs={extraPropToFlowjs(entity.value, calculation.value, accessToken)}
                                    description={() => (
                                        <>
                                            <span>{trg("drag_and_drop_or")} </span>
                                            <span className="ap-fileupload-drop-browse-span" data-mode="files">
                                                {trg("choose_files")}
                                            </span>
                                        </>
                                    )}
                                    uploadInstruction={trg("upload_attachment_instruction")}
                                    multiple={false}
                                    color={"white"}
                                    onChange={onFileUploadChange}
                                    config={{
                                        trigger: false,
                                        type: "inline",
                                        size: true,
                                    }}
                                ></Upload>
                                {uploadIsRunning && (
                                    <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                                )}
                            </>
                        )}
                    </Tab>
                    <Tab label={trg("select_from_attachments")}>
                        <Select
                            data={attachments.map((attachment) => ({
                                value: attachment.id.toString(),
                                label: attachment.filename,
                            }))}
                            showSelectAll={false}
                            searchable={true}
                            placeholder={trg("select_from_attachments")}
                            searchPlaceholder={trg("search")}
                            selectAllText={trg("select_all")}
                            multiple
                            value={selectedAttachmentIds.map((id) => id.toString())}
                            onSelect={handleAttachmentSelection}
                            valueKey={"value"}
                            labelKey={"label"}
                            visible={selectVisible}
                            onVisibleChange={setSelectVisible}
                        />
                    </Tab>
                </Tabs>
            </Modal>
        </div>
    );
};

export default FileUploadComponent;
