import React, {useState} from "react";
import Layout from "../../Layout/Layout";
import CanShowCalculationMiddleware from "../../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../../types/HeaderType";
import {useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import LoadingContainer, {CircularWidth} from "../../../misc/LoadingContainer";
import {LeftbarItemType} from "../../Layout/LayoutLeftbar";
import {Tabs, Tab} from "@appkit4/react-components/tabs";
import {Loading} from "@appkit4/react-components";
import {useTr} from "../../../utils/trUtil";
import DeferredTaxResult from "./DeferredTaxResult";
import "./DeferredTaxBase.scss";
import DeferredTaxPriorEntries from "./DeferredTaxPriorEntries";
import DeferredTaxCurrentEntries from "./DeferredTaxCurrentEntries";
import Centered from "../../../misc/Centered";
import DeferredTaxForm from "./DeferredTaxForm";
import DeferredTaxSettings from "./DeferredTaxSettings";
import PagePanel from "../../../misc/PagePanel";

export declare type DeferredTaxChildPropTypes = {
    menu: React.ReactNode;
    changeMenuIndexTo: (index: number) => void;
    newEntryWasAdded: boolean;
};
declare type PropTypes = {};

const DeferredTaxBase: React.FC<PropTypes> = () => {
    const trh = useTr("header");
    const tr = useTr("global");
    const navigate = useNavigate();
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [content, setContent] = React.useState(<LoadingContainer circularWidthOverride={CircularWidth.big} />);
    const [menuItems, setMenuItems] = React.useState<LeftbarItemType[]>([]);
    const [baseUrl, setBaseUrl] = React.useState<string>("");
    const [currentStep, setCurrentStep] = useState(2);
    const [entriesArePrepared, setEntriesArePrepared] = useState(false);
    const [newEntryWasAdded, setNewEntryWasAdded] = useState(false);

    const handleIndexChange = (index: number) => {
        setCurrentStep(index);
        navigate(menuItems[index].link);
    };

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/deferred_tax_entry/prepare_dynamic_entries/`,
                "post",
                {}
            )
                .then((res: any) => res.json())
                .then((res: any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    }
                    setEntriesArePrepared(true);
                });
        }
    }, [entity, calculation, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        if (newEntryWasAdded === true) {
            setTimeout(() => {
                setNewEntryWasAdded(false);
            }, 1000);
        }
    }, [newEntryWasAdded]);

    React.useEffect(() => {
        setMenuItems(
            O.isNone(calculation) || O.isNone(entity)
                ? []
                : [
                      {
                          key: "prior-entries",
                          text: tr("deferred_tax_prior_entries"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/deferred-tax/prior-entries`,
                      },
                      {
                          key: "current-entries",
                          text: tr("deferred_tax_current_entries"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/deferred-tax/current-entries`,
                      },
                      {
                          key: "deferred_tax_result",
                          text: tr("deferred_tax_result"),
                          link: `/calculation/${entity.value.hash}/${calculation.value.hash}/deferred-tax/result`,
                      },
                  ]
        );
        setBaseUrl(
            O.isNone(calculation) || O.isNone(entity)
                ? ""
                : `/calculation/${entity.value.hash}/${calculation.value.hash}/deferred-tax`
        );
    }, [calculation, entity, window.location.href]);

    const menu = (
        <Tabs type="underline" activeIndex={currentStep} onTabChange={handleIndexChange}>
            {menuItems.map((m) => (
                <Tab key={m.key} label={m.text} value={m.key} />
            ))}
        </Tabs>
    );

    // Set relevant content by current calculation and entity
    React.useEffect(() => {
        if (!O.isNone(calculation) && !O.isNone(entity)) {
            const child_props = {menu: menu, changeMenuIndexTo: handleIndexChange};
            if (window.location.href.endsWith("deferred-tax/prior-entries")) {
                setContent(<DeferredTaxPriorEntries {...child_props} newEntryWasAdded={newEntryWasAdded} />);
                setCurrentStep(0);
            } else if (window.location.href.endsWith("deferred-tax/current-entries")) {
                setContent(<DeferredTaxCurrentEntries {...child_props} newEntryWasAdded={newEntryWasAdded} />);
                setCurrentStep(1);
            } else if (window.location.href.endsWith("deferred-tax/result")) {
                setContent(<DeferredTaxResult {...child_props} newEntryWasAdded={newEntryWasAdded} />);
                setCurrentStep(2);
            } else if (baseUrl !== "") {
                navigate(`${baseUrl}/result`);
            }
        }
    }, [calculation, entity, window.location.href, baseUrl, newEntryWasAdded]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={
                        <>
                            {trh("DeferredTaxLabel")}
                            <sup>
                                <small className="text-muted">&nbsp;(Beta)</small>
                            </sup>
                        </>
                    }
                    submenu={menu}
                    buttons={
                        O.isSome(entity) &&
                        O.isSome(calculation) && (
                            <div className="d-flex">
                                <DeferredTaxForm
                                    entity={entity.value}
                                    calculation={calculation.value}
                                    onSaveHandler={() => setNewEntryWasAdded(true)}
                                />
                                <DeferredTaxSettings
                                    entity={entity.value}
                                    calculation={calculation.value}
                                    onSaveHandler={() => setNewEntryWasAdded(true)}
                                />
                            </div>
                        )
                    }
                >
                    {entriesArePrepared ? (
                        content
                    ) : (
                        <Centered>
                            <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                        </Centered>
                    )}
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default DeferredTaxBase;
