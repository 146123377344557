import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Form} from "../../../../types/Form";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {Select} from "@appkit4/react-components";
import {FormRow} from "../../../../types/FormRow";
import classes from "./RulesetCategoryIDsForm.module.css";
import {useTr} from "../../../../utils/trUtil";

declare type PropTypes = {
    formrow?: FormRow;
    form: Form;
    onChange: (rulesetCategoryIDs: string[]) => void;
    onSaveHandler?: () => void;
};

const RulesetCategoryIDsForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [selectedCategoryRulesets, setSelectedCategoryRulesets] = React.useState<any>([]);
    const [categoryRulesetIds, setCategoryRulesetIds] = React.useState<string>("");
    const {fixtureState, init, rulesetForms, rulesets, categories} = useFixtureStore((s) => ({
        fixtureState: s,
        init: s.init,
        rulesetForms: s.rulesetForms,
        rulesets: s.rulesets,
        categories: s.categories,
    }));
    const trg = useTr("global");

    const [data, setData] = React.useState<any>([]);

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        props.onChange(selectedCategoryRulesets);
    }, [selectedCategoryRulesets /* props - //TODO commented because maximum update depth */]);

    /** Loading ruleset category ids from database */
    React.useEffect(() => {
        if (categoryRulesetIds.length > 0) {
            fetchApi(`/settings/_/fixtures/adjustmentcategoryruleset/?ids=${categoryRulesetIds}`)
                .then((res) => res.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setSelectedCategoryRulesets(res.results.map((r) => `${r.ruleset}_${r.adjustment_category}`));
                    }
                });
        } else {
        }
    }, [categoryRulesetIds, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        setCategoryRulesetIds(
            props.formrow && props.formrow.ruleset_category_ids && (props.formrow.ruleset_category_ids + "").length > 0
                ? props.formrow.ruleset_category_ids
                : ""
        );
    }, [props.formrow, props.formrow?.ruleset_category_ids]);

    // Set data for select and generate it with <ruleset>_<adjustment_category> option value.
    React.useEffect(() => {
        if (fixtureState.rulesetFormsAreLoaded && fixtureState.rulesetsAreLoaded && fixtureState.categoriesAreLoaded)
            setData(
                rulesetForms
                    .filter((rf) => rf.form === props.form.id)
                    .map((rf) => {
                        let r = rulesets.find((x) => x.id === rf.ruleset);
                        if (typeof r !== "undefined")
                            return {
                                ...r,
                                type: "group",
                                children: categories.map((c) => ({
                                    key: c.id,
                                    id: `${r ? r.id : "0"}_${c.id}`,
                                    name: c.name,
                                })),
                            };
                        else return null;
                    })
                    .filter((item) => item !== null)
            );
    }, [
        rulesetForms,
        props.form,
        rulesets,
        categories,
        fixtureState.categoriesAreLoaded,
        fixtureState.rulesetFormsAreLoaded,
        fixtureState.formsAreLoaded,
        fixtureState.rulesetsAreLoaded,
    ]);

    return (
        <div className={`p-2 mb-3 bg-body rounded-1 ${classes["wrapper"]}`}>
            <h6>{trg("adjustment_category_for")}</h6>
            <Select
                data={data}
                searchable={true}
                showSelectAll={false}
                value={selectedCategoryRulesets}
                multiple
                placeholder={trg("selected_ruleset")}
                onSelect={(e) => setSelectedCategoryRulesets(e)}
                valueKey={"id"}
                labelKey={"name"}
            ></Select>
            <ListOfAdjustmentCategoryRuleset
                selectedCategoryRulesets={selectedCategoryRulesets}
                rulesets={rulesets}
                categories={categories}
            />
        </div>
    );
};

export default RulesetCategoryIDsForm;

const ListOfAdjustmentCategoryRuleset = ({selectedCategoryRulesets, rulesets, categories}) => {
    let lastRulesetId = 0;

    return (
        <div className="p-2">
            {selectedCategoryRulesets
                .sort((a, b) => (a < b ? 1 : -1))
                .map((cr) => {
                    let [r, c] = cr.split("_");
                    let rul = rulesets.find((x) => x.id === parseInt(r));
                    let cat = categories.find((x) => x.id === parseInt(c));
                    let printRulesetName = lastRulesetId !== r;
                    lastRulesetId = r;
                    return (
                        <div key={cr}>
                            {printRulesetName && `${rul?.name}:`}
                            <li className="ms-3">{cat?.name}</li>
                        </div>
                    );
                })}
        </div>
    );
};
