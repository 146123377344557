import React from "react";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Button, Input, TextArea, Modal, Select} from "@appkit4/react-components";
import {DeferredTaxEntry} from "../../../types/DeferredTaxEntry";
import {Calculation} from "../../../types/Calculation";
import {Entity} from "../../../types/Entity";
import classes from "./DeferredTaxForm.module.scss";
import {adjustment_tax_types} from "../../../Constants";
import {NaNToZero} from "../../../utils/nanToZero";

declare type PropTypes = {
    entry?: DeferredTaxEntry;
    calculation: Calculation;
    entity: Entity;
    onSaveHandler?: () => void;
    onDeleteHandler?: () => void;
};

const DeferredTaxForm: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [entry, setEntry] = React.useState<DeferredTaxEntry | undefined>(props.entry);
    const [visible, setVisible] = React.useState(false);

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [form, setForm] = React.useState({
        name: entry ? entry.name : "",
        type: entry && entry.type ? entry.type : null,
        calculation: props.calculation.id,
        adjustment_entry: entry && entry.adjustment_entry ? entry.adjustment_entry : null,
        description: entry && entry.description ? entry.description : "",
        tax_type: entry && entry.tax_type ? entry.tax_type : null,
        book_value: entry && entry.book_value ? parseFloat(entry.book_value.toString()).toFixed(2) : "0.00",
        tax_value: entry && entry.tax_value ? parseFloat(entry.tax_value.toString()).toFixed(2) : "0.00",
        rate:
            entry && entry.rate
                ? parseFloat(entry.rate + "").toFixed(2)
                : parseFloat(props.calculation.deferred_tax_rate_closing.toString()).toFixed(2),
        difference: entry && entry.difference ? parseFloat(entry.difference.toString()).toFixed(2) : "0.00",
        value_adjustment:
            entry && entry.value_adjustment ? parseFloat(entry.value_adjustment.toString()).toFixed(2) : "0.00",
        tax: entry && entry.tax ? parseFloat(entry.tax.toString()).toFixed(2) : "0.00",
        is_prior: entry ? entry.is_prior : window.location.href.endsWith("deferred-tax/prior-entries"),
    });

    React.useEffect(() => {
        setForm((s) => ({...s, is_prior: window.location.href.endsWith("deferred-tax/prior-entries")}));
    }, []);

    React.useEffect(() => {
        setForm((s) => ({...s, difference: (parseFloat(s.book_value) - parseFloat(s.tax_value)).toFixed(2)}));
    }, [form.book_value, form.tax_value]);

    React.useEffect(() => {
        setEntry(props.entry);
    }, [props.entry]);

    const onChangeField = (v: string, event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setForm((s) => {
            let new_s = {...s};
            new_s[event.target.name] = v;
            return new_s;
        });
    };

    const onUpdateField = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let val = event.target.value;
        setForm((s) => {
            let new_s = {...s};
            new_s[event.target.name] = !["name", "description", "tax_type"].includes(event.target.name)
                ? NaNToZero(parseFloat(val)).toFixed(2)
                : val;
            return new_s;
        });
    };

    React.useEffect(() => {
        setForm((s) => {
            let new_s = {...s};
            new_s["tax"] = ((parseFloat(form.difference) * parseFloat(form.rate)) / 100).toFixed(2);
            return new_s;
        });
    }, [form.difference, form.rate]);

    const afterSaveDeleteActions = () => {
        if (props.onSaveHandler) props.onSaveHandler();
        setIsSubmitted(false);
        setTimeout(() => setVisible(false), 1000);
    };

    const onSubmit = () => {
        setIsSubmitted(true);
        fetchApi(
            `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/deferred_tax_entry/${
                props.entry ? props.entry.id + "/" : ""
            }`,
            props.entry ? "patch" : "post",
            form
        )
            .then((r) => r.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                }
                afterSaveDeleteActions();
            })
            .catch((data) => {
                addErrorNotification(data);
                afterSaveDeleteActions();
            });
    };

    const deleteEntry = () => {
        if (entry) {
            setIsSubmitted(true);
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/deferred_tax_entry/${
                    entry.id + "/"
                }`,
                "delete"
            )
                .then((res) => {
                    if (!res.ok) {
                        let error = res.json();
                        addErrorNotification(error);
                    }
                    afterSaveDeleteActions();
                })
                .catch((data) => {
                    addErrorNotification(data);
                    afterSaveDeleteActions();
                });
        }
    };

    return (
        <>
            {entry ? (
                <Button
                    kind="text"
                    className="d-inline btn-small"
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            ) : (
                <Button
                    kind="secondary"
                    icon="Appkit4-icon icon-plus-outline"
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    {form.is_prior ? trg("add_new_prior_entry") : trg("add_new_entry")}
                </Button>
            )}
            <Modal
                visible={visible}
                closable={true}
                maskCloseable={false}
                title={entry ? "Edit: " + (entry.name ? entry.name : "") : "Adding new deferred tax entry..."}
                ariaLabel={entry ? (entry.name ? entry.name : "") : "Adding new deferred tax entry..."}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "30.75rem"}}
                className={classes["deferred-tax-form-modal"]}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                icons={""}
                footer={
                    <>
                        <Button
                            onClick={deleteEntry}
                            style={{position: "absolute", left: 20}}
                            kind="negative"
                            icon="icon-delete-outline"
                        >
                            {trg("delete_entry")}
                        </Button>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={onSubmit} loading={isSubmitted}>
                            {trg("save")}
                        </Button>
                    </>
                }
                //bodyStyle={{minHeight: "52px"}}
            >
                <div className={classes["ap-pattern-form"]}>
                    <div className={classes["ap-pattern-form-item"]}>
                        <Input
                            name="name"
                            title={trg("name")}
                            errorNode={
                                <div aria-live="polite" className={classes["ap-field-validation-error"]}>
                                    {trg("please_enter_a_name")}
                                </div>
                            }
                            required
                            error={isSubmitted && form.name === ""}
                            value={form.name}
                            onChange={onChangeField}
                            onBlur={onUpdateField}
                            disabled={entry && entry.adjustment_entry !== null}
                        ></Input>
                    </div>

                    <div className={classes["ap-pattern-form-item"]}>
                        <TextArea
                            name="description"
                            // className={classNames({
                            //     'error': errors.description.dirty && errors.description.error
                            // })}
                            // required
                            title={trg("description")}
                            value={form.description}
                            maxLength={1000}
                            onChange={onChangeField}
                            onBlur={onUpdateField}
                            disabled={entry && entry.adjustment_entry !== null}
                        ></TextArea>
                    </div>
                    <div className={classes["ap-pattern-form-item"]}>
                        <div className="d-flex">
                            <Select
                                className="w-100"
                                data={Object.entries(adjustment_tax_types).map(([key, value]) => ({
                                    label: value,
                                    value: value,
                                }))}
                                placeholder={trg("tax_type")}
                                required={true}
                                value={
                                    form.tax_type
                                        ? form.tax_type
                                        : props.entry && props.entry.tax_type
                                        ? props.entry.tax_type
                                        : ""
                                }
                                onSelect={(v) =>
                                    setForm((s) => {
                                        let new_s = {...s};
                                        new_s["tax_type"] = v.toString();
                                        return new_s;
                                    })
                                }
                                error={isSubmitted && !form.tax_type}
                                disabled={entry && entry.adjustment_entry !== null}
                            ></Select>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <Input
                                className="w-100"
                                name="rate"
                                title={trg("rate") + " (%)"}
                                required
                                value={form.rate}
                                onChange={onChangeField}
                                onBlur={onUpdateField}
                                disabled={entry && entry.adjustment_entry !== null}
                            ></Input>
                        </div>
                    </div>
                    <div className={classes["ap-pattern-form-item"]}>
                        <div className="d-flex">
                            <Input
                                className="w-100"
                                name="book_value"
                                title={trg("book_value")}
                                required
                                value={form.book_value}
                                onChange={onChangeField}
                                onBlur={onUpdateField}
                                disabled={entry && entry.adjustment_entry !== null}
                            ></Input>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <Input
                                className="w-100"
                                name="tax_value"
                                title={trg("tax_value")}
                                required
                                value={form.tax_value}
                                onChange={onChangeField}
                                onBlur={onUpdateField}
                                disabled={entry && entry.adjustment_entry !== null}
                            ></Input>
                        </div>
                    </div>
                    <div className={classes["ap-pattern-form-item"]}>
                        <div className="d-flex">
                            <Input
                                className="w-100"
                                name="difference"
                                title={trg("difference")}
                                value={form.difference}
                                disabled={true}
                                //readOnly={true}
                            ></Input>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <Input
                                className="w-100"
                                name="value_adjustment"
                                title={trg("value_adjustment")}
                                required
                                value={form.value_adjustment}
                                onChange={onChangeField}
                                onBlur={onUpdateField}
                            ></Input>
                        </div>
                    </div>
                    <div className={classes["ap-pattern-form-item"]}>
                        <div className="d-flex">
                            <label>{trg("tax")}:</label>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <strong>
                                {(
                                    (parseFloat(form.difference) * parseFloat(form.rate)) / 100 +
                                    parseFloat(form.value_adjustment)
                                ).toFixed(2)}
                            </strong>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeferredTaxForm;
