import React, {useState} from "react";
import {Tabs, Tab} from "@appkit4/react-components";
import FileUploadComponent from "./FileUploadComponent";
import {Question} from "../../../types/Question";
import {useTr} from "../../../utils/trUtil";
import Comments from "./Comments";
import classes from "../Questionnaire.module.scss";
import {Attachment} from "../../../types/Attachment";
import Attachments from "../Attachments";
import i18n from "../../../utils/i18n";

interface TabsComponentProps {
    question: Question;
    attachments: any[];
    comments: any[];
    TabsChanged: () => void;
    AttachmentsChanged: (question: Question, attachment: Attachment, deleteAttachment?: boolean) => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({
    attachments,
    comments,
    question,
    TabsChanged,
    AttachmentsChanged,
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const trg = useTr("global");
    const language = i18n.language;
    const handleTabChange = (index: number, value: string) => {
        setActiveIndex(index);
        TabsChanged();
    };

    const tabs: {label: React.ReactNode; value: string}[] = [
        question.attachments_upload_allowed
            ? {
                  label: (
                      <div className={classes["tabLabel"]}>
                          <span className="Appkit4-icon icon-paperclip-outline"></span>
                          {trg("attachments")}
                      </div>
                  ),
                  value: "Attachments",
              }
            : null,
        {
            label: (
                <div className={classes["tabLabel"]}>
                    <span className="Appkit4-icon icon-comment-outline"></span>
                    {trg("comments")}
                </div>
            ),
            value: "Comments",
        },
        {
            label: (
                <div className={classes["tabLabel"]}>
                    <span className="Appkit4-icon icon-list-view-outline"></span>
                    {trg("logs")}
                </div>
            ),
            value: "Logs",
        },
        question.help_text || question.help_text_sk
            ? {
                  label: (
                      <div className={classes["tabLabel"]}>
                          <span className="Appkit4-icon icon-information-outline"></span>
                          {trg("info")}
                      </div>
                  ),
                  value: "Info",
              }
            : null,
    ].filter((tab) => tab !== null) as {label: React.ReactNode; value: string}[];

    const renderContent = (tabValue: string) => {
        switch (tabValue) {
            case "Attachments":
                return (
                    <FileUploadComponent
                        key={attachments.length}
                        question={question}
                        attachments={attachments}
                        onAttachmentChange={AttachmentsChanged}
                    />
                );

            case "Comments":
                return (
                    <Comments
                        question={question}
                        comments={comments}
                        onCommentsChange={() => setTimeout(() => TabsChanged(), 3000)}
                    />
                );

            case "Info":
                return (
                    <div>
                        {
                            language === "sk-SK" ? question.help_text_sk : question.help_text //default "en" keby sa nenasla zhoda v language z nejakeho dovodu
                        }
                    </div>
                );

            default:
                return <div>{trg("no_content") + " " + trg(tabValue.toLowerCase())}</div>;
        }
    };

    return (
        <>
            <Tabs type="underline" activeIndex={activeIndex} onTabChange={handleTabChange} style={{fontSize: "50%"}}>
                {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value}></Tab>
                ))}
            </Tabs>
            <div className="tab-content">{renderContent(tabs[activeIndex].value)}</div>
        </>
    );
};

export default TabsComponent;
