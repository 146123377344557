import React from "react";
import {Panel} from "@appkit4/react-components";
import "./PagePanel.scss";

interface PropTypes {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    children: React.ReactNode;
    className?: string;
    buttons?: React.ReactNode;
    withoutPanel?: boolean;
    submenu?: React.ReactNode;
    description?: React.ReactNode;
}
const PagePanel: React.FC<PropTypes> = (props: PropTypes) => {
    return (
        <>
            <div className="d-flex page-panel-header">
                <div className="flex-grow-1">
                    <h2 className="page-title">
                        {props.title}
                        {props.subtitle ? (
                            <small className="text-muted">
                                <small>{props.subtitle}</small>
                            </small>
                        ) : (
                            ""
                        )}
                    </h2>
                    {props.description && <span>{props.description}</span>}
                </div>
                <div>{props.buttons}</div>
            </div>
            {props.submenu ? props.submenu : ""}
            {props.withoutPanel ? props.children : <Panel className={props.className}>{props.children}</Panel>}
        </>
    );
};

export default PagePanel;
