import {Input, Panel} from "@appkit4/react-components";
import {DefaultComponentProps} from "../Questionnaire";
import {useState} from "react";
import {useTr} from "../../../utils/trUtil";

const InputComponent: React.FC<DefaultComponentProps> = ({question, onChangeHandler}) => {
    const initialValue = question.answer?.data?.value || "";
    const trg = useTr("global");
    const [value, setValue] = useState(initialValue);

    return (
        <Input
            type={"text"}
            title={trg("enter_value")}
            value={value}
            onChange={(v: string) => {
                onChangeHandler(question, v);
                setValue(v);
            }}
            onClear={() => {
                onChangeHandler(question, "");
                setValue("");
            }}
            allowClear
        ></Input>
    );
};

export default InputComponent;
