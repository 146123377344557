import React from "react";
import {Adjustment} from "../../../types/Adjustment";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {ListItem, Drawer, Button, Input} from "@appkit4/react-components";
import {useNavigate} from "react-router-dom";
import {getCalculationBaseUrl} from "../../../utils/userUtil";
import {adjustment_types} from "../../../Constants";
import {stripHtml} from "../../../utils/stripHtml";
import {useTr} from "../../../utils/trUtil";
import {Loading} from "@appkit4/react-components/loading";

declare type AdjustmentChildPropTypes = {
    adjustment: Adjustment;
    onAdjustmentSaved?: () => void;
};

export const AdjustmentChildItem: React.FC<AdjustmentChildPropTypes> = (props: AdjustmentChildPropTypes) => {
    const [adjustment, setAdjustment] = React.useState<Adjustment>(props.adjustment);
    const navigate = useNavigate();
    const trg = useTr("global");

    const {calculation, entity, fetchApi, addErrorNotification, addSuccessNotification, loadAdjustments} =
        useGlobalStore((s) => ({
            calculation: s.currentCalculation,
            entity: s.currentCalculationEntity,
            fetchApi: s.fetchApi,
            addErrorNotification: s.addErrorNotification,
            addSuccessNotification: s.addSuccessNotification,
            loadAdjustments: s.loadAdjustments,
        }));

    const [reloadingData, setReloadingData] = React.useState<boolean>(false);
    const [url, setUrl] = React.useState<string>();
    const [endpoint, setEndpoint] = React.useState<string>();
    const [drawerVisible, setDrawerVisible] = React.useState<boolean>(false);
    const [formIsSubmitted, setFormIsSubmitted] = React.useState<boolean>(false);
    const [errorCategory, setErrorCategory] = React.useState<string>("");
    const [adjustmentAreLoaded, setAdjustmentAreLoaded] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [amount, setAmount] = React.useState<string>((0.0).toFixed(2));
    const [coefficient, setCoefficient] = React.useState<string>((1.0).toFixed(2));
    const [finalAmount, setFinalAmount] = React.useState<string>((0.0).toFixed(2));
    const [type, setType] = React.useState<string>("");

    React.useEffect(() => {
        setAdjustment(props.adjustment);
        validateAmountValue(props.adjustment.final_amount);
    }, [props, props.adjustment.amount, props.adjustment]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && adjustment.id) {
            setUrl(`${getCalculationBaseUrl(entity, calculation)}/tax-flows/adjustment/${adjustment.id}`);
            setEndpoint(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment.id}/`
            );
        }
    }, [entity, calculation, adjustment]);

    const validateAmountValue = (value: string | null | number | undefined): string => {
        if (typeof value === "number") value = value.toString();
        if (value === undefined || value === null || parseFloat(value).toString() === "NaN") value = "0.0";
        value = Math.abs(parseFloat(value ? value : "0.0")).toFixed(2);
        setAmount(value);
        return value;
    };

    const dataAreInvalid = () => {
        if (!adjustment.category && adjustment) {
            setErrorCategory(trg("category_is_required"));
            return true;
        }
        setErrorCategory("");
        return false;
    };

    React.useEffect(() => {
        if (adjustment) {
            let adj = adjustment;
            if (adj.type !== undefined) setType(adj.type);
            if (adj.name !== undefined) setName(stripHtml(adj.name));
            if (adj.amount !== undefined && adj.amount !== null)
                setAmount(typeof adj.amount === "string" ? parseFloat(adj.amount).toFixed(2) : adj.amount.toFixed(2));
            if (adj.coefficient !== undefined) setCoefficient(adj.coefficient.toFixed(2));
        }
    }, [adjustment]);

    React.useEffect(() => {
        setFinalAmount((parseFloat(amount) * parseFloat(coefficient)).toFixed(2));
    }, [coefficient, amount]);

    const saveHandler = () => {
        if (dataAreInvalid()) return;

        if (endpoint === "") return;

        setFormIsSubmitted(true);

        let method = "patch";

        let basic_body = {
            name: name,
            amount: amount,
            type: type,
            coefficient: coefficient,
        };

        let body = adjustment ? basic_body : {...basic_body};

        if (endpoint) {
            fetchApi(endpoint, method, body)
                .then((response) => response.json())
                .then((data) => {
                    setFormIsSubmitted(false);
                    setAdjustmentAreLoaded(true);
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                        loadAdjustments();
                    } else {
                        loadAdjustments();
                        setReloadingData(true);
                        setTimeout(() => {
                            setReloadingData(false);
                        }, 8000);
                        addSuccessNotification(trg("saved_successfully"));
                    }
                })
                .catch((data) => {
                    addErrorNotification(data);
                    loadAdjustments();
                    setFormIsSubmitted(false);
                    setAdjustmentAreLoaded(true);
                });
        }
    };

    return (
        <ListItem key={adjustment.id} role="option" style={{margin: "5px 0", padding: "5px"}}>
            {O.isSome(entity) && O.isSome(calculation) && url && (
                <span
                    onClick={(e) => {
                        if (url) {
                            e.preventDefault();
                            navigate(url);
                        }
                    }}
                >
                    <span className="primary-text">{adjustment.name}</span>
                    {adjustment.adjustment_default_id > 0 && (
                        <small>&nbsp;&nbsp;({"da" + adjustment.adjustment_default_id})</small>
                    )}
                </span>
            )}
            <span
                onClick={() => {
                    setDrawerVisible(true);
                }}
            >
                {amount}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="Appkit4-icon icon-pencil-outline"></span>
                <Drawer
                    placement="bottom"
                    title={adjustment.name}
                    visible={drawerVisible}
                    onClose={() => {
                        setDrawerVisible(false);
                    }}
                >
                    <>
                        {reloadingData ? (
                            <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                        ) : (
                            <div className="row border border-1 border-dark p-1 py-3 ">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        title={trg("adjustment_name")}
                                        value={name}
                                        readonly={
                                            type === adjustment_types.FIXED_ASSETS ||
                                            type === adjustment_types.PROVISIONS ||
                                            type === adjustment_types.VALUATION_ALLOWANCES
                                        }
                                        onChange={(v: string) => setName(stripHtml(v))}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                value={amount}
                                                readonly={type !== adjustment_types.MANUAL}
                                                title={trg("amount")}
                                                className="form-control-number"
                                                onChange={(v: string) => setAmount(v)}
                                            />
                                        </div>
                                        <div className="col-md-1 text-center">x</div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                title={trg("coefficient")}
                                                maxLength={5}
                                                className="form-control-number"
                                                value={coefficient}
                                                onChange={(v: string) => {
                                                    let number = parseFloat(v).toFixed(7);
                                                    if (number.length <= 10) setCoefficient(v);
                                                    else setCoefficient((s) => s);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-1 text-center">=</div>
                                        <div className="col-md-4">
                                            <Input
                                                type="text"
                                                className="form-control-number"
                                                value={finalAmount}
                                                readonly={true}
                                                title={trg("final_amount")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button onClick={saveHandler} kind="primary" loading={formIsSubmitted}>
                                        {trg("save_adjustment")}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                </Drawer>
            </span>
        </ListItem>
    );
};
