import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useNavigate} from "react-router";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Ruleset} from "../../../types/Ruleset";
import {Table, Column} from "@appkit4/react-components/table";
import {Button} from "@appkit4/react-components";
import RulesetForm from "./Forms/RulesetForm";
import {dateToIsoString} from "../../../utils/dateToIsoString";
import i18n from "../../../utils/i18n";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {useTr} from "../../../utils/trUtil";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: Ruleset[];
}

const AdminFixtureRuleset: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, setData] = React.useState<Ruleset[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);
    const trg = useTr("global");

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false || dataAreLoading === true) {
            fetchApi(`/settings/_/fixtures/ruleset/?page=${current}`)
                .then((r) => r.json())
                .then((res: PaginationType) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res.results);
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current]);

    const renderActionCell = (row) => {
        return (
            <>
                <RulesetForm key={`edit-${row.id}`} ruleset={row} onSaveHandler={() => setDataAreLoaded(false)} />
                <RulesetForm
                    key={`copy-${row.id}`}
                    ruleset={row}
                    onSaveHandler={() => setDataAreLoaded(false)}
                    isCopyForm={true}
                />
            </>
        );
    };

    const pagination = (
        <Pagination
            current={current}
            total={Math.ceil(totalCount / countPerPage)}
            onPageChange={onPageChange}
        ></Pagination>
    );

    return (
        <CanShowAdminMiddleware>
            <div className="container-fluid">
                {pagination}
                <Table originalData={data} hasTitle striped condensed>
                    <Column field="id" sortKey="id" slot="end">
                        {trg("id")}
                    </Column>
                    <Column field="name" sortKey="name">
                        {trg("name")}
                    </Column>
                    <Column field="description" sortKey="description">
                        {trg("description")}
                    </Column>
                    <Column field="country_key" sortKey="country_key">
                        {trg("country_key")}
                    </Column>
                    <Column
                        field="valid_from"
                        sortKey="valid_from"
                        renderCell={(row: any, field: string) => {
                            return row.valid_from ? new Date(row.valid_from).toLocaleDateString(i18n.language) : "--";
                        }}
                    >
                        {trg("valid_from")}
                    </Column>
                    <Column
                        field="valid_to"
                        sortKey="valid_to"
                        renderCell={(row: any, field: string) => {
                            return row.valid_to ? new Date(row.valid_to).toLocaleDateString(i18n.language) : "--";
                        }}
                    >
                        {trg("valid_to")}
                    </Column>
                    <Column field="id" title={"Action"} renderCell={renderActionCell}>
                        {trg("action")}
                    </Column>
                </Table>
                {pagination}
            </div>
            <RulesetForm onSaveHandler={() => setDataAreLoaded(false)} />
        </CanShowAdminMiddleware>
    );
};

export default AdminFixtureRuleset;
