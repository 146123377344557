import {AvailableLanguages} from "../types/i18n";
import i18n from "./i18n";

export const numberToLocaleString = (number: any, locale?: string, max_digits?: number, min_digits?: number) => {
    const validLocale = getValidLocale(locale);
    const amount = parseNumber(number, validLocale);

    return amount.toLocaleString(validLocale, {
        maximumFractionDigits: max_digits ?? 2,
        minimumFractionDigits: min_digits ?? 2,
    });
};

const getValidLocale = (locale?: string): string => {
    if (locale) {
        const matchedLocale = Object.entries(AvailableLanguages).find(([_, val]) => val.includes(locale));
        return matchedLocale ? AvailableLanguages[matchedLocale[0]] : AvailableLanguages.English.toString();
    }
    return i18n.language;
};

const parseNumber = (number: any, locale: string): number => {
    if (typeof number === "number") {
        return number;
    }
    if (typeof number === "string") {
        return locale.includes("en") ? parseFloat(number) : parseFloat(number.replace(",", "."));
    }
    return 0.0;
};

export const convertLocaleNumberToFloat = (number: string, locale: string) => {
    return locale.includes("en") ? number : number.replace(".", "").replace(" ", "").replace(",", ".");
};
