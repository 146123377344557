import {Footer} from "@appkit4/react-components";
import React from "react";
import {useTr} from "../../utils/trUtil";
import "./LayoutFooter.scss";

const LayoutFooter: React.FC = () => {
    const tr = useTr("footer");

    const d = new Date();
    const year = d.getFullYear();

    return (
        <Footer
            id="layout-footer"
            className="footer"
            content={`© ${year} PricewaterhouseCoopers Tax k.s., a Slovak limited partnership. ${tr("copyrightText")}`}
            type="links"
            links={[
                {name: tr("TOSLabel"), href: tr("TOSLink")},
                {name: tr("PrivacyLabel"), href: tr("PrivacyLink")},
                {name: tr("DataControllerLabel"), href: tr("DataControllerLink")},
            ]}
        />
    );
};
export default LayoutFooter;
