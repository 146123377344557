import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Loading} from "@appkit4/react-components";
import {Entity} from "../../types/Entity";
import {Calculation} from "../../types/Calculation";
import {safeBackendFetch} from "../../utils/apiClient";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useTr} from "../../utils/trUtil";
import useMigration from "../useMigration";

interface CalculationListPropTypes {
    entity: Entity;
    isActive: boolean;
}
const CalculationList: React.FC<CalculationListPropTypes> = (props: CalculationListPropTypes) => {
    const entity = props.entity;
    const tr = useTr("calculation_list");

    const navigate = useNavigate();

    const userInfo = useGlobalStore((s) => s.userInfo);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const codebooks = useGlobalStore((s) => s.codebooks);
    const removeCurrentCalculation = useGlobalStore((s) => s.removeCurrentCalculation);

    const [calculations, setCalculations] = useState<Calculation[]>([]);
    const [calculationsAreLoaded, setCalculationsAreLoaded] = useState(false);
    // TODO - Run migration in function
    const migrated = useMigration(entity.hash);

    useEffect(() => {
        if (O.isSome(userInfo) && accessToken && props.isActive /* && migrated */) {
            safeBackendFetch(`/entity/${entity.hash}/calculation/`, {}, accessToken)
                .then((response) => {
                    return response.json();
                })
                .then((data: Array<Calculation>) => {
                    setCalculations(data);
                    setCalculationsAreLoaded(true);
                });
        } else {
            setCalculationsAreLoaded(false);
        }
    }, [props.isActive, userInfo, entity, /*migrated,*/ accessToken]);

    if (!props.isActive || !calculationsAreLoaded) return <Loading loadingType="circular" indeterminate={true} />;

    const navigateToCalculation = (cal: Calculation) => {
        removeCurrentCalculation();
        navigate(`/calculation/${entity.hash}/${cal.hash}`);
    };

    return (
        <table className="table table-borderless table-striped" style={{width: "95%", marginLeft: "2%"}}>
            {/* <List data={listData} itemKey={`calculation-list-${entity.hash}`}></List> */}
            <thead>
                <tr>
                    <th>{tr("name")}</th>
                    <th>{tr("preiod_start")}</th>
                    <th>{tr("period_end")}</th>
                    <th>{tr("status")}</th>
                    <th>{tr("description")}</th>
                    <th style={{width: "70px"}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {calculations.map((cal) => {
                    return (
                        <tr key={cal.hash} style={{cursor: "pointer"}}>
                            <td onClick={() => navigateToCalculation(cal)}>{cal.name}</td>
                            <td onClick={() => navigateToCalculation(cal)}>
                                {new Date(cal.period_start).toLocaleDateString()}
                            </td>
                            <td onClick={() => navigateToCalculation(cal)}>
                                {new Date(cal.period_end).toLocaleDateString()}
                            </td>
                            <td onClick={() => navigateToCalculation(cal)}>
                                {codebooks.calculation.statuses[cal.status]}
                            </td>
                            <td onClick={() => navigateToCalculation(cal)}>{cal.description}</td>
                            <td className="td">
                                <span
                                    className="Appkit4-icon icon-setting-outline"
                                    onClick={() => navigate(`/calculation/${entity.hash}/${cal.hash}/settings`)}
                                ></span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default CalculationList;
