export const zeroToString = (data: any) => {
    return data.map((o: any) => {
        Object.keys(o).forEach((k) => {
            if (typeof o[k] !== "number" || o[k] !== 0) return o[k];
            o[k] = o[k].toFixed(2);
            return o[k];
        });
        return o;
    });
};
