export const urlInsertParam = (key: string, value: string, init_url = null, reload_page = false) => {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    let inputUrl = new URL(init_url ? init_url : window.location.href);
    let inputParams = new URLSearchParams(inputUrl.search);

    inputParams.append(key, value);
    if (reload_page) window.location.search = inputParams.toString();

    return inputParams.toString();
};
