import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Select} from "@appkit4/react-components";
import {Questionnaire} from "../../../../types/Questionnaire";
import {useTr} from "../../../../utils/trUtil";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";

function useQuestionnaireSelection(disabled: boolean) {
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState<Questionnaire>();
    const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([]);
    const [areLoaded, setAreLoaded] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [toSelect, setToSelect] = React.useState<Questionnaire>();
    const trg = useTr("global");

    const fetchQuestionnaires = React.useCallback(() => {
        fetchApi(`/settings/_/fixtures/questionnaire/`)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    addErrorNotification(data);
                } else {
                    setQuestionnaires(data.results);
                    if (toSelect) {
                        setSelectedQuestionnaire(toSelect);
                        setToSelect(undefined);
                    }
                }
                setAreLoaded(true);
            })
            .catch((error) => {
                addErrorNotification(error);
                setAreLoaded(true);
            });
    }, [fetchApi, addErrorNotification, toSelect]);

    React.useEffect(() => {
        if (!areLoaded) {
            fetchQuestionnaires();
        }
    }, [areLoaded, fetchQuestionnaires]);

    const setNewQuestionnaire = (newQuestionnaire: Questionnaire) => {
        setToSelect(newQuestionnaire);
        setAreLoaded(false);
    };

    const handleSelect = (val: SelectValue) => {
        if (val && selectedQuestionnaire && selectedQuestionnaire.id === val) {
            console.log("delete");
            setSelectedQuestionnaire(undefined);
        } else {
            console.log("init");
            setSelectedQuestionnaire(val ? questionnaires.find((q) => q.id === val) : undefined);
        }
    };

    const questionnaireSelector = (
        <Select
            data={questionnaires}
            labelKey="name"
            placeholder={trg("select_questionnaire")}
            valueKey="id"
            value={selectedQuestionnaire ? selectedQuestionnaire.id : ""}
            onSelect={handleSelect}
            itemTemplate={(val, item) => (
                <>
                    ({item.id}) - {item.name}
                </>
            )}
            disabled={selectedQuestionnaire && disabled}
        />
    );

    return {questionnaireSelector, selectedQuestionnaire, setNewQuestionnaire};
}

export default useQuestionnaireSelection;
