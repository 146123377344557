import React from "react";
import {Loading, Notice, Button, Notification, FileModel} from "@appkit4/react-components";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {Load} from "../../types/Load";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useTr} from "../../utils/trUtil";
import {useNavigate} from "react-router-dom";
import Centered from "../../misc/Centered";
import PagePanel from "../../misc/PagePanel";
import ImportSettings from "./ImportSettings";
import useColumnMapping from "../../hooks/useColumnMapping";
declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    menu: React.ReactNode;
};

const ImportTrialBalanceColumnMapping: React.FC<PropTypes> = (props: PropTypes) => {
    const tr2 = useTr("calculation_import");
    const tr3 = useTr("base");
    const trg = useTr("global");

    const navigate = useNavigate();
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [lastLoad, setLastLoad] = React.useState<Load>();
    const [errors, setErrors] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [cancelIsLoading, setCancelIsLoading] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);

    const cm = useColumnMapping({load: lastLoad, type: "trial_balance"});

    React.useEffect(() => {
        if (props.entity && props.calculation)
            fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_last_load/`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : setLastLoad(data);
                });
    }, [props.entity, props.calculation, fetchApi, addErrorNotification, setLastLoad]);

    const onColumnMapping = () => {
        setIsLoading(true);
        if (lastLoad && lastLoad?.status >= 4) {
            setIsLoading(false);
            setErrors(["Trial balance was already imported."]);
        } else if (cm.checkTriedSending()) {
            setIsLoading(false);
            setErrors(["All required fields need to be mapped."]);
        } else if (lastLoad) {
            let mpcol = {...cm.mappedColumns};
            delete mpcol[""];
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_import_load/`,
                "post",
                {
                    load_id: lastLoad?.id,
                    sheet_name: cm.sheet,
                    mapped_columns: mpcol,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setIsLoading(false);
                    if (typeof data.error === "undefined") {
                        setLastLoad(data.load);
                        updateCurrentCalculation(data.load.calculation);
                        navigate(`/calculation/${props.entity.hash}/${props.calculation.hash}/import/journal/file`);
                    } else {
                        setErrors(data.error.details);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsLoading(false);
                });
        }
    };

    const onColumnMappingCsv = () => {
        setIsLoading(true);
        if (lastLoad && lastLoad?.status >= 4) {
            setIsLoading(false);
            setErrors(["Trial balance was already imported."]);
        } else if (cm.checkTriedSending()) {
            setIsLoading(false);
            setErrors(["All required fields need to be mapped."]);
        } else if (lastLoad) {
            let mpcol = {...cm.mappedColumns};
            delete mpcol[""];
            fetchApi(
                `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_import_load_csv/`,
                "post",
                {
                    load_id: lastLoad?.id,
                    sheet_name: cm.sheet,
                    mapped_columns: mpcol,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setIsLoading(false);
                    if (typeof data.error === "undefined") {
                        setLastLoad(data.load);
                        updateCurrentCalculation(data.load.calculation);
                        navigate(`/calculation/${props.entity.hash}/${props.calculation.hash}/import/journal/file`);
                    } else {
                        setErrors(data.error.details);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setIsLoading(false);
                });
        }
    };

    // const onColumnMapping = (file: FileModel) => {
    //     setIsLoading(true);
    //     if (lastLoad && lastLoad?.status >= 4) {
    //         setIsLoading(false);
    //         setErrors(["Trial balance was already imported."]);
    //     } else if (cm.checkTriedSending()) {
    //         setIsLoading(false);
    //         setErrors(["All required fields need to be mapped."]);
    //     } else if (lastLoad) {
    //         let mpcol = {...cm.mappedColumns};
    //         delete mpcol[""];

    //         const fileType = file.name.split(".").pop()?.toLowerCase();

    //         const url =
    //             fileType === "xls" || fileType === "xlsx"
    //                 ? `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_import_load/`
    //                 : `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_import_load_csv/`;
    //         fetchApi(url, "post", {
    //             load_id: lastLoad?.id,
    //             sheet_name: cm.sheet,
    //             mapped_columns: mpcol,
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setIsLoading(false);
    //                 if (typeof data.error === "undefined") {
    //                     setLastLoad(data.load);
    //                     updateCurrentCalculation(data.load.calculation);
    //                     navigate(`/calculation/${props.entity.hash}/${props.calculation.hash}/import/journal/file`);
    //                 } else {
    //                     setErrors(data.error.details);
    //                 }
    //                 setIsLoading(false);
    //             })
    //             .catch((err) => {
    //                 addErrorNotification(err);
    //                 setIsLoading(false);
    //             });
    //     }
    // };

    const onCancelImportHandler = () => {
        setCancelIsLoading(true);
        fetchApi(
            `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/trial_balance_import_cancel/`,
            "post",
            {
                load_id: lastLoad?.id,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined"
                    ? addErrorNotification(data)
                    : updateCurrentCalculation(data.calculation);
                setCancelIsLoading(false);
            })
            .catch((err) => {
                addErrorNotification(err);
                setCancelIsLoading(false);
            });
    };

    const fileType = () => {
        const loadFile = lastLoad?.file.original_filename.split(".")!;
        let fileType = loadFile[1];
        if (fileType === "csv") return false;
        else return true;
    };
    return (
        <PagePanel className="mb-3 p-0" title={"Trial balance column mapping"}>
            {props.menu}
            {lastLoad && typeof lastLoad.initial_data.sheet_names !== "undefined" ? (
                <>
                    <h2 style={{fontSize: "1.4rem"}}>{tr2("pleaseMapColumns")}</h2>
                    {errors.map((error) => {
                        return (
                            <Notice
                                key={error}
                                title=""
                                style={{width: "auto"}}
                                message={error}
                                status="error"
                                onClose={() => setErrors([])}
                            ></Notice>
                        );
                    })}
                    {cm.sheet && (
                        <div className="row align-items-end">
                            <div className="col-md-4 my-3">{cm.sheetSelector}</div>
                            {lastLoad && (
                                <>
                                    <div className="col-md-6 my-3">
                                        {lastLoad.file.original_filename}
                                        <br />
                                        <small className="text-muted">
                                            {new Date(lastLoad.file.created.toString()).toUTCString()}
                                        </small>
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <>
                                            {cm.columns.length ? (
                                                <div className="d-flex flex-row-reverse mt-2">
                                                    {props.calculation.state_trial_balance_import_is_running ? (
                                                        <>
                                                            <Button
                                                                kind="text"
                                                                loading={cancelIsLoading}
                                                                onClick={onCancelImportHandler}
                                                            >
                                                                {trg("cancel")}
                                                            </Button>
                                                            <Button kind="tertiary" disabled={true}>
                                                                {tr2("import_is_running")}
                                                            </Button>
                                                            <Loading
                                                                className="mx-2"
                                                                loadingType="circular"
                                                                indeterminate={true}
                                                                compact={true}
                                                            ></Loading>
                                                        </>
                                                    ) : props.calculation.state_trial_balance_imported &&
                                                      lastLoad.status >= 2 ? (
                                                        <>
                                                            <Button kind="secondary" disabled={true}>
                                                                {tr2("import_is_loaded")}
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button
                                                            kind="primary"
                                                            loading={isLoading}
                                                            disabled={false}
                                                            onClick={fileType() ? onColumnMapping : onColumnMappingCsv}
                                                        >
                                                            {tr3("next")}
                                                        </Button>
                                                    )}
                                                    <ImportSettings />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {lastLoad.status === 2 && (
                        <Notification
                            title=""
                            style={{width: "100%"}}
                            closeable={false}
                            message={
                                <div>
                                    {trg("trial_balance_is_currently_loading")}{" "}
                                    <Button
                                        kind="text"
                                        className="ap-notification-link"
                                        color="gray"
                                        onClick={() =>
                                            navigate(
                                                `/calculation/${props.entity.hash}/${props.calculation.hash}/import/journal/file`
                                            )
                                        }
                                    >
                                        {trg("import_journal")}
                                    </Button>
                                </div>
                            }
                            status="success"
                        ></Notification>
                    )}
                    {props.calculation.state_trial_balance_imported !== true &&
                        props.calculation.trial_balance_import_queue_message &&
                        props.calculation.trial_balance_import_queue_message.error_message && (
                            <Notification
                                title=""
                                style={{width: "100%"}}
                                closeable={false}
                                message={
                                    <div>{props.calculation.trial_balance_import_queue_message.error_message}</div>
                                }
                                status="error"
                            ></Notification>
                        )}

                    {cm.mappingTable}
                </>
            ) : lastLoad && typeof lastLoad.initial_data.sheet_names === "undefined" ? (
                <div>
                    <Notification
                        className="w-50"
                        title="Failed to retrieve uploaded file information"
                        message="Verify that the first row of each workbook sheet contains the correct column names and remove unnecessary columns. Then upload this updated file again."
                        status="error"
                    />
                </div>
            ) : (
                <div className="mt-5">
                    <Centered>
                        <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                    </Centered>
                </div>
            )}
        </PagePanel>
    );
};

export default ImportTrialBalanceColumnMapping;
