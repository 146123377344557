import React from "react";
import {Checkbox} from "@appkit4/react-components/checkbox";

import HtmlPanel from "./HtmlPanel";
import {InputPropTypes} from "../CalculationFormElement";

const InputCheckbox: React.FC<InputPropTypes> = (props) => {
    const {element, onChangeHandler, children} = props;

    const convertValue = (value: boolean) => {
        return value ? "1" : "0";
    };

    return element.html_type === "panel" ? (
        <>
            <HtmlPanel {...props}>
                <>
                    <div className="border mx-2 px-2">
                        <Checkbox defaultChecked={false}>{element.info ? element.info : element.description}</Checkbox>
                    </div>
                    {children}
                </>
            </HtmlPanel>
        </>
    ) : (
        <div className="border mx-2 px-2">
            <Checkbox defaultChecked={element.value} onChange={(v) => onChangeHandler(element, convertValue(v))}>
                {element.description ? element.description : element.info}
            </Checkbox>
        </div>
    );
};

export default InputCheckbox;
