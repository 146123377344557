import React from "react";
import {ListItem, Avatar} from "@appkit4/react-components";
import {avatarColor} from "../utils/avatarColor";
import {getInitials} from "../utils/nameFormatter";
import {Member} from "../types/UserInfo";

declare type PropTypes = {
    members: Member[];
    getDescription: (item: Member) => React.ReactNode;
    getAction: (item: Member) => React.ReactNode;
};

const MembersList: React.FC<PropTypes> = ({members, getDescription, getAction}) => {
    const initials = (item: Member) => {
        return getInitials(item.user.first_name ? item.user.first_name + " " + item.user.last_name : item.user.email);
    };

    const renderItem = (item: Member, index: number) => {
        return (
            <ListItem
                key={index + "-" + item.user.email}
                id={index + "-" + item.user.email}
                title={
                    <div className="small" style={{lineHeight: "1.2rem"}}>
                        {item.user.email}
                    </div>
                }
                divider={true}
                description={`${item.user.first_name} ${item.user.last_name}`}
                avatar={
                    <span className="ap-list-avatar-container" role="option">
                        <Avatar
                            style={{width: "34px", height: "34px"}}
                            label={initials(item)}
                            bgColor={`#${avatarColor(initials(item))}`}
                        ></Avatar>
                    </span>
                }
            >
                <span className="mx-3">
                    <i>
                        <small>{getDescription(item)}</small>
                    </i>
                </span>
                <span>{getAction(item)}</span>
            </ListItem>
        );
    };

    return <div>{members.map((member: Member, index: number) => renderItem(member, index))}</div>;
};

export default MembersList;
