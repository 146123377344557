import React, {useState, useEffect, useCallback, useRef} from "react";
import {FeedsComments, FeedsCommentItem, Tooltip} from "@appkit4/react-components";
import {Question} from "../../../types/Question";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {useTr} from "../../../utils/trUtil";
import i18n from "../../../utils/i18n";
import {avatarColor} from "../../../utils/avatarColor";
import "./Comments.scss";
import {AvailableLanguages} from "../../../types/i18n";

export interface CommentItem {
    id: number;
    shortName: string;
    fullName: string;
    commentsTime: string;
    commentsContent: string;
    answer_id?: number;
    shortNameBgColor?: string;
}

function formatTimeAgo(diffInMinutes, diffInHours, locale) {
    if (locale === AvailableLanguages.Slovak) {
        if (diffInMinutes < 1) {
            return `pred pár sekundami`;
        } else if (diffInMinutes < 2) {
            return `pred 1 minútou`;
        } else if (diffInMinutes < 60) {
            return `pred ${Math.floor(diffInMinutes)} minútami`;
        }
        if (diffInHours < 2) {
            return `pred 1 hodinou`;
        } else if (diffInHours < 24) {
            return `pred ${Math.floor(diffInHours)} hodinami`;
        }
    } else {
        if (diffInMinutes < 1) {
            return `a few seconds ago`;
        } else if (diffInMinutes < 2) {
            return `1 minute ago`;
        } else if (diffInMinutes < 60) {
            return `${Math.floor(diffInMinutes)} minutes ago`;
        }
        if (diffInHours < 2) {
            return `1 hour ago`;
        } else if (diffInHours < 24) {
            return `${Math.floor(diffInHours)} hours ago`;
        }
    }
    return "invalid time";
}

const Comments: React.FC<{question: Question; comments: CommentItem[]; onCommentsChange: (id: number) => void}> = ({
    question,
    comments,
    onCommentsChange,
}) => {
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const trg = useTr("global");
    const locale = i18n.language;

    const [commentsContent, setCommentsContent] = useState<string>("");
    const [displayAddComment, setDisplayAddComment] = useState<boolean>(false);
    const [commentList, setCommentList] = useState<CommentItem[]>([]);

    const formatCommentsTime = (timestamp: string) => {
        const commentDate = new Date(timestamp);
        if (isNaN(commentDate.getTime())) {
            return "invalid date";
        }
        const now = new Date();
        const diffInSeconds = Math.abs((now.getTime() - commentDate.getTime()) / 1000);
        const diffInHours = diffInSeconds / 3600;
        const diffInMinutes = diffInSeconds / 60;

        if (diffInHours < 24) {
            return formatTimeAgo(diffInMinutes, diffInHours, locale);
        }
        return commentDate
            .toLocaleString(locale, {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            })
            .toString();
    };

    useEffect(() => {
        setCommentList(
            comments
                .filter((comment) => comment.answer_id === question?.answer?.id)
                .map((comment) => ({
                    ...comment,
                    commentsTime: formatCommentsTime(comment.commentsTime),
                    shortNameBgColor: `#${avatarColor(comment.fullName)}`,
                }))
                .reverse()
        );
    }, [comments]);

    const addComments = useCallback(() => {
        if (!O.isSome(entity) || !O.isSome(calculation)) {
            throw new Error("Entity or calculation is undefined");
        }

        fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/answer_comment/`, "post", {
            message: commentsContent,
            question: question.id,
            answer: null,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.error(data);
                } else {
                    const fullName = `${data.owner.first_name} ${data.owner.last_name}`;
                    const newComment: CommentItem = {
                        id: data.id,
                        shortName: data.owner.first_name[0] + data.owner.last_name[0],
                        fullName: fullName,
                        commentsTime: formatCommentsTime(data.created_at),
                        commentsContent: data.message,
                        shortNameBgColor: `#${avatarColor(fullName)}`,
                    };
                    setCommentList([newComment, ...commentList]);
                    setCommentsContent("");
                    onCommentsChange(question.id);
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }, [commentsContent, commentList, entity, calculation, fetchApi, question.id]);

    return (
        <div className="feeds-comments-wrapper">
            <FeedsComments
                type={"comments"}
                renderCommentList={() =>
                    commentList.length === 0 ? (
                        <p>{trg("no_comments_yet")}</p>
                    ) : (
                        commentList.map((item) => (
                            <div style={{maxHeight: "70px"}} key={"Comment-" + item.id.toString()}>
                                <FeedsCommentItem item={item} />
                            </div>
                        ))
                    )
                }
                style={{maxHeight: "300px", resize: "none", overflowY: "auto"}}
            />
            <div style={{height: "5px"}}></div>
            {displayAddComment ? (
                <FeedsComments
                    type={"addCommentPanel"}
                    title={trg("add_new_comment")}
                    placeholderWithoutContent={trg("write_comment")}
                    placeholderWithContent={trg("your_comment")}
                    addBtnName={trg("add_comment")}
                    commentsContent={commentsContent}
                    onAddClick={addComments}
                    maxLength={500}
                    onCommentContentChange={setCommentsContent}
                    onCloseClick={() => {
                        setDisplayAddComment(false);
                        onCommentsChange(question.id);
                    }}
                />
            ) : (
                <Tooltip trigger="hover" position="right" id="tooltipDesc" content={trg("add_new_comment")}>
                    <span
                        className="Appkit4-icon icon-plus-fill"
                        onClick={() => {
                            setDisplayAddComment(true);
                            onCommentsChange(question.id);
                        }}
                        role="button"
                    ></span>
                </Tooltip>
            )}
        </div>
    );
};

export default Comments;
