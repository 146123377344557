import {createRoot} from "react-dom/client";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import "./utils/i18n.js"; // This import alone is functioning as a LanguageProvider
import ThemeProvider from "./misc/ThemeProvider";
import BackendAvailabilityProvider from "./misc/BackendAvailabilityProvider";
import {AuthProvider} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";

const oidcConfig = {
    authority: "" + process.env.REACT_APP_PWC_IDENTITY_AUTHORITY,
    client_id: "" + process.env.REACT_APP_PWC_IDENTITY_CLIENT_ID,
    response_type: "code",
    redirect_uri: process.env.REACT_APP_REDIRECT_URI ? "" + process.env.REACT_APP_REDIRECT_URI : "/oidc/login/callback",
    scope: "openid profile email pwcUserType",
    userStore: new WebStorageStateStore({store: window.localStorage}),
};

const root = createRoot(document.getElementById("root")!);

root.render(
    <AuthProvider {...oidcConfig}>
        <BackendAvailabilityProvider>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </BackendAvailabilityProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: change (null) to (console.log) at the end of the function)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
((onPerfEntry: any) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import("web-vitals").then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
})(null);
